import type { ApiPathDebugInfo, ApiPathDebugInfoUI } from "./type";

const isChangedRecently = (debugInfo: ApiPathDebugInfo) =>
  Date.now() - debugInfo.lastCallTime < 30000;

/**
 * Order is from bigger weight to smaller weight
 */
const getDebugInfoOrderWeight = (
  debugInfo: ApiPathDebugInfo,
  isPinned: boolean
) => {
  const SuperWeight = {
    Pinned: 1_000_000,
    RecentlyCalled: 100_000,
  };

  if (isPinned) {
    return debugInfo.callsCount + SuperWeight.Pinned;
  }

  if (isChangedRecently(debugInfo)) {
    return debugInfo.callsCount + SuperWeight.RecentlyCalled;
  }

  return debugInfo.callsCount;
};

export const getUIDebugInfoMap = (
  debugInfoMap: Map<string, ApiPathDebugInfo>,
  pinnedPaths: Set<string>
) => {
  const uiDebugInfoMap = new Map<string, ApiPathDebugInfoUI>();
  Array.from(debugInfoMap.keys())
    .sort(
      (a, b) =>
        getDebugInfoOrderWeight(debugInfoMap.get(b), pinnedPaths.has(b)) -
        getDebugInfoOrderWeight(debugInfoMap.get(a), pinnedPaths.has(a))
    )
    .forEach((pathStr, order) => {
      uiDebugInfoMap.set(pathStr, {
        ...debugInfoMap.get(pathStr),
        isPinned: pinnedPaths.has(pathStr),
        order,
      });
    });

  return uiDebugInfoMap;
};
