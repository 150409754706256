/* eslint-disable max-lines */
import { PANEL_SECTIONS } from './panelSections';
import { PHOTOGRAPHY_PROMO } from './photographyPromo';
import { PANELS } from './panels';
import { SUPER_APPS } from './superApps';
import { LEFTBAR } from './leftBar';
import { COMP_TYPES } from './compTypes';
import { videoPlayerUtils } from '@wix/santa-editor-utils';

import type { FocalPointTypes } from '@wix/focal-point-input';
import type { FittingType } from '@wix/image-kit';

const { VIDEO_PLAYER_TYPES } = videoPlayerUtils;

const baseConstants = {
  DS_ACTIONS: {
    ADD_PAGE: 'addPage',
  },
  LIVE_CHAT: {
    WIDGET_ID: '3614dcfb-1efb-484a-b7c2-49e6e1bc8213',
  },
  ACTIONS: {
    SCREEN_IN: 'screenIn',
    MODE_IN: 'modeIn',
    EXIT: 'exit',
    MODE_EXIT: 'modeOut',
    MODE_CHANGE: 'modeChange',
    DEFAULT_ACTION: 'defaultAction',
  },
  APPLICATIONS: {
    META_SITE_APPLICATION_ID: '-666',
  },
  FEATURES: {
    CONNECT_TO_DATA: 'connectToData',
    PASTE: 'paste',
  },
  PACKAGE_UPDATE_PANEL_STEPS: {
    VERSION_SELECTION: 'versionSelection',
    CONFIRMATION: 'confirmation',
    TASKS: 'tasks',
    FIRST_SUCCESS: 'firstSuccess',
    SECOND_SUCCESS: 'secondSuccess',
    ERROR: 'error',
  },
  COMPONENT_UI_COLORS: {
    BLUE: 'BLUE',
    ORANGE: 'ORANGE',
  },
  COMPONENT_UI_THEMES: {
    DARK: 'DARK',
    LIGHT: 'LIGHT',
  },
  COMPONENT_DISPLAY: {
    ONLY_IN_NON_DEFAULT_MODES: 'onlyInModes',
    ONLY_IN_DEFAULT_MODE: 'onlyInDefault',
    IN_MODE_AND_DEFAULT: 'bothInModesAndInDefault',
    OUT_OF_MODES_BOX: 'outOfModesBox',
  },
  COMPONENTS_STORE_KEYS: {
    META_DATA_OVERRIDES: 'meta-data-overrides',
    SCROLL_COUNTER: 'scroll-counter',
  },
  NORMALIZED_PLAYING_STATES: ['playing', 'seek_playing', 'play_preview'],
  PLAYBACK_STATES: {
    PLAYING: 'playing',
    ENDED: 'ended',
    PAUSED: 'paused',
    WAITING: 'waiting',
  },
  VIDEO_READY_STATES: {
    IN_PROCESS: 'in_process',
    PLAYING_PREVIEW: 'playing_preview',
    IDLE: 'idle',
    NO_VIDEO: 'no_video',
  },
  VIDEO_PLAYER_TYPES,
  COLUMN_LAYOUT_ALIGNMENT_OPTIONS: [
    {
      label: 'STRIP_CONTAINER_COLUMN_LAYOUTS_ALIGNMENT_LEFT_LABEL',
      value: 0,
      symbolName: 'alignContentLeft',
    },
    {
      label: 'STRIP_CONTAINER_COLUMN_LAYOUTS_ALIGNMENT_CENTER_LABEL',
      value: 50,
      symbolName: 'alignContentCenter',
    },
    {
      label: 'STRIP_CONTAINER_COLUMN_LAYOUTS_ALIGNMENT_RIGHT_LABEL',
      value: 100,
      symbolName: 'alignContentRight',
    },
  ],
  CONSTRAINS: {
    PAGE_MIN_HEIGHT: {
      DESKTOP: 500,
      MOBILE: 200,
    },
  },
  FEEDBACK: {
    CHECK_COMMENTS_INTERVAL: 30000,
    CHECK_SITE_SHARED_INTERVAL: 300000,
    CLOSE_PANEL_TIMEOUT: 300,
  },
  EDITOR_API: {
    BLOCKING_LAYER_STYLE: {
      backgroundColor: 'rgba(255, 255, 255, 0.85)',
    },
  },
  EVENTS: {
    ADD_PANEL: {
      COMPONENT_ADDED_TO_STAGE: 'componentAddedToStage',
    },
    ADD_ELEMENTS_PANEL: {
      RESET_WIDGET: 'resetWidget',
    },
  },
  GRID_LINES_TYPES: {
    PAGE: 'page',
  },
  DOCKED_PANEL_SECTIONS: {
    WIDGET_PUBLIC_API: 'dockableSections.widgetPublicInterface',
    WIDGET_PUBLIC_API_PREVIEW: 'dockableSections.widgetPublicInterfacePreview',
    PROPERTIES: 'dockableSections.properties',
  },
  HISTORY: {
    UNDO: 'undo',
    REDO: 'redo',
  },
  ROOT_COMPS: {
    LEFTBAR,
    LEFTTREE: {
      IDE_TREE_TITLE: 'ideTreeTitle',
      PAGES_TREE: 'pagesTree',
      SITE_TREE: 'siteTree',
      POPUPS_TREE: 'wixCodePopupsTree',
      COLLECTIONS: 'collections',
      PUBLIC_FILE_TREE: 'publicFileTree',
      BACKEND_FILE_TREE: 'backendFileTree',
      WIDGETS_VIEW: 'widgetsView',
      APP_BUILDER_LEFT_TREE_HEADER: 'appBuilderLeftTreeHeader',
      TITLE: 'title',
      NODE_MODULES_TREE: 'nodeModulesTree',
    },
    TOOLTIPS: {
      FIRST_TIME_EXIT_POPUP_MODE: 'FIRST_TIME_EXIT_POPUP_MODE',
    },
    TOPBAR: {
      APPEARANCE: {
        FULL: 0,
        SAVE_AND_MOBILE_CHANGE_ICONS: 1,
        PREVIEW_CHANGE_TO_ICON: 2,
        HIDE_REDO: 3,
        TRIM_PAGES_NAV_BUTTON: 4,
        HIDE_UPGRADE_IN_MENU: 5,
      },
      MENU_BAR_ITEMS: {
        SITE: 'SITE',
        SETTINGS: 'SETTINGS',
        TOOLS: 'TOOLS',
        CODE: 'CODE',
        HELP: 'HELP',
        UPGRADE: 'UPGRADE',
      },
      MENU_BAR_TO_MENU_ITEMS_MAPPER: {
        SITE: 'SITE_MENU_ITEMS',
        SETTINGS: 'SETTINGS_MENU_ITEMS',
        TOOLS: 'TOOLS_MENU_ITEMS',
        CODE: 'CODE_MENU_ITEMS',
        HELP: 'HELP_MENU_ITEMS',
      },
      SITE_MENU_ITEMS: {
        FEEDBACK: 'FEEDBACK',
        VIEW_SITE: 'VIEW_SITE',
        VIEW_SITE_MOBILE: 'VIEW_SITE_MOBILE',
        GO_TO_SEO_WIZ: 'GO_TO_SEO_WIZ',
        ACCESSIBILITY: 'ACCESSIBILITY',
        MOBILE_FRIENDLY: 'MOBILE_FRIENDLY',
        CONNECT_DOMAIN: 'CONNECT_DOMAIN',
        UPGRADE: 'UPGRADE',
        IMAGE_OPTIMIZATION: 'IMAGE_OPTIMIZATION',
        SETTINGS: 'SETTINGS',
        SAVE: 'SAVE',
        PREVIEW: 'PREVIEW',
        UNPUBLISH: 'UNPUBLISH',
        PUBLISH: 'PUBLISH',
        GET_TRAFFIC: 'GET_TRAFFIC',
        EDITOR_MODE_SWITCH: 'EDITOR_MODE_SWITCH',
        SITE_DASHBOARD: 'SITE_DASHBOARD',
        REVISIONS: 'REVISIONS',
        DUPLICATE: 'DUPLICATE',
        TRANSFER: 'TRANSFER',
        BACK_TO_ADI: 'BACK_TO_ADI',
        UPDATE_EDITOR: 'UPDATE_EDITOR',
        MOVE_TO_TRASH: 'MOVE_TO_TRASH',
        CREATE_NEW_SITE: 'CREATE_NEW_SITE',
        EXIT: 'EXIT',
        CREATE_RELEASE_CANDIDATE: 'CREATE_RELEASE_CANDIDATE',
        RELEASE_MANAGER: 'RELEASE_MANAGER',
        UPGRADE_MENU_DIRECT_CLICK: `TOP_BAR_UPGRADE_MENU_DIRECT_CLICK`,
        TOP_BAR_UPGRADE_MENU_UPGRADE_NOW: `TOP_BAR_UPGRADE_MENU_UPGRADE_NOW`,
        SITE_BRANCHES: 'SITE_BRANCHES',
      },
      TOOLS_MENU_ITEMS: {
        TOOLBAR: 'TOOLBAR',
        SECTIONIZER: 'SECTIONIZER',
        AI_PERSONALIZE_CONTENT: 'AI_PERSONALIZE_CONTENT',
        LAYERS: 'LAYERS',
        LAYERS_SEPARATOR: 'LAYERS_SEPARATOR',
        RULERS: 'RULERS',
        GUIDELINES: 'GUIDELINES',
        DEVELOPER_MODE_SEPARATOR: 'DEVELOPER_MODE_SEPARATOR',
        DEVELOPER_MODE_TITLE: 'DEVELOPER_MODE_TITLE',
        DEVELOPER_MODE: 'DEVELOPER_MODE',
        PROPERTIES_TOOLBAR: 'PROPERTIES_TOOLBAR',
        HIDDEN_ELEMENTS: 'HIDDEN_ELEMENTS',
        WIDGET_PUBLIC_API: 'WIDGET_PUBLIC_API',
        SNAP: 'SNAP',
        REORDER_SEPARATOR: 'REORDER_SEPARATOR',
        REORDER: 'REORDER',
        EXTENSIONS_SEPARATOR: 'TOOLS_EXTENSIONS_SEPARATOR',
        EXTENSIONS_SLOT_TITLE: 'TOOLS_EXTENSIONS_TITLE',
        EXTENSIONS_SLOT: 'TOOLS_EXTENSIONS_SLOT',
      },
      HELP_MENU_ITEMS: {
        HELP_EDITOR_SEARCH: 'HELP_EDITOR_SEARCH',
        HELP_CHAT: 'HELP_CHAT',
        HELP_CENTER: 'HELP_CENTER',
        HELP_HOME: 'HELP_HOME',
        HELP_KB_SHORTCUTS: 'HELP_KB_SHORTCUTS',
        HELP_VIDEOS_SEPARATOR: 'HELP_VIDEOS_SEPARATOR',
        HELP_VIDEOS: 'HELP_VIDEOS',
        HELP_CONNECT_DOMAIN: 'HELP_CONNECT_DOMAIN',
        HELP_SEO: 'HELP_SEO',
        HELP_TERMS_OF_USE: 'HELP_TERMS_OF_USE',
        HELP_PRIVACY_POLICY: 'HELP_PRIVACY_POLICY',
        HELP_WELCOME_TOUR: 'HELP_WELCOME_TOUR',
        HELP_NEW_RELEASES: 'HELP_NEW_RELEASES',
        HELP_EDITOR_NEW_USERS_TOUR: 'HELP_EDITOR_NEW_USERS_TOUR',
        HELP_ADI_TO_EDITOR_TOUR: 'HELP_ADI_TO_EDITOR_TOUR',
        HELP_EDITOR_TO_EDITOR_TOUR: 'HELP_EDITOR_TO_EDITOR_TOUR',
        HELP_EXTENSION_SLOT: 'HELP_MENU_EXTENSION_SLOT',
        HELP_WIX_LEARN: 'HELP_WIX_LEARN',
      },
      CODE_MENU_ITEMS: {
        CODE_TOGGLE_DEV_TOOLS: 'CODE_TOGGLE_DEV_TOOLS',
        CODE_TITLE: 'CODE_TITLE',
        CODE_HEADER: 'CODE_HEADER',
        CODE_SUB_HEADER: 'CODE_SUB_HEADER',
        CODE_ON_LIST_ITEMS: 'CODE_ON_LIST_ITEMS',
        CODE_SYMBOL: 'CODE_SYMBOL',
        ENABLE_DEVELOPER_MODE: 'ENABLE_DEVELOPER_MODE',
        HIRE_A_DEVELOPER: 'HIRE_A_DEVELOPER',
        API_REFERENCES: 'API_REFERENCES',
        VIDEO_TUTORIALS: 'VIDEO_TUTORIALS',
        ADD_A_CODE_SNIPPET: 'ADD_A_CODE_SNIPPET',
        EMBED_HTML: 'EMBED_HTML',
      },
      SETTINGS_MENU_ITEMS: {
        CONNECT_DOMAIN: 'CONNECT_DOMAIN',
        MAILBOX: 'MAILBOX',
        UPGRADE: 'UPGRADE',
        GET_TRAFFIC: 'GET_TRAFFIC',
        GO_TO_SEO_WIZ: 'GO_TO_SEO_WIZ',
        FAVICON: 'FAVICON',
        CUSTOM_ERROR_PAGE: 'CUSTOM_ERROR_PAGE',
        BUSINESS_INFO: 'BUSINESS_INFO',
        SOCIAL: 'SOCIAL',
        TRACKING_TOOLS: 'TRACKING_TOOLS',
        MARKETING_INTEGRATIONS: 'MARKETING_INTEGRATIONS',
        ACCESSIBILITY: 'ACCESSIBILITY',
        MOBILE_FRIENDLY: 'MOBILE_FRIENDLY',
        IMAGE_OPTIMIZATION: 'IMAGE_OPTIMIZATION',
        PRIVACY_GDPR: 'PRIVACY_GDPR',
        MULTILINGUAL: 'MULTILINGUAL',
        PERMISSIONS: 'PERMISSIONS',
        DUPLICATE: 'DUPLICATE',
        TRANSFER: 'TRANSFER',
        SITE_DASHBOARD: 'SITE_DASHBOARD',
        SITE_ACCESS: 'SITE_ACCESS',
      },
      BUTTONS: {
        BACK_TO_EDITOR: 'BACK_TO_EDITOR',
        EDITOR_SEARCH: 'EDITOR_SEARCH',
        MOBILE: 'MOBILE',
        DESKTOP_MODE: 'DESKTOP_MODE',
        MOBILE_MODE: 'MOBILE_MODE',
        PREVIEW: 'PREVIEW',
        PUBLISH: 'PUBLISH',
        TEST_SITE: 'TEST_SITE',
        LOCAL_PUSH: 'LOCAL_PUSH',
        PUBLISH_RC: 'PUBLISH_RC',
        REDO: 'REDO',
        SAVE: 'SAVE',
        UNDO: 'UNDO',
        ARENA: 'ARENA',
        WIXSTORES_MIGRATION_UPGRADE_SITE: 'WIXSTORES_MIGRATION_UPGRADE_SITE',
        ZOOM: 'ZOOM',
      },
      LABELS: {
        VERSION_INDICATOR: 'VERSION_INDICATOR',
      },
      DROP_PANELS: {
        CUSTOM: {
          MOBILE_OPTIMIZE_LAYOUT_UNDO: 'MOBILE_OPTIMIZE_LAYOUT_UNDO',
          AUTO_SAVE: 'AUTO_SAVE',
          DISABLE_AUTO_SAVE: 'DISABLE_AUTO_SAVE',
          ENABLE_AUTO_SAVE: 'ENABLE_AUTO_SAVE',
          BACK_TO_ADI: 'BACK_TO_ADI',
          BACK_TO_CHAT: 'BACK_TO_CHAT',
          PUBLISH_RC: 'CUSTOM_PUBLISH_RC',
        },
        SITE: 'SITE',
        SETTINGS: 'SETTINGS',
        TOOLS: 'TOOLS',
        HELP: 'HELP',
        UPGRADE: 'UPGRADE',
        MOBILE: 'MOBILE',
        SAVE: 'SAVE',
        PUBLISH: 'PUBLISH',
        PUBLISH_RC: 'PUBLISH_RC',
        TEST_SITE_RC: 'TEST_SITE_RC',
        LOCAL_PUSH: 'LOCAL_PUSH',
        ARENA: 'ARENA',
        WIXSTORES_MIGRATION_UPGRADE_SITE: 'WIXSTORES_MIGRATION_UPGRADE_SITE',
        LANGUAGE_DROP_PANEL: 'LANGUAGE_DROP_PANEL',
        WORKSPACE_MODE_DROP_PANEL: 'WORKSPACE_MODE_DROP_PANEL',
      },
      MODES: {
        EDITOR: 'editor',
        PREVIEW: 'preview',
        ZOOM_OUT: 'zoomout',
      },
    },
    GFPP: {
      ACTIONS: {
        AI_TOOLS: 'aiTools',
        SETTINGS: 'settings',
        LAYOUT: 'layout',
        DESIGN: 'design',
        DIVIDERS: 'dividers',
        BACKGROUND: 'background',
        UPGRADE: 'upgrade',
        FILTERS: 'filters',
        BEHAVIORS: 'behaviors',
        EFFECTS: 'effects',
        CROP: 'crop',
        FOCAL_POINT: 'focalPoint',
        MASK: 'mask',
        MOTION: 'motion',
        ANIMATION: 'animation',
        INTERACTION_HIDE: 'interactionHide',
        INTERACTIONS: 'interactionsMode',
        TRANSITION: 'transition',
        LINK: 'link',
        TEXT_LINK: 'textLink',
        HIDE: 'hide',
        HELP: 'help',
        SCAN_DESIGNER_PRESET: 'scanDesignerPreset',
        STRETCH: 'stretch',
        STRETCH_FOR_COLUMNS: 'stretchForColumns',
        MOVE_DOWN: 'moveDown',
        MOVE_UP: 'moveUp',
        PIN_MODE: 'pinMode',
        ACCESSIBILITY: 'accessibility',
        CONNECT: 'connect',
        MANAGE: 'manage',
        CUSTOMIZE_DESIGN: 'customizeDesign',
        STYLABLE: 'stylable',
        STYLABLE_LAYOUT: 'stylableLayout',
        APPLY_TO_OTHER_VIEW: 'applyToOtherView',
        CHANGE_MEDIA: 'changeMedia',
        SCALE_UP: 'scale_up',
        SCALE_DOWN: 'scale_down',
        MOBILE_BACKGROUND_SETTINGS: 'mobileBackgroundSettings',
        ADD: 'add',
        QUICK_EDIT: 'quickEdit',
        SWITCH_LAYOUT: 'switchLayout',
        SWITCH_LAYOUT_COMPARISON: 'switchLayoutComparison',
        CUSTOM_CONNECT: 'customConnect',
        WIDGET_PLUGINS: 'widgetPlugins',
        PRESETS: 'presets',
      },
      DYNAMIC_INPUT_TYPES: {
        EXPERIMENTS: 'experiments',
        COMP_DATA: 'compData',
      },
      PLATFORM: {
        POSSIBLE_VALUES: {
          DEFAULT: 'DEFAULT',
          HIDE: 'HIDE',
          SITE_UPGRADE: 'SITE_UPGRADE',
          DEFAULT_MAIN_ACTION1: 'DEFAULT1',
          DEFAULT_MAIN_ACTION2: 'DEFAULT2',
        },
      },
      ANIMATION_TYPES: ['screenIn'],
    },
    MOBILE_EDITOR: {
      RECOMMENDED_MOBILE_HEADER_HEIGHT: 70,
      SEPARATOR_LINE_LENGTH: 234,
      MOBILE_FRAME_WIDTH: 320,
      MOBILE_TOOLS_ITEMS: {
        QUICK_ACTION_BAR: 'quickActionBar',
        ACTION_BAR: 'actionBar',
        BACK_TO_TOP: 'backToTop',
        WELCOME_SCREEN: 'welcomeScreen',
        BROWSE_THEME_COLOR: 'browserThemeColor',
      },
    },
    SELECTOR_ID: {
      EDITOR: 'editor',
      EDITING_AREA: 'editingArea',
      STAGE_CONTAINER: 'stageContainer',
      SCROLLABLE_EDITOR_STAGE: 'scrollableEditorStage',
      SCROLLABLE_EDITOR_STAGE_CONTENT: 'scrollableEditorStageContent',
      PREVIEW: 'preview',
      PREVIEW_PLACEHOLDER: 'previewPlaceholder',
    },
  },
  MOUSE_ACTION_TYPES: {
    DEFAULT: 'DEFAULT',
    DRAG: 'DRAG',
    DRAG_PUSH: 'DRAG_PUSH',
    DRAG_CROP: 'DRAG_CROP',
    SPACER: 'SPACER',
    ROTATE: 'ROTATE',
    RESIZE: 'RESIZE',
    RESIZE_PUSH: 'RESIZE_PUSH',
    RESIZE_CROP: 'RESIZE_CROP',
    RESIZE_PAGE: 'RESIZE_PAGE',
    SECTION_DRAG: 'SECTION_DRAG',
    LASSO: 'LASSO',
    DRAG_ATTEMPT: 'DRAG_ATTEMPT',
    ADD_PANEL_DRAG_TO_STAGE: 'ADD_PANEL_DRAG_TO_STAGE',
    ADD_SECTION_PANEL_DRAG_TO_STAGE: 'ADD_SECTION_PANEL_DRAG_TO_STAGE',
    CLASSIC_SECTION_RESIZE_PUSH: 'CLASSIC_SECTION_RESIZE_PUSH',
  },
  PANEL_TYPES: {
    COMP: 'compPanelFrame',
    FULL_STAGE: 'fullStageFrame',
    DOCKED_PANEL: 'dockedPanel',
    NOTIFICATION: 'notificationPanelFrame',
    FOCUS: 'focusPanelFrame',
    CONFIRMATION: 'confirmationPanelFrame',
    DANGER: 'dangerMessagePanelFrame',
    LEFT: 'leftPanelFrame',
    RIGHT: 'rightPanelFrame',
    MESSAGE: 'messagePanelFrame',
    HELP: 'helpPanelFrame',
    TOOL: 'toolPanelFrame',
    BG_SUB_PANEL: 'bgSubPanelFrame',
    BG_PANEL: 'bgPanelFrame',
    TPA_SETTINGS: 'tpaSettingsPanelFrame',
    PLATFORM: 'platform',
    EMPTY: 'emptyPanelFrame',
    SITE_SETTINGS: 'siteSettingsPanelFrame',
    SITE_SETTINGS_BRANCH_WARNING: 'siteSettingsBranchWarning',
  },
  BLOCKED_RESIZE_NOTIFICATION_COMPS: [
    'wysiwyg.viewer.components.StateBox',
    'wixui.AccordionContainer',
  ],
  MULTISELECT: {
    TYPE: 'MultiSelect',
    SINGLE_SELECTION_COMPONENT_TYPES: [
      'wysiwyg.viewer.components.tpapps.TPASection',
      'wysiwyg.viewer.components.tpapps.TPAMultiSection',
      'wysiwyg.viewer.components.tpapps.TPAGluedWidget',
      'tpa.viewer.components.tpapps.TPASection',
      'tpa.viewer.components.tpapps.TPAMultiSection',
      'tpa.viewer.components.tpapps.TPAGluedWidget',
      'mobile.core.components.Page',
      'wysiwyg.viewer.components.HeaderContainer',
      'wysiwyg.viewer.components.FooterContainer',
    ],
  },
  MOBILE: {
    MOBILE_WIZARD: {
      OPENING_STATE: {
        BEFORE: 'BEFORE',
        IN_PROGRESS: 'IN_PROGRESS',
        FINISH: 'FINISH',
      },
    },
    MOBILE_ONLY: {
      MIN_TEXT_SIZE: 13,
      MAX_TEXT_SIZE: 50,
    },
  },
  GENERAL_HELP_KEY: '58406e7a-1ad0-4105-8e37-c053b529f9d8',
  EDITOR_API_ACTION: '__editor_api_action',
  componentPanels: {
    aiTools: 'aiToolsPanel',
    settings: 'settingsPanel',
    layout: 'layoutPanel',
    animation: 'animationPanel',
    motion: 'motionPanel',
    filters: 'filtersPanel',
    behaviors: 'behaviorsPanel',
    effects: 'effectsPanel',
    mask: 'maskPanel',
    stretching: 'stretchingPanel',
    design: 'designPanel',
    customizeDesign: 'advancedStylePanel',
    link: 'linkPanel',
    manage: 'managePanel',
    background: 'backgroundPanel',
    dividers: 'dividersPanel',
    apply: 'applyPanel',
    mobileBackgroundSettings: 'mobileBackgroundSettingsPanel',
    stylable: 'stylablePanel',
    smartWriter: 'SmartWriterPanel',
    aiTextGenerator: 'aiTextGeneratorPanel',
    aiTextCreatorV2: 'aiTextCreatorV2Panel',
  },
  PANEL_NAMES: {
    TEXT_INPUT_SETTINGS: 'compPanels.panels.TextInput.settingsPanel',
    TEXT_AREA_INPUT_SETTINGS: 'compPanels.panels.TextAreaInput.settingsPanel',
    WPHOTO_SETTINGS: 'compPanels.panels.WPhoto.settingsPanel',
    RADIO_GROUP_SETTINGS: 'compPanels.panels.RadioGroup.settingsPanel',
    COMBO_BOX_INPUT_SETTINGS: 'compPanels.panels.ComboBoxInput.settingsPanel',
    COMBO_BOX_MANAGE: 'compPanels.panels.ComboBoxInput.managePanel',
    CHECKBOX_SETTINGS: 'compPanels.panels.Checkbox.settingsPanel',
    DATE_PICKER_SETTINGS: 'compPanels.panels.DatePicker.settingsPanel',
    FILE_UPLOADER_SETTINGS: 'compPanels.panels.FileUploader.settingsPanel',
    TIME_PICKER_SETTINGS: 'compPanels.panels.TimePicker.settingsPanel',
    SITE_BUTTON_SETTINGS: 'compPanels.panels.SiteButton.settingsPanel',
    SLIDER_SHOW_GALLERY_SETTINGS:
      'compPanels.panels.SlideShowGallery.settingsPanel',
    PAGINATED_GRID_GALLERY_SETTINGS:
      'compPanels.panels.PaginatedGridGallery.settingsPanel',
    MATRIX_GALLERY_SETTINGS: 'compPanels.panels.MatrixGallery.settingsPanel',
    SLIDER_GALLERY_SETTINGS: 'compPanels.panels.SliderGallery.settingsPanel',
    VIDEO_PLAYER_SETTINGS: 'compPanels.panels.VideoPlayer.settingsPanel',
    TOGGLE_SWITCH_SETTINGS: 'compPanels.panels.ToggleSwitch.settingsPanel',
    ADDRESS_INPUT_SETTINGS: 'compPanels.panels.AddressInput.settingsPanel',
    MUSIC_PLAYER_SETTINGS: 'compPanels.panels.MusicPlayer.settingsPanel',
    VIDEO_BOX_SETTINGS: 'compPanels.panels.MediaPlayer.settingsPanel',
    VECTOR_SHAPE_SETTINGS: 'compPanels.panels.VectorImage.settingsPanel',
    POPUP_CONTAINER_SETTINGS: 'compPanels.panels.PopupContainer.settingsPanel',
    TPA_SETTINGS_PANEL: 'tpa.compPanels.tpaSettings',
    LINK_PANEL: 'panels.toolPanels.linkPanel',
    ADD_PAGES_PANEL: 'addPreset.panels.addPagePanel',
  },
  PANEL_SECTIONS,
  USER_PREFS: {
    SECTIONS_MIGRATION: {
      SHOW_ADI_TO_EDITOR_TOUR: 'sectionsMigration.showAdiToEditorTour',
      SHOW_EDITOR_TO_EDITOR_TOUR: 'sectionsMigration.showEditorToEditorTour',
      SHOW_EDITOR_NEW_USERS_TOUR: 'sectionsMigration.showEditorNewUsersTour',
    },
    WIX_APPS_MIGRATION: {
      IS_NEWS_MIGRATED: 'wixapps.migration.isNewsMigrated',
      IS_MENUS_MIGRATED: 'wixapps.migration.isMenusMigrated',
      IS_LIST_BUILDER_MIGRATED: 'wixapps.migration.isListBuilderMigrated',
    },
    ACCESSIBILITY: {
      ADVANCED_SETTINGS: 'accessibility.advancedSettings',
    },
    TYPES: {
      SESSION: 'session',
      SITE: 'site',
      GLOBAL: 'global',
    },
    VIEW_TOOLS: {
      DEVELOPER_MODE_ENABLED: 'viewtools.developerModeEnabled',
      DEVELOPER_TOOLBAR_ENABLED: 'viewtools.developerToolBarEnabled',
      SHOW_HIDDEN_COMPS: 'viewTools.showHiddenComponents',
      RULERS_ENABLED: 'viewtools.rulersEnabled',
      GUIDES_STATE: 'rulers.guidesState',
      GUIDELINES_ENABLED: 'viewtools.guidelinesEnabled',
      TOOLBAR_ENABLED: 'viewtools.toolbarEnabled',
      SECTIONS_HANDLES_ENABLED: 'viewtools.sectionsHandlesEnabled',
      SNAPTO_ENABLED: 'viewtools.snapToEnabled',
    },
    DOCKED_PANEL_SECTIONS: {
      WIDGET_PUBLIC_API_ENABLED: 'dockedsections.widgetpublicapi',
    },
    TOOLBAR: {
      POSITION: 'toolbar.position',
    },
    BUSINESS_TYPE: 'businessType',
    SITE_REVISIONS: {
      SITE_HISTORY_DONT_SHOW_AGAIN: 'siteRevisions.dontshowagain',
    },
    SAVE: {
      SITE_SAVED_DONT_SHOW_AGAIN: 'save.siteSaved.dontshowagain',
      SHOW_CONNECT_DOMAIN: 'save.connectdomain.shouldshow',
      AUTOSAVE_ENABLED: 'save.autosave.enabled',
      USER_MADE_MANUAL_SAVE: 'save.userMadeManualSave',
      FEEDBACK_RELEVANT_SAVES_NUMBER: 'save.feedbackRelevantSavesNumber',
      FEEDBACK_PANEL_3RD_SAVE_SHOWN: 'save.feedbackPanelThirdSaveShown',
      FEEDBACK_PANEL_SITE_COMPLETION_SHOWN:
        'save.feedbackPanelSiteCompletionShown',
    },
    PUBLISH: {
      POST_PUBLISH_DONT_SHOW_AGAIN: 'publish.success.dontshowagain',
      MOBILE_DONT_SHOW_AGAIN: 'publish.success.mobile.dontshowagain',
      SITE_WAS_ONLY_AUTO_PUBLISHED: 'siteWasOnlyAutoPublished',
    },
    TEST_SITE: {
      TEST_SITE_BUTTON_TITLE: 'testSite.buttonLabel',
    },
    PUBLISH_RC: {
      OVERRIDE_RC_DONT_SHOW_AGAIN: 'publishrc.fail.override.dontshowagain',
      PREPARE_PANEL_RC_DONT_SHOW_AGAIN: 'publishrc.preparePanel.dontshowagain',
    },
    BRANCHES: {
      INDICATOR_PANEL_DONT_SHOW_AGAIN: 'branches.indicatorpanel.dontshowagain',
      SITE_SETTINGS_WARNING_DONT_SHOW_AGAIN:
        'branches.siteSettingsWarningDontShowAgain',
    },
    TOO_MANY_PAGES: {
      DONT_SHOW_AGAIN: 'tooManyPages.dontShowAgain',
      FREQUENCY_COUNT: 'tooManyPages.frequencyCount',
    },
    OPEN_WIDGET_CONFIRMATION: {
      DONT_SHOW_AGAIN: 'openWidgetConfirmation.dontShowAgain',
    },
    DRAG_TO_HEADER: {
      DONT_SHOW_AGAIN: 'dragToHeader.dontShowAgain',
      DRAG_COUNT: 'dragToHeader.count',
    },
    DRAG_TO_FOOTER: {
      DONT_SHOW_AGAIN: 'dragToFooter.dontShowAgain',
      DRAG_COUNT: 'dragToFooter.count',
    },
    PIN_TO_SCREEN: {
      DONT_SHOW_AGAIN: 'pinToScreen.dontShowAgain',
      PIN_COUNT: 'pinToScreen.count',
    },
    SOSP: {
      PANELS: {
        ATTACH_DO_NOT_SHOW_AGAIN: 'sosp.panels.attachedToSOSPContainer',
        ATTACH_COUNT: 'sosp.panels.attachedToSOSPCount',
        DETACH_DO_NOT_SHOW_AGAIN: 'sosp.panels.detachedFromSOSPContainer',
        DETACH_COUNT: 'sosp.panels.detachedFromSOSPCount',
      },
    },
    OUT_OF_GRID: {
      DESKTOP: {
        OUT_OF_GRID_DONT_SHOW_AGAIN:
          'dragOutsidePage.outOfGrid.desktop.dontShowAgain',
        OUT_OF_GRID_COUNT: 'dragOutsidePage.outOfGrid.desktop.count',
      },
      CONTAINER: {
        OUT_OF_GRID_DONT_SHOW_AGAIN:
          'dragOutsideContainer.outOfGrid.container.dontShowAgain',
        OUT_OF_GRID_COUNT: 'dragOutsidePage.outOfGrid.container.count',
      },
      MOBILE: {
        OUT_OF_GRID_DONT_SHOW_AGAIN:
          'dragOutsidePage.outOfGrid.mobile.dontShowAgain',
        OUT_OF_GRID_COUNT: 'dragOutsidePage.outOfGrid.mobile.count',
      },
    },
    CANT_MAKE_CHANGES: {
      MOBILE_CANT_MAKE_CHANGE_DONT_SHOW_AGAIN:
        'cantmakechanges.mobile.dontShowAgain',
    },
    THEME: {
      COLORS: {
        CUSTOM: {
          COMPS_ADDED: 'theme.colors.compsCustom',
          USER_ADDED: 'theme.colors.userCustom',
          USER_SITE_ADDED: 'theme.colors.userSiteCustom',
          USER_ADDED_GRADIENTS: 'theme.colors.userCustomGradients',
          ADDED_GRADIENTS: 'theme.colors.addedGradients',
          ADDED_MESHES: 'theme.colors.addedMeshes',
          USER_LOGO: 'theme.colors.userLogo',
          USER_PROMPT: 'theme.colors.userPrompt',
        },
        IS_MIGRATED: 'theme.colors.isMigrated',
        ARE_COLORS_SYNCHRONISED: 'theme.colors.areColorsSynchronise',
      },
      ANIMATIONS: {
        ANIMATION_KIT: 'theme.animations.animationKit',
      },
    },
    DEVELOPER_MODE: {
      FIRST_TIME: 'developer.firstTime.dontShowAgain',
      FIRST_TIME_AUTO_DEV_MODE_ON: 'developer.firstTime.autoDevModeOn',
      SHOW_HIDDEN_COMPS: 'developer.firstTime.showHiddenComponents',
      FILE_PANE_SPLITTER: 'developer.fileTreeSplitter.state',
      EXPOSED_TO_WIX_CODE: 'developer.exposed_to_wix_code',
    },
    TEXT: {
      SAVE_THEME: {
        DONT_SHOW_AGAIN: 'text.saveTheme.dontShowAgain',
      },
      OPEN_PANEL_SECTIONS: {
        TEXT_EFFECTS: 'text.openPanelSections.textEffects',
        TEXT_SPACING: 'text.openPanelSections.textSpacing',
        TEXT_SEO: 'text.openPanelSections.textSEO',
        VERTICAL_TEXT: 'text.openPanelSections.verticalText',
      },
      FONT_FAMILIES: {
        HEADING: 'text.fontFamilies.heading',
        PARAGRAPH: 'text.fontFamilies.paragraph',
      },
    },
    MOBILE_EDITOR: {
      USER_WAS_IN_MOBILE_EDITOR: 'mobileEditor.userWasInMobileEditor',
      USER_WAS_IN_MOBILE_WIZARD_KEY: 'mobileEditor.userWasInMobileWizard',
      USER_ADDED_COMPONENT_FROM_ADD_PANEL:
        'mobileEditor.userAddedComponentFromAddPanel',
      USER_PASTED_MOBILE_ONLY_COMP: 'mobileEditor.userPastedMobileOnlyComp',
      USER_SET_MOBILE_LANDING_PAGE: 'USER_SET_MOBILE_LANDING_PAGE',
      FIRST_TIME_PANEL: {
        DONT_SHOW_AGAIN: 'mobileEditor.firstTimePanel.dontShowAgain',
      },
      MOBILE_WIZARD: {
        DONT_SHOW_AGAIN: 'mobileEditor.mobileWizard.dontShowAgain',
      },
      BACK_TO_TOP_BUTTON: {
        CUSTOM_ICON: 'backToTopButton.customIcon',
        CHANGE_ICON_PANEL_WAS_OPEN: 'backToTopButton.changeIconPanel.opened',
      },
      WIX_CODE: {
        MOBILE_ONLY_CODE: {
          DONT_SHOW_AGAIN: 'mobileEditor.wixCode.mobileOnlyCode.dontShowAgain',
        },
      },
    },
    MULTILINGUAL: {
      STYLING_COMPONENT: {
        DONT_SHOW_AGAIN: 'multilingual.stylingComponent.dontShowAgain',
      },
      WIDGET_DELETED_WARNING: {
        DONT_SHOW_AGAIN: 'multilingual.langPickerDeletePanel.dontShowAgain',
      },
      COMPONENT_CHANGED_WARNING: {
        DONT_SHOW_AGAIN: 'multilingual.componentChanged.dontShowAgain',
      },
      GOOGLE_TRANSLATE_WARNING: {
        DONT_SHOW_AGAIN:
          'multilingual.googleTranslateWarningPanel.dontShowAgain',
      },
      PUBLISH_SUCCESS_PANEL: {
        DONT_SHOW_AGAIN: 'multilingual.publishSuccessPanel.dontShowAgain',
      },
      UNPUBLISH_PANEL: {
        DONT_SHOW_AGAIN: 'multilingual.unpublishPanel.dontShowAgain',
      },
    },
    UNDO: {
      DONT_SHOW_AGAIN: 'undo.dontShowAgain',
      UNDO_COUNT: 'undo.count',
      UNDO_THRESHOLD: 10,
      UNDO_PREVIOUSLY_USED: 'undo.previouslyUsed',
    },
    REDO: {
      REDO_PREVIOUSLY_USED: 'redo.previouslyUsed',
    },
    DELETE: {
      FIRST_TIME_DELETE_USED: 'delete.firstTimeUsed',
    },
    SSR_CACHE: {
      MAX_HOURS: 'MAX_HOURS',
      TOGGLE_STATE: 'TOGGLE_STATE',
      ADVANCED_SETTING: 'ADVANCED_SETTING',
    },
    GOOGLE_MAPS: {
      MULTIPLE_LOCATIONS: 'multiple-locations',
    },
    ADD_ELEMENTS: {
      ADD_ELEMENTS_OPENED: 'addElementsPanelOpened',
    },
    DEV_CENTER_SETTINGS: {
      APP_DEFINITION_ID: 'devCenterAppDefinitionId',
    },
    PREVIEW: {
      USER_SEEN_PREVIEW_QRCODE_PANEL: 'USER_SEEN_PREVIEW_QRCODE_PANEL',
    },
    IS_PAGE_DESIGN_BUTTON_HIDDEN: 'isPageDesignButtonHidden',
    DYNAMIC_PAGES: {
      SUGGEST_ON_DUPLICATE: {
        DONT_SHOW_AGAIN: 'dynamicPages.suggestOnDuplicate.dontShowAgain',
      },
    },
  },
  STYLABLE: {
    EDITOR: {
      SITE_PATH: '/site.st.css',
      THEME_PRESET_FILENAME: '_theme.st.css',
      COMPONENTS_INDEX_PATH: 'wix-ui-santa/index.st.css',
      THEME_CHANGE_DATA_COLOR_TYPE: 'COLOR',
      COLOR_ROWS: 5,
      INITIAL_PALETTE_COLORS: {
        color_1: '#000000',
        color_2: '#FFFFFF',
      },
      PALETTE_IGNORED_KEYS: ['paletteName', 'paletteTags'],
      PALETTE_ROOT_COLORS_INDICES: [0, 2, 1, 2, 3],
      PAGE_CUSTOMIZATION_PANEL: 1,
      DESIGN_PANEL_THEME_REF: 'stesantaeditordesignpanel__root',
      GRADIENT_CATEGORY: 'gradient',
      EDITORX_THEME_REF: 'steeditorxtheme__root',
      DEFAULT_PANEL_WIDTH: 240,
    },
  },
  SUPER_APPS,
  TOP_BAR: {
    TOOLTIP_ID: {
      PAGES_LIST: 'pagesTooltipID',
      ZOOMOUT: 'topBarZoomOutTooltipID',
      UNDO: 'topBarUndoTooltipID',
      REDO: 'topBarRedoTooltipID',
      PREVIEW: 'topBarPreviewTooltipID',
      WIXSTORES_MIGRATION_UPGRADE_SITE: 'store-migration-tooltip',
      MENU: 'topBarMenuTooltipId',
      BACK_TO_EDITOR: 'edit-site-tooltip',
    },
  },
  LEFT_BAR: {
    WAS_BUTTON_CLICKED_KEY: '/left_bar_button_clicked',
    SUPER_APPS_ORDER: {
      DESKTOP: [
        'blog.menuPanel',
        'blog.newMenuPanel',
        'wixStore.panels.wixStorePanel',
        'wixBookings.panels.bookingsPanel',
        'wixData.panel',
        'ascend.panel',
        'promote.panel',
      ],
      MOBILE: [
        'blog.menuPanel',
        'wixStore.panels.wixStorePanel',
        'wixBookings.panels.bookingsPanel',
      ],
    },
    MOBILE_HIDDEN_ELEMENTS: {
      TOOLTIP_TIMEOUT: 3000,
      PROGRESS: {
        IDLE: 'IDLE',
        IN_PROGRESS: 'IN_PROGRESS',
        FINISH: 'FINISH',
      },
    },
  },
  SIZE_LIMITS: {
    MOBILE_HEADER_RECOMMENDED_HEIGHT: 92,
    FIXED_MOBILE_HEADER_MAX_HEIGHT: 140,
    MOBILE_HEADER_MIN_HEIGHT: 50,
    MOBILE_MENU_MAX_Y: 250,
    MOBILE_DRAG_CONSTRAINT_MARGINS: 20,
    TINY_MENU_SIZE: 50,
  },
  RIGHT_AREA: {
    WIDTH: 228,
    WIDTH_PREVIEW: 288,
    SIDE_DEFAULT_WIDTH: 300,
  },
  LOCAL_STORAGE_TYPE: {
    CLIPBOARD: 'CLIPBOARD',
    OPEN_STAGE_IN_ZOOM_MODE: 'openStageInZoomMode',
  },
  CLIPBOARD_SCOPE: {
    GENERAL: 'GENERAL',
  },
  CLIPBOARD_ITEM_TYPE: {
    COMPONENT: 'COMPONENT',
    PAGE: 'PAGE',
  },
  DEVELOPER_MODE: {
    CONTEXT_TYPES: {
      UNKNOWN: 'UNKNOWN',
      PAGE: 'PAGE',
      COLLECTION: 'COLLECTION',
      FILE: 'FILE',
      WHATS_NEW: 'WHATS_NEW',
      TRIGGER_BACKEND_FUNCTION: 'TRIGGER_BACKEND_FUNCTION',
    },
    CONTAINER_STATES: {
      MINIMIZED: 'minimized',
      MAXIMIZED: 'maximized',
      CUSTOM: 'custom',
    },
  },
  SAVE_PUBLISH: {
    DURATION_TO_SHOW_RESULT: 3000,
    SAVE_REMINDER_TIMEOUT: 1200000,
    SAVE_TOOLTIP_TIMEOUT: 300000,
    SAVE_TOOLTIP_DURATION: 4000,
    SITE_AUTO_SAVED_TOOLTIP_DURATION: 8000,
    VALIDATION_ERROR_POPUP_INTERVAL: 180000,
  },
  PANELS,
  USER_ACTIONS: {
    ORIGIN: {
      GFPP: 'gfpp',
      DOUBLE_CLICK: 'double_click',
    },
  },
  COMP_TYPES,
  MENU_BAR_KEYBOARD_CTXT: 'MENU_BAR',
  CONNECT_DOMAIN: {
    FLOWS: {
      SAVE: 'save',
      PUBLISH: 'publish',
      PUBLISH_BUTTON: 'publish_button',
      TOP_BAR: 'top_bar',
      URL_BAR: 'url_bar',
      FIRST_MANUAL_PUBLISH: 'first_manual_publish',
      SAVE_SUCCESS_PANEL_CONTINUE: 'saveSuccessPanel_footer_continueButton',
    },
  },
  ANCHORS_MENU: {
    NEW_ANCHOR_POS_MARGIN: 60,
  },
  PREVIEW: {
    FLOATING_BUBBLE_TIMEOUT: 4000,
  },
  SECTIONS_REORGANIZE: {
    ZOOM_OUT_SCALE: 0.5,
    ZOOM_IN_SCALE: 1,
    ZOOM_IN_DELAY_MS: 500,
    STRIP_WIDTH: 24,
  },
  MUSIC_PROMO: {
    TEMPLATE_IDS: [
      'a40fa859-2414-4ed7-879d-85162a1d7d7f',
      'd19b7f18-b7b1-4ba5-a411-899b180e92e0',
      'ef885be1-3846-4bf3-bdb3-2ba8df0e7f17',
      'c063226c-389a-4d04-ac82-8433bc1dfd99',
      'd15fe54e-5758-42f0-a004-5330bd226934',
      'c879820a-c71b-4697-ac94-7ac5d4dd60db',
      '06e8e719-3f93-4fba-b143-e7d050805a53',
      '888ea0c5-1e5f-44dc-bef1-b279be1ada5d',
      '602d464e-8e5a-48ff-966e-ca12e0aab208',
      '13110526-b739-2b05-bd74-7e88d98c6bae',
      '2b6824b2-3c26-4431-b228-82933a68ebe7',
      '1311050e-5dca-f284-ebd0-1c5b6e60b8f7',
      '12b78afc-e353-4c54-9581-f324231f4a4a',
      '93abb361-5c81-4eaa-9a4b-3ed652a3c43a',
      '11752135-7cd5-4f42-ad8d-47fddc794831',
      '1010613c-4bd3-4250-9bbf-3beface22800',
      'ed2956be-df81-48c5-9bb2-04a58d83d1bd',
      '1328e84c-b14a-19f6-9383-40a56332ac0d',
      '19f7496d-f1e1-4b83-8804-74442b5b1c62',
      'dafd8a1a-56dd-4a89-97b7-3d37c754577f',
      '9173e5f8-12b3-4297-b18d-e8255a9a5770',
      'edba47b4-9bbb-4f60-8914-31441576141f',
      '1177cae2-6ad2-4993-9f6a-656cd875854a',
      '858e22eb-933d-47ed-b9c4-f3da0d16358d',
      'd9c80554-eee7-48a0-a36e-8f10b7f05bdf',
      'a80ba887-f853-44cf-aeee-218b716bffad',
      '5aceb67b-a429-4e6f-98df-819ab8e54535',
      'dfe198cd-3474-454f-aa26-082de7730632',
      '24ef8660-7354-4687-8028-f6c6514d4a70',
      '9c99dd80-7b8b-4f1c-abc2-b67923579571',
      'fcb83e7c-bf51-4f12-a213-ced349322508',
      '7776cbc7-a889-470a-b106-0629717bf9ac',
      '5c1beed4-ac61-4783-a2ea-182dece51d13',
      '717ae0ef-b9b4-44eb-9765-4412d2b6ec9f',
      'af2d12f6-698b-4e0d-a537-60f6c6db815e',
      'a0e9bcd6-25aa-4b01-aea2-bcaa26ccc009',
      '3acde861-ac0f-41ab-b559-f696ae09df96',
      'e72ca344-638d-45cd-900d-28efdb7214c0',
      'c8bcc939-022d-407d-a438-d8dbff264c8a',
      '935c05d6-8e89-4f07-acf0-3f754c5ecce4',
      '7780d359-bab9-4668-8548-651497073b8f',
      '69a0b246-1700-40e5-b6ce-998e7e91e7a2',
      '0a5e98d6-e012-4945-b86c-3e2e889e5dcd',
      '85cfc126-992c-4b8e-90f9-24010258603a',
      '57208171-c020-47ea-92ee-73906cddab53',
      '6db4bd1e-791d-41ef-94f6-6d29cf08af66',
      '7fdcf344-27eb-4292-94df-4960f93aa834',
      '68742d4b-c9e7-4809-9594-0da828699a72',
      '92f4869b-53e4-4b9c-8fe7-f6ba6967848b',
      'ccf8f527-66e0-42be-acb5-8d5e58daebf0',
      '13110226-4a9e-f84b-c38b-e8146cb5f729',
      '253b97cc-baf0-43d6-8851-fe412cce7fba',
      '17ec5e01-64cd-4077-aa33-72dc97c5388e',
    ],
    WIDGET_ID: '13bb5dc2-a098-5a74-502b-6bac84e80b23',
    APP_DEFINITION_ID: '13bb5d67-1add-e770-a71f-001277e17c57',
  },
  PHOTOGRAPHY_PROMO,
  ADD_PANEL: {
    SECTION_COMP_TYPES: [
      'wysiwyg.viewer.components.StripContainer',
      'wysiwyg.viewer.components.BgImageStrip',
      'tpa.viewer.components.StripSlideshow',
      'tpa.viewer.components.StripShowcase',
      'tpa.viewer.components.Collage',
      'wysiwyg.viewer.components.StripContainerSlideShow',
      'wysiwyg.viewer.components.StripColumnsContainer',
    ],
    SECTION_NAMES: {
      ONE_ITEM_REPEATER: 'oneItemRepeaterSection',
    },
  },
  LINK_PANEL: {
    noLinkTabLabel: 'noLinkTabLabel',
    externalLinkTabLabel: 'externalLinkTabLabel',
    pageLinkTabLabel: 'pageLinkTabLabel',
    anchorLinkTabLabel: 'anchorLinkTabLabel',
    edgeAnchorLinksLabel: 'edgeAnchorLinksLabel',
    docLinkTabLabel: 'docLinkTabLabel',
    emailLinkTabLabel: 'emailLinkTabLabel',
    phoneLinkTabLabel: 'phoneLinkTabLabel',
    whatsAppLinkTabLabel: 'whatsAppLinkTabLabel',
    addressLinkTabLabel: 'addressLinkTabLabel',
    popupLinkTabLabel: 'popupLinkTabLabel',
    homePageLoginTabLabel: 'homePageLoginTabLabel',
  },
  VERTICAL_ANCHORS_MENU: {
    OFFSET: {
      RIGHT: 16,
    },
  },
  BI: {
    HELP: {
      ORIGIN: {
        ADD_PANEL: 'addpanel',
        PAGES_PANEL: 'pagespanel',
        LEFTBAR: 'leftbar',
        GFPP: 'gfpp',
        PAGES: 'pages',
        PANEL: 'panel',
        TOPBAR: 'topbar',
        TOOLBAR: 'toolbar',
        EDITOR_SEARCH: 'editor_search',
      },
    },
    PREVIEW: {
      ORIGIN: {
        TOP_BAR: 'topbar',
        MENU_BAR: 'menubar',
        INTERACTION: 'interaction',
        DYNAMIC_PAGES: 'dynamic_pages',
        KEYBOARD: 'keyboard',
        GFPP: 'gfpp',
        TPA: 'tpa',
        WIDGET: 'widget',
        PLATFORM: 'platform',
      },
    },
  },
  PROGRESS_STATUS: {
    IDLE: 'idle',
    IDLE_SAVED: 'idleSaved',
    IN_PROGRESS: 'inProgress',
    AUTO_SAVE_IN_PROGRESS: 'autoSaveInProgress',
    DONE_SUCCESS: 'doneSuccess',
    DONE_FAILED: 'doneFail',
  },
  COMP_ALIGNMENT_OPTIONS: {
    LEFT: 'left',
    RIGHT: 'right',
    CENTER: 'center',
    TOP: 'top',
    BOTTOM: 'bottom',
    MIDDLE: 'middle',
  },
  COMP_DISTRIBUTION_OPTIONS: {
    BOTH: 'verticalAndHorizontal',
    VERTICAL: 'vertical',
    HORIZONTAL: 'horizontal',
  },
  COMP_MATCH_SIZE_OPTIONS: {
    WIDTH: 'width',
    HEIGHT: 'height',
    BOTH: 'heightAndWidth',
  },
  ROUTER_TYPES: {
    CUSTOM_ROUTER: 'Custom Router',
    DATA_BINDING: 'Data',
  },
  OVARLAPPING_COMP: {
    DELAY: 3000,
    HIDE_DELAY: 15000,
    MIN_OVERLAP: 70,
  },
  ADD_PANEL_DATA: {
    POPUP: {
      FORBIDDEN_SECTIONS_OF_OTHER_CATEGORY: [
        'anchorsSection',
        'verticalAnchorsMenuSection',
      ],
    },
  },
  PAGE_SETTINGS_TABS: {
    PAGE_INFO: 'page_info',
    LAYOUT: 'layout',
    MOBILE_LAYOUT: 'mobile_layout',
    PERMISSIONS: 'permissions',
    SEO: 'seo',
    BASIC_SEO: 'BASIC',
    SOCIAL: 'SOCIAL',
    SOCIAL_NEW: 'social',
    ADVANCED: 'advanced',
    TITLE_KEYS: {
      BASIC_SEO: 'Pages_Actions_SEO',
      SOCIAL: 'Pages_Actions_Social',
    },
  },
  COMP_PANELS: {
    POPUP: {
      CONTAINER: {
        POPUP_GRID_LAYOUT_PANEL: {
          NAME: 'compPanels.dynamicPanels.popupGridLayoutPanel',
        },
        POPUP_FULL_LAYOUT_PANEL: {
          NAME: 'compPanels.dynamicPanels.popupFullLayoutPanel',
        },
        XBUTTON_REMOVE_BUBBLE: {
          NAME: 'compPanels.panels.PopupCloseIconButton.bubbleOnClose',
          TOP_OFFSET: 50,
          AUTO_CLOSE_DELAY: 10000,
        },
        SETTINGS_PANEL: {
          PAGES_SELECTOR_WIDTH: 288,
          PAGES_SELECTOR_PANEL_NAME: 'panels.toolPanels.pagesSelectorPanel',
          NAME: 'compPanels.panels.PopupContainer.settingsPanel',
          PAGE_SELECTION_BUTTON: {
            ICON: 'pageSelector',
            COLLISION_ICON: 'inputValidationError',
          },
        },
        DESIGN_PANEL: {
          NAME: 'compPanels.panels.PopupContainer.designPanel',
        },
      },
      OVERLAY: {
        DESIGN_PANEL: {
          NAME: 'compPanels.panels.PopupOverlayContainer.designPanel',
        },
        SETTINGS_PANEL: {
          NAME: 'rEditor.panels.popup.overlaySettingsPanel',
        },
      },
    },
  },
  VECTOR_IMAGE_DEFAULTS: {
    VECTOR_SHAPE_DEFAULT_STYLE: {
      strokeWidth: 4,
      opacity: 1,
      stroke: '#5E97FF',
      strokeOpacity: 1,
      enableStroke: false,
    },
    VECTOR_ART_DEFAULT_STYLE: {
      opacity: 1,
    },
    VECTOR_SHAPE_COLOR_DEFAULT: {
      color1: '#242323',
    },
    VECTOR_ART_COLOR_KEYS: [
      'color1',
      'color2',
      'color3',
      'color4',
      'color5',
      'color6',
      'color7',
      'color8',
    ],
    VECTOR_IMAGE_CATEGORY: 'VECTOR_ART',
    VECTOR_SHAPE_DEFAULT_PATH:
      'public/ab62ca24cd194952aad0707eead0c0f7/21555df8496d4c3c86bae2b4fcba13f1',
    VECTOR_ART_DEFAULT_PATH:
      'public/98285ba8ab164d17a935742bd019e150/ed8054c8d49f41bb92efb3cb42c5a85c',
  },
  UI: {
    gfppMargins: 18,
    componentLabelsGfppMargins: 30,
    navControlsGfppMargins: 36,
    compPanelMargins: 24,
    OLD_TOPBAR_HEIGHT: 48,
    TOPBAR_HEIGHT: 89,
    VIEWPORT_HORIZONTAL_MARGINS: 24,
    VIEWPORT_VERTICAL_MARGINS: 24,
    VIEWPORT_VERTICAL_MARGINS_GFPP: 68,
    VIEWPORT_VERTICAL_MARGINS_NAV_CONTROLS: 82,
    EDITING_AREA_RIGHT_MARGIN: 40,
    FOCUS_FRAME_BORDER_WIDTH: 3,
    WIX_CODE_TOPBAR_HEIGHT: 36,
    LEFTBAR_BTN_WIDTH_COLLAPSED: 48,
    FIXED_STAGE_MARGIN_TOP: 36,
    MOBILE_PREVIEW_TOP: 59,
    MOBILE_PREVIEW_TOP_NEW_WORKSPACE: 18,
    MOBILE_PREVIEW_MODE_HEIGHT: 512,
    MOBILE_PREVIEW_BOTTOM: 94,
    ANCHOR_OFFSET_TOP: -12,
    ANCHOR_HEIGHT: 25,
    ANCHOR_WIDTH: 141,
    CUSTOM_GFPP_POS: {
      Y_OFFSET_FROM_CLICK: -12,
      X_OFFSET_FROM_CLICK: -48,
      HEIGHT_TO_APPLY: 500,
    },
    COLOR_FORMATS: {
      HEX: 'hex',
      RGB: 'rgb',
      HSB: 'hsb',
    },
    BUBBLE: {
      DISTANCE_FROM_TARGET: 10,
      MARGINS_FROM_WINDOW: 6,
    },
    PAGES_PANEL_FIRST_STEPS: {
      MARGINS_FROM_ELEMENT: 22,
      POSITION_ADJUSTMENT_VALUE: 35,
    },
    TOOLTIP: {
      ALIGNMENT: {
        TOP: 'top',
        LEFT: 'left',
        RIGHT: 'right',
        BOTTOM: 'bottom',
      },
      TRIGGERS: {
        CLICK: 'onClick',
        MOUSE_ENTER: 'onMouseEnter',
        MOUSE_LEAVE: 'onMouseLeave',
        OUTER_CLICK: 'outerClick',
        IMMEDIATE: 'immediate',
        TIMER: 'timer',
      },
      VALUE_TYPE: {
        STRING: 'string',
        CLASS: 'class',
        TEMPLATE: 'template',
      },
      STYLE_TYPE: {
        SMALL: 'small',
        NORMAL: 'normal',
        CONTENT_ONLY: 'contentOnly',
      },
    },
    NAVIGATION_TYPES: {
      TABS: 'tabs',
      PAGINATION: 'pagination',
      DROPDOWN: 'dropdown',
      INTERACTION: 'interaction',
    },
    NAVIGATION_CONTROLS_POS: {
      TOP: 'top',
      BOTTOM: 'bottom',
      LEFT: 1,
      RIGHT: '',
    },
    OFFSET_TOP_WHEN_TOOLS_HIDDEN: 91,
    OLD_OFFSET_TOP_WHEN_TOOLS_HIDDEN: 42,
    VIDEO: {
      TYPE: {
        YOUTUBE: 'YOUTUBE',
        VIMEO: 'VIMEO',
        DAILYMOTION: 'DAILYMOTION',
      },
      URL: {
        YOUTUBE: '//www.youtube.com/embed/',
        VIMEO: '//player.vimeo.com/video/',
        DAILYMOTION: '//www.dailymotion.com/embed/video/',
        FACEBOOK:
          '//www.facebook.com/plugins/video.php?href=https://www.facebook.com/',
      },
      QUERY_PARAMS: {
        YOUTUBE: {
          wmode: 'transparent',
          theme: 'light',
          controls: '0',
          autohide: '0',
          loop: '1',
          showinfo: '0',
        },
        VIMEO: {},
        DAILYMOTION: {
          controls: '0',
          'sharing-enable': '0',
          'ui-logo': '0',
          'ui-start-screen-info': '0',
        },
        FACEBOOK: {},
      },
    },
    LEGACY_FIT_DISPLAY_MODE: 'legacyFit',
    IMAGE_DATA_KEYS: [
      'uri',
      'width',
      'height',
      'title',
      'originalImageDataRef',
      'type',
    ],
    CLIPART_DATA_KEYS: ['uri', 'width', 'height', 'title'],
    MARGIN_BETWEEN_FOCUS_TAB_AND_COMP_LABELS: 6,
    HIGHLIGHTS: {
      TYPES: {
        THICK: 'thick',
        THICK_WITH_OPACITY: 'thick with-opacity',
        THICK_LIGHT: 'thick light-theme',
        OVERLAY_WITH_OPACITY: 'with-background with-background-opacity',
        THIN: 'thin',
        OVERLAY: 'with-background',
        DYNAMIC: 'thick with-opacity dynamic',
        NORMAL: 'normal',
        NORMAL_WITH_OVERLAY: 'normal with-background',
      },
      THICK_WIDTH: 3,
    },
  },
  NOTIFICATIONS: {
    TYPES: {
      WARNING: 'warning',
      ERROR: 'error',
      INFO: 'info',
      SUCCESS: 'success',
      TRACKING: 'tracking',
    },
    TIMEOUT: 10000,
    PRESETS: {
      REPEATER_MAX_ITEMS_EDITOR_LIMITATION: 'repeaterMaxItemsEditorLimitation',
    },
  },
  MULTILINGUAL: {
    LANGUAGE_STATUSES: {
      INACTIVE: 'Inactive',
      ACTIVE: 'Active',
      DELETED: 'Deleted',
    },
    keyboardContextKey: 'CURRENT_LANGUAGE_SWITCH',
    linguistDefId: '14d84998-ae09-1abf-c6fc-3f3cace5bf19',
    sitePropertyServiceUrl: '/_api/site-properties-service/properties',
  },
  MEDIA_TYPES: {
    COLOR: 'Color',
    IMAGE: 'Image',
    VIDEO: 'WixVideo',
  },
  RESIZE_SIDES: {
    TOP: 'RESIZE_TOP',
    LEFT: 'RESIZE_LEFT',
    BOTTOM: 'RESIZE_BOTTOM',
    RIGHT: 'RESIZE_RIGHT',
  },
  RESIZE_DIRECTIONS: [
    'topLeft',
    'top',
    'topRight',
    'right',
    'bottomRight',
    'bottom',
    'bottomLeft',
    'left',
  ],
  MEDIA_MANAGER_MEDIA_TYPES: {
    PICTURE: 'picture',
    VIDEO: 'video',
    MUSIC: 'music',
    DOCUMENT: 'document',
    SHAPE: 'shape',
    SWF: 'swf',
    ARCHIVE: 'archive',
  },
  TAB_TITLE_KEYS: {
    EDITOR: 'EDITOR_BROWSER_TAB_NAME',
    APP_BUILDER: 'APPBUILDER_BROWSER_TAB_NAME',
  },
  STATIC_PAGES: {
    MAX_STATIC_PAGES_ALLOWED: 100,
    WARNING_COUNTER_NUMBER_OF_STATIC_PAGES: 70,
    WARNING_NUMBER_OF_STATIC_PAGES: 50,
    USER_PREF_FLOW_DYNAMIC_PAGES: 'USER_PREF_FLOW_DYNAMIC_PAGES',
  },
  SSR_CACHE: {
    AUTO: 'auto',
    MANUAL: 'manual',
  },
  INLINE_POPUP: {
    TYPES: [
      'wysiwyg.viewer.components.InlinePopup',
      'wysiwyg.viewer.components.MenuContainer',
    ],
    BEHAVIOR_NAME: 'toggle',
    STATES: { OPEN: 'open', CLOSED: 'closed' },
  },
  ACCESSIBILITY: {
    A11Y_ATTRIBUTES: [
      'label',
      'live',
      'level',
      'role',
      'atomic',
      'hidden',
      'current',
      'tabindex',
      'invalid',
      'multiline',
      'autocomplete',
      'expanded',
      'pressed',
      'haspopup',
      'disabled',
      'busy',
      'owns',
      'relevant',
      'roledescription',
      'activedescendant',
      'braillelabel',
      'brailleroledescription',
      'checked',
      'colcount',
      'colindex',
      'colindextext',
      'colspan',
      'controls',
      'describedby',
      'description',
      'details',
      'dropeffect',
      'errormessage',
      'flowto',
      'grabbed',
      'keyshortcuts',
      'labelledby',
      'multiselectable',
      'orientation',
      'placeholder',
      'posinset',
      'readonly',
      'required',
      'rowcount',
      'rowindex',
      'rowindextext',
      'rowspan',
      'selected',
      'setsize',
      'sort',
      'valuemax',
      'valuemin',
      'valuenow',
      'valuetext',
    ],
  },
  META_DATA: {
    DATA_EDIT_OPTIONS: {
      DEFAULT: 'FULL',
      TEXT: {
        STYLE_ONLY: 'TEXT_STYLE_ONLY',
      },
    },
  },
  BOX_SLIDE_SHOW: {
    GFPP: {
      ACTIONS: {
        LABELS: {
          REGULAR_SLIDESHOW_SETTINGS_MANAGE_SLIDES:
            'Regular_Slideshow_Settings_Manage_Slides',
        },
      },
    },
  },
  STATE_BOX: {
    GFPP: {
      ACTIONS: {
        LABELS: {
          MANAGE_STATES: 'gfpp_mainaction_multiStateBox',
        },
      },
    },
  },
  GALLERIES: {
    GALLERIES_COMP_TYPES: [
      'wysiwyg.viewer.components.SliderGallery',
      'wysiwyg.viewer.components.SlideShowGallery',
      'wysiwyg.viewer.components.MatrixGallery',
      'wysiwyg.viewer.components.PaginatedGridGallery',
      'wysiwyg.viewer.components.MediaZoom',
      'wysiwyg.viewer.components.tpapps.TPA3DGallery',
      'wysiwyg.viewer.components.tpapps.TPA3DCarousel',
      'tpa.viewer.components.Masonry',
      'tpa.viewer.components.Accordion',
      'tpa.viewer.components.Impress',
      'tpa.viewer.components.Freestyle',
      'tpa.viewer.components.Collage',
      'tpa.viewer.components.Honeycomb',
      'tpa.viewer.components.StripShowcase',
      'tpa.viewer.components.StripSlideshow',
      'tpa.viewer.components.Thumbnails',
    ],
  },
  SITE_CREATION: {
    OPEN_METHOD_NAME: 'Editor2.0 wizard',
    SCREENS: {
      BUSINESS_CARD: 'business_card',
      KITS: 'kits',
      HOMEPAGE: 'homepage',
      PAGES: 'pages',
    },
    NAVIGATIONS: {
      AUTO_SKIP: 'auto_skip',
      SKIP: 'skip',
      NEXT: 'next',
      EDIT_SITE: 'edit-site',
    },
  },
  SITE_GENERATION: {
    OPEN_METHOD_NAME: 'SiteGeneration',
  },
  COMP_META_DATA: {
    CONTROLS: {
      GFPP_BUTTON_CLICK: 'GFPP_BUTTON_CLICK',
      FOCUS_BOX: 'FOCUS_BOX',
      ACCESSIBILITY: 'ACCESSIBILITY',
    },
  },
  CUSTOM_ERROR_PAGE: {
    DEFAULT_URL: 'error404',
  },
  GHOST_REF_COMP: 'GHOST_REF_COMP',
  NEW_RELEASES_SUBJECT_NAMES: ['Editor', 'BusinessManager'],
  PANEL_RESOLVE_TYPE: {
    MAIN_ACTION: 'mainActionClicked',
    SECONDARY_ACTION: 'secActionClicked',
    CLOSE_ACTION: 'closeActionClicked',
  },
  DESIGN_PANEL: {
    VIEWS: {
      BACKGROUND: 'BACKGROUND',
      LOBBY: 'LOBBY',
      THEME: 'THEME',
      TRANSITIONS: 'TRANSITIONS',
      TEXT: 'TEXT',
      TEXT_THEME: 'TEXT_THEME',
      COLOR: 'COLOR',
      COLOR_THEME: 'COLOR_THEME',
      HEADER_SCROLL: 'HEADER_SCROLL',
      ADVANCED_SETTINGS: 'ADVANCED_SETTINGS',
      ANIMATIONS: 'ANIMATIONS',
    },
  },
  GET_FEEDBACK: {
    NEW_COUNTER_API: 'https://editor.wix.com/feedback-mv/v1/counts',
  },
  DYNAMIC_PAGES: {
    SORT_INNER_ROUTES: {
      TITLE: 'title',
      NO_SORT: 'no-sort',
    },
  },
  COMPONENT_DESIGN: {
    EMPTY_BACKGROUND: {
      background: {
        type: 'BackgroundMedia',
        mediaRef: null as AnyFixMe,
        alignType: 'center',
        fittingType: 'fill',
        color: 'color_11',
        colorOpacity: 0,
        colorLayers: [
          {
            type: 'SolidColorLayer',
            opacity: 0,
            fill: {
              type: 'SolidColor',
              color: 'color_11',
            },
          },
        ],
        colorOverlay: '',
        colorOverlayOpacity: 1,
        imageOverlay: '',
        scrollType: 'none',
      },
    },
  },
} as const;

export interface ImageData {
  id?: string;
  opacity?: number;
  type: Extract<MediaTypes, 'Image'>;
  alt?: string;
  title?: string;
  uri: string;
  width: number;
  height: number;
  metaData?: string;
  focalPoint?: FocalPointTypes.FocalPointValue;
  artist?: any;
}

export interface Mask {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  svgId?: string;
  mode?: string;
}

export interface VideoData {
  id?: string;
  posterImageRef: ImageData;
  opacity?: number;
  type: Extract<MediaTypes, 'WixVideo'>;
  alt?: string;
  title?: string;
  metaData?: string;
  loop?: boolean;
  playbackSpeed?: number;
  uri?: string;
  videoId?: string;
  focalPoint?: FocalPointTypes.FocalPointValue;
  artist?: any;
  qualities?: any[];
  mask?: Mask;
  mediaFeatures?: string[];
}

export interface BackgroundData {
  id?: string;
  type: 'BackgroundMedia';
  fittingType: FittingType;
  showOverlayForMediaType?: string;
  alignType: string;
  colorLayers?: any[];
  colorOverlay?: string;
  colorOverlayOpacity?: number;
  color?: string;
  colorOpacity?: number;
  scrollType?: string;
  mediaRef?: ImageData | VideoData;
  imageOverlay?: ImageData;
  filterEffect?: any;
}

export type GridLinesType = ValueOf<typeof baseConstants.GRID_LINES_TYPES>;
export type Feature = ValueOf<typeof baseConstants.FEATURES>;
export type ProgressStatus = ValueOf<typeof baseConstants.PROGRESS_STATUS>;
export type MediaTypes = ValueOf<typeof baseConstants.MEDIA_TYPES>;
export type DesktopSuperApps = ValueOf<
  typeof baseConstants.LEFT_BAR.SUPER_APPS_ORDER.DESKTOP
>;
export type PanelType = ValueOf<typeof baseConstants.PANEL_TYPES>;
export type PanelSection = ValueOf<typeof baseConstants.PANEL_SECTIONS>;
export type GfppActions = ValueOf<typeof baseConstants.ROOT_COMPS.GFPP.ACTIONS>;
export type MatchSizeValue = ValueOf<
  typeof baseConstants.COMP_MATCH_SIZE_OPTIONS
>;

export default baseConstants;
