import type {
  ContentItem,
  ISectionBrief,
  ListContentItem,
  Volume,
} from '@wix/adi-content-api';
import {
  widgetToAppMap,
  AppToAppDefId,
  ContentManager,
} from '@wix/adi-content-api';
import {
  countNumberOfContentItems,
  createFlatStructureMap,
  getLocale,
} from '../utils';
import { caasContentAdapter } from './caasContentAdapter';
import {
  APP_TO_HARD_CODED_SOURCE_TEMPLATE_ID,
  CE_TYPES_THAT_MUST_HAVE_CSM_DEFINITION_TO_RENDER,
  CE_TYPES_TO_ENRICH_MEDIA,
  DEFAULT_INDUSTRY_ID,
  DEFAULT_STRUCTURE_ID,
} from './caasConsts';
import { ContentKind, TEMP_USER_DATA } from '../consts';
import {
  createSectionContent,
  getAppNamesAndAppDefIdsEntries,
  getSourceTemplateIdKey,
  isListContentItem,
} from './caasUtils';
import { CeType } from '@wix/adi-core-types';
import {
  APP_ID_TO_CE_TYPE,
  getCeTypeToAppDefIdsMap,
} from './sitemapRulesConsts';
import {
  addNewSection,
  getCeTypeToSectionIndex,
  getSectionIndex,
  getWidgetPositionCfg,
  isSectionBlocked,
  removeSectionsIfNeeded,
  sortAppDefIdsByPriority,
  replaceContentCategory,
} from './sitemapRulesUtils';

import type { AppInstallOption } from '@wix/editor-platform-host-integration-apis';
import type {
  ExtraContentItem,
  ExtraListContentItem,
  GetContentOptions,
  IndustryProfileData,
  PageData,
  PageSectionContent,
  ProviderContent,
  SuggestedKit,
  UserData,
} from '../types';
import type { CompStructure } from '@wix/document-services-types';

export class CaasProvider {
  private readonly _caas: ContentManager;
  private _profileId: string = '';
  private _industryId: string = '';
  private _structureId: string = '';

  constructor({
    origin = 'contentProvider',
    locale,
    contentVersion,
  }: {
    origin?: string;
    locale?: string;
    contentVersion?: string;
  }) {
    this._caas = new ContentManager({
      origin,
      locale: locale ?? getLocale(),
      contentVersion,
    });
  }

  async initContent(industryId?: string, structureId?: string): Promise<void> {
    const isSameContent =
      this._industryId === industryId && this._structureId === structureId;
    const didInitContent = !!this._industryId && !!this._structureId;
    const hasNoContentId = !industryId && !structureId;
    if (isSameContent || (hasNoContentId && didInitContent)) {
      return;
    }
    const partialIds =
      (!industryId && structureId) || (industryId && !structureId);
    if (partialIds) {
      throw new Error(
        `Missing one of the content ids: industryId=${industryId}, structureId=${structureId}`,
      );
    }
    this._industryId = hasNoContentId ? DEFAULT_INDUSTRY_ID : industryId!;
    this._structureId = hasNoContentId ? DEFAULT_STRUCTURE_ID : structureId!;
    await this._caas.initContent(this._industryId, this._structureId, {});
  }

  setReplacements(userData: UserData) {
    this._caas.setReplacements({
      'GeneralDescription.BusinessName':
        userData.businessName ?? TEMP_USER_DATA.businessName,
      'GeneralDescription.MainEmail': userData.email ?? TEMP_USER_DATA.email,
      'GeneralDescription.BusinessType':
        userData.businessTypeName ?? TEMP_USER_DATA.businessTypeName,
      'Contact.PhoneNumber': userData.phoneNumber ?? TEMP_USER_DATA.phoneNumber,
      'Contact.email': userData.email ?? TEMP_USER_DATA.email,
    });
  }

  private getCaasContent(
    ceType: CeType,
    numberOfListItems: number | undefined,
    count: number,
  ): ExtraListContentItem[] | ExtraContentItem[] | undefined {
    const MAX_OFFSET = 99;
    const randomOffset = Math.floor(Math.random() * MAX_OFFSET);

    const sections = this._caas.getPages()?.[0].sections;
    const fullCeType =
      sections?.filter((section) => section.ceType === ceType)[0] ?? ceType;

    const ceTypeContent = this.generateContent(
      numberOfListItems,
      fullCeType,
      count,
      randomOffset,
    );

    if (!ceTypeContent) {
      return;
    }

    this.enrichMediaContent(
      ceType,
      sections,
      numberOfListItems,
      count,
      randomOffset,
      ceTypeContent,
    );

    return ceTypeContent;
  }

  private generateContent(
    numberOfListItems: number | undefined,
    fullCeType: ISectionBrief | CeType,
    count: number,
    randomOffset: number,
  ) {
    return numberOfListItems
      ? this._caas.generateListContentItems(
          fullCeType,
          count,
          numberOfListItems,
          randomOffset,
        )
      : this._caas.generateContentItems(fullCeType, count, randomOffset);
  }

  private enrichMediaContent(
    ceType: CeType,
    sections: ISectionBrief[] | undefined,
    numberOfListItems: number | undefined,
    count: number,
    randomOffset: number,
    ceTypeContent: ListContentItem[] | ContentItem[],
  ) {
    if (!CE_TYPES_TO_ENRICH_MEDIA.includes(ceType)) {
      return;
    }
    const fullContactCeType =
      sections?.filter((section) => section.ceType === CeType.Contact)[0] ??
      CeType.Contact;
    const extraCeTypeContent = this.generateContent(
      numberOfListItems,
      fullContactCeType,
      count,
      randomOffset,
    );
    if (!Array.isArray(extraCeTypeContent)) {
      return;
    }
    if (isListContentItem(extraCeTypeContent)) {
      (ceTypeContent as ExtraListContentItem[]).forEach(
        (contentItem: ExtraListContentItem, index: number) => {
          contentItem.items.forEach(
            (item: ExtraContentItem, itemIndex: number) => {
              item.extraMedia =
                extraCeTypeContent[index].items[itemIndex].media;
            },
          );
          contentItem.header.extraMedia =
            extraCeTypeContent[index].header.media;
          contentItem.footer.extraMedia =
            extraCeTypeContent[index].footer.media;
        },
      );
    } else {
      ceTypeContent.forEach((contentItem: ExtraContentItem, index: number) => {
        contentItem.extraMedia = extraCeTypeContent[index].media;
      });
    }
  }

  private getSourceTemplateIdsMap(): Record<string, string> {
    const caasSourceTemplateIdsMap = this._caas.getTpaMetasitesIDs() || {};
    const hardCodedSourceTemplateIdsMap = Object.entries(
      APP_TO_HARD_CODED_SOURCE_TEMPLATE_ID,
    ).reduce((acc, [appDefId, sourceTemplateId]) => {
      const ceTypes = APP_ID_TO_CE_TYPE[appDefId];
      if (ceTypes) {
        ceTypes.forEach((ceType) => {
          acc[ceType] = sourceTemplateId;
        });
      }
      return acc;
    }, {} as Record<string, string>);
    return { ...caasSourceTemplateIdsMap, ...hardCodedSourceTemplateIdsMap };
  }

  private gatherCaasAvailableAppDefIds(): string[] {
    const appsFromCaas = Object.keys(this.getSourceTemplateIdsMap())
      .map((ceTypeOrWidgetType) => {
        const appTypeOrCeType =
          widgetToAppMap[ceTypeOrWidgetType] || ceTypeOrWidgetType;
        return (
          AppToAppDefId[appTypeOrCeType] ||
          getCeTypeToAppDefIdsMap()[appTypeOrCeType as CeType]
        );
      })
      .flat()
      .filter(Boolean) as string[];
    return [...new Set(appsFromCaas)];
  }

  private filterSectionsByAppDefIds(
    sections: PageSectionContent[],
    chosenAppDefIds: string[],
  ): PageSectionContent[] {
    const allAvailableAppDefIds = new Set([
      ...this.gatherCaasAvailableAppDefIds(),
      ...chosenAppDefIds,
    ]);
    return sections.filter((section) => {
      if (
        CE_TYPES_THAT_MUST_HAVE_CSM_DEFINITION_TO_RENDER.has(section.ceType)
      ) {
        const appDefIds = getCeTypeToAppDefIdsMap()[section.ceType];
        if (appDefIds?.length) {
          return appDefIds.some((appDefId) =>
            allAvailableAppDefIds.has(appDefId),
          );
        }
      }
      return true;
    });
  }

  async getContent(
    { industryId, structureId, contentType }: GetContentOptions,
    compStructure: CompStructure,
    contentKind: ContentKind = ContentKind.ALL,
  ): Promise<ProviderContent[]> {
    await this.initContent(industryId, structureId);
    const flatStructureMap = createFlatStructureMap(compStructure);
    const count = countNumberOfContentItems(flatStructureMap);
    const numberOfListItems = countNumberOfContentItems(flatStructureMap, true);
    const caasContentItems = contentType
      ? this.getCaasContent(contentType, numberOfListItems, count)
      : undefined;
    return caasContentAdapter(caasContentItems, contentKind);
  }

  private getPageSectionsContents(
    sections: ISectionBrief[],
    installedAppDefIds: string[],
    appDefIdsNotToPersonalize: string[],
  ): PageSectionContent[] {
    const sourceTemplateIdsByCeType = this.getSourceTemplateIdsMap();
    const ceTypesNotToPersonalize = new Set<CeType>(
      appDefIdsNotToPersonalize
        .map((appDefId) => APP_ID_TO_CE_TYPE[appDefId])
        .flat(),
    );
    const sectionContents = sections
      .map(({ ceType, volume }) =>
        createSectionContent(
          ceType as CeType,
          volume,
          ceTypesNotToPersonalize,
          sourceTemplateIdsByCeType,
        ),
      )
      .filter(({ contentCategory }) => contentCategory);
    return this.applySitemapRules(
      sectionContents,
      installedAppDefIds,
      ceTypesNotToPersonalize,
      sourceTemplateIdsByCeType,
    );
  }

  private applySitemapRules(
    sections: PageSectionContent[],
    installedAppDefIds: string[],
    ceTypesNotToPersonalize: Set<CeType>,
    sourceTemplateIdsByCeType: Record<string, string>,
  ): PageSectionContent[] {
    let resultSections = [...sections];
    const sortedAppDefIds = sortAppDefIdsByPriority(installedAppDefIds);
    for (const appDefId of sortedAppDefIds) {
      let ceTypeToSectionIndex = getCeTypeToSectionIndex(resultSections);
      const positionCfg = getWidgetPositionCfg(appDefId, this._structureId);
      if (!positionCfg) {
        continue;
      }
      if (positionCfg.appCeType) {
        const sectionIndex = getSectionIndex(
          positionCfg,
          ceTypeToSectionIndex,
          resultSections.length,
        );
        const isBlocked = isSectionBlocked(
          this._structureId,
          positionCfg,
          sortedAppDefIds,
        );
        if (!isBlocked) {
          resultSections = addNewSection(
            appDefId,
            positionCfg.appCeType,
            resultSections,
            sectionIndex,
            ceTypesNotToPersonalize,
            sourceTemplateIdsByCeType,
          );
          ceTypeToSectionIndex = getCeTypeToSectionIndex(resultSections);
        }
        if (positionCfg.replaceContentCategory) {
          resultSections = replaceContentCategory(
            sections,
            positionCfg.appCeType,
            positionCfg.replaceContentCategory,
          );
        }
      }
      resultSections = removeSectionsIfNeeded(
        resultSections,
        positionCfg,
        ceTypeToSectionIndex,
      );
    }
    resultSections = this.filterSectionsByAppDefIds(
      resultSections,
      installedAppDefIds,
    );
    return resultSections;
  }

  getAdditionalPagesData(): PageData[] {
    const pages = this._caas.getPages();

    if (!pages?.length) {
      return [];
    }

    const [, ...additionalPages] = pages;
    return additionalPages.map(({ name, sections }) => ({
      sections: this.getPageSectionsContents(sections, [], []),
      name,
    }));
  }

  getHomepageData(
    appDefIds: string[],
    appDefIdsNotToPersonalize: string[],
  ): PageData {
    const pages = this._caas.getPages();
    if (!pages?.length) {
      throw new Error('No pages found in CAAS provider');
    }
    const [homepage] = pages;
    const sections = this.getPageSectionsContents(
      homepage.sections,
      appDefIds,
      appDefIdsNotToPersonalize,
    );
    return {
      name: homepage.name,
      sections,
    };
  }

  getIndustryProfiles(): IndustryProfileData[] {
    const profileMapping = this._caas.getAllProfileMappings();
    if (!profileMapping?.length) {
      return [];
    }
    return profileMapping.map(({ profileId, description, translationKey }) => ({
      id: profileId,
      description: description || `${profileId} description`,
      translationKey: translationKey || '',
    }));
  }

  private verifyProfileIdExist(id: string) {
    const profileIdExist = this._caas
      .getAllProfileMappings()
      ?.some(({ profileId }) => id === profileId);
    if (!profileIdExist) {
      throw new Error(`Profile id: ${id} does not exist`);
    }
  }

  setProfileId(profileId: string): void {
    this.verifyProfileIdExist(profileId);
    this._profileId = profileId;
    this._caas.setProfileId(profileId);
  }

  getCurrentProfile(): IndustryProfileData | null {
    if (!this._profileId) {
      return null;
    }
    const currentIndustryProfiles = this.getIndustryProfiles().find(
      ({ id }) => id === this._profileId,
    );
    return currentIndustryProfiles || null;
  }

  getLayoutFamilyBlacklist(): string[] {
    const blacklistLayoutFamilies = this._caas.getLayoutFamilyBlacklist();
    if (!blacklistLayoutFamilies?.length) {
      return [];
    }
    return blacklistLayoutFamilies;
  }

  getSuggestedKits(): SuggestedKit[] {
    if (!this._profileId) {
      return [];
    }
    return (
      this._caas
        .getProfileMapping()
        ?.kits?.map(({ id, level }) => ({ id, volume: level as Volume })) || []
    );
  }

  getAppsInstallOptions(appDefIds: string[]): Record<string, AppInstallOption> {
    const templateIdsMap = this.getSourceTemplateIdsMap();
    if (!templateIdsMap) return {};
    const appNamesAndIdsEntries = getAppNamesAndAppDefIdsEntries(appDefIds);
    return appNamesAndIdsEntries.reduce((appsOptions, [appName, appDefId]) => {
      const templateKey = getSourceTemplateIdKey(appName, templateIdsMap);
      if (!templateKey) return appsOptions;

      const sourceTemplateId = templateIdsMap[templateKey];
      if (sourceTemplateId) {
        appsOptions[appDefId] = { sourceTemplateId };
      }
      return appsOptions;
    }, {} as Record<string, AppInstallOption>);
  }
}
