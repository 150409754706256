import { CeType } from '@wix/adi-core-types';
import {
  WIX_BOOKINGS,
  WIX_RESTAURANTS_MENUS_NEW,
  WIX_RESTAURANTS_ORDERS_NEW,
  WIX_RESTAURANTS_TABLE_RESERVATIONS,
} from '@wix/app-definition-ids';

export const ALLOWED_CONTENT_LANGUAGES = new Set([
  'en',
  'pt',
  'es',
  'fr',
  'de',
  'ru',
  'ja',
  'it',
  'nl',
  'ko',
  'sv',
  'pl',
  'tr',
]);

export const DEFAULT_INDUSTRY_ID = 'e79e5765052b89b579c9a4c2';
export const DEFAULT_STRUCTURE_ID = '40dc538a8a6c3e2dae342922';

export const APP_TO_HARD_CODED_SOURCE_TEMPLATE_ID: Record<string, string> = {
  [WIX_BOOKINGS]: '639b8e11-3e76-41ff-b393-36a66ccf889e',
  [WIX_RESTAURANTS_MENUS_NEW]: '4dbb4bca-4de0-4703-ac73-3543886caec4',
  [WIX_RESTAURANTS_ORDERS_NEW]: '6d50b376-c999-4e7c-a13f-ee83ea3fe4f1',
  [WIX_RESTAURANTS_TABLE_RESERVATIONS]: 'a5f5a2f3-f832-41c8-80b7-3319aff2bb68',
};

export const CE_TYPES_TO_ENRICH_MEDIA = [CeType.About, CeType.Welcome];

const {
  Welcome,
  About,
  HomepageAbout,
  Contact,
  Quote,
  Mission,
  Imprint,
  Accessibility,
  Legal,
  Service,
  Project,
  News,
  Product,
  Product_Listing,
  Feature,
  Files,
  Event,
  Facilities,
  Testimonial,
  Testimonial_Short,
  Promotion,
  Infographics,
  OpeningHours,
  TeamMember,
  Informational,
  Client,
  CV,
  Achievements,
  FAQ,
  Careers,
  Link_List,
  Bullet_List,
  InnerAbout,
  SalesLead,
  Support,
  SubmitTestimonials,
  JobApplication,
  Feedback,
  PriceQuote,
  Donate,
  Stores,
  StoresGridGalleryWidget,
  StoresSliderGalleryWidget,
  StoresProductSpecificWidget,
  Instagram_Widget,
  VideoWidget,
  MusicWidget,
  Blog,
  NewBlog,
  BlogPostGallery,
  NewBlogCustomFeed,
  NewBlogRecentPosts,
  EventsSingle,
  EventsMultiple,
  GetSubscribersWidget,
  RestaurantsReservationsWidget,
  RestaurantsOrders,
  RestaurantsMenus,
  Bookings,
  BookingsWidget,
  BookingsTimetable,
  BandsInTownWidget,
  Forum,
  PaidPlans,
  ProGalleryWidget,
  Price,
  ScheduleItem,
  SignUp,
  Groups_SingleGroup,
  Challenges,
  Challenges_SingleChallenge,
  Portfolio_Widget,
  WelcomeLightbox,
  SubscribeLightbox,
  PromotionLightbox,
  PrivacyLightbox,
  LegalLightbox,
  ContactLightbox,
  DeviantArt_Widget,

  Cookie_Policy_Article,
  Cookie_Policy_Basics,
  Cookie_Policy_Disclaimer,
  Hotels_Widget,
  Hotels_Search_Widget,
  Privacy_Policy_Article,
  Privacy_Policy_Basics,
  Privacy_Policy_Disclaimer,
  Privacy_Policy_Included,
  Refund_Policy_Included,
  Refund_Policy_Basics,
  Refund_Policy_Disclaimer,
  Shipping_Policy_Included,
  Shipping_Policy_Basics,
  Shipping_Policy_Disclaimer,
  Terms_And_Conditions_Article,
  Terms_And_Conditions_Included,
  Terms_And_Conditions_Basics,
  Terms_And_Conditions_Disclaimer,
  Text,
  Image,
  Gallery,
  Video,
  List,
  GeneralDescription,
  Membership,
  Portfolio,
  Social,
  SpecialOffer,
  Widget,
  StickySocialBar,
  HTML,
  Podcast,
  Members_Page,
  Page,
  Blank_List,
  Blank_Single,
  Health,
  Registration,
  Welcome_Portfolio,
  Welcome_CV,
} = CeType;

export enum PresetContentCategory {
  Welcome = 'Welcome',
  WelcomeCV = 'WelcomeCV',
  WelcomePortfolio = 'WelcomePortfolio',
  About = 'About',
  InnerAbout = 'InnerAbout',
  Contact = 'Contact',
  Quote = 'Quote',
  OpeningHours = 'OpeningHours',
  OnlyText = 'OnlyText',
  Legal = 'Legal',
  Promotional = 'Promotional',
  General = 'General',
  Features = 'Features',
  Testimonials = 'Testimonials',
  TeamMembers = 'TeamMembers',
  Clients = 'Clients',
  CV = 'CV',
  Achievements = 'Achievements',
  Infographics = 'Infographics',
  OnlyTextList = 'OnlyTextList',
  LinkList = 'LinkList',
  BulletList = 'BulletList',
  SubscribeForm = 'SubscribeForm',
  SalesLeadForm = 'SalesLeadForm',
  SupportForm = 'SupportForm',
  TestimonialForm = 'TestimonialForm',
  JobApplicationForm = 'JobApplicationForm',
  FeedbackForm = 'FeedbackForm',
  PriceQuoteForm = 'PriceQuoteForm',
  DonationForm = 'DonationForm',
  Stores = 'Stores',
  StoresProductGallery = 'StoresProductGallery',
  StoresProductSlider = 'StoresProductSlider',
  StoresFeaturedProduct = 'StoresFeaturedProduct',
  Instagram = 'Instagram',
  VideoPlayer = 'VideoPlayer',
  MusicPlayer = 'MusicPlayer',
  Blog = 'Blog',
  SingleEvent = 'SingleEvent',
  EventList = 'EventList',
  Subscribe = 'Subscribe',
  RestaurantReservation = 'ResturantReservation',
  RestaurantOrders = 'ResturantOrders',
  RestaurantMenus = 'ResturantMenus',
  Timetable = 'Timetable',
  TourDates = 'TourDates',
  Groups = 'Groups',
  Forum = 'Forum',
  PaidPlans = 'PaidPlans',
  OnlinePrograms = 'OnlinePrograms',
  ProGallery = 'ProGallery',
  Portfolio_Widget = 'Portfolio_Widget',
  EventsWelcome = 'EventsWelcome',
  BookingsWidget = 'BookingsWidget',
}

export const unitedCeType: Record<CeType, PresetContentCategory | null> = {
  [Welcome]: PresetContentCategory.Welcome,
  [Welcome_Portfolio]: PresetContentCategory.WelcomePortfolio,
  [Welcome_CV]: PresetContentCategory.WelcomeCV,
  [About]: PresetContentCategory.About,
  [HomepageAbout]: PresetContentCategory.About,
  [Mission]: PresetContentCategory.About,
  [InnerAbout]: PresetContentCategory.InnerAbout,
  [Contact]: PresetContentCategory.Contact,
  [Quote]: PresetContentCategory.Quote,
  [OpeningHours]: PresetContentCategory.OpeningHours,
  [Accessibility]: PresetContentCategory.OnlyText,
  [Facilities]: PresetContentCategory.OnlyText,
  [Informational]: PresetContentCategory.OnlyText,
  [Imprint]: PresetContentCategory.Legal,
  [Legal]: PresetContentCategory.Legal,
  [Promotion]: PresetContentCategory.Promotional,
  [Service]: PresetContentCategory.General,
  [Project]: PresetContentCategory.General,
  [News]: PresetContentCategory.General,
  [Product]: PresetContentCategory.General,
  [Event]: PresetContentCategory.General,
  [Product_Listing]: PresetContentCategory.General,
  [Feature]: PresetContentCategory.Features,
  [Files]: PresetContentCategory.Features,
  [Price]: PresetContentCategory.Features,
  [ScheduleItem]: PresetContentCategory.Features,
  [Testimonial]: PresetContentCategory.Testimonials,
  [Testimonial_Short]: PresetContentCategory.Testimonials,
  [TeamMember]: PresetContentCategory.TeamMembers,
  [Client]: PresetContentCategory.Clients,
  [CV]: PresetContentCategory.CV,
  [Achievements]: PresetContentCategory.Achievements,
  [Infographics]: PresetContentCategory.Infographics,
  [FAQ]: PresetContentCategory.OnlyTextList,
  [Careers]: PresetContentCategory.OnlyTextList,
  [Link_List]: PresetContentCategory.LinkList,
  [Bullet_List]: PresetContentCategory.BulletList,

  [SignUp]: PresetContentCategory.SubscribeForm,
  [SalesLead]: PresetContentCategory.SalesLeadForm,
  [Support]: PresetContentCategory.SupportForm,
  [SubmitTestimonials]: PresetContentCategory.TestimonialForm,
  [JobApplication]: PresetContentCategory.JobApplicationForm,
  [Feedback]: PresetContentCategory.FeedbackForm,
  [PriceQuote]: PresetContentCategory.PriceQuoteForm,
  [Donate]: PresetContentCategory.DonationForm,

  [Stores]: PresetContentCategory.Stores,
  [StoresGridGalleryWidget]: PresetContentCategory.StoresProductGallery,
  [StoresSliderGalleryWidget]: PresetContentCategory.StoresProductSlider,
  [StoresProductSpecificWidget]: PresetContentCategory.StoresFeaturedProduct,
  [Instagram_Widget]: PresetContentCategory.Instagram,
  [VideoWidget]: PresetContentCategory.VideoPlayer,
  [MusicWidget]: PresetContentCategory.MusicPlayer,
  [Blog]: PresetContentCategory.Blog,
  [NewBlog]: PresetContentCategory.Blog,
  [NewBlogCustomFeed]: PresetContentCategory.Blog,
  [NewBlogRecentPosts]: PresetContentCategory.Blog,
  [BlogPostGallery]: PresetContentCategory.Blog,
  [EventsSingle]: PresetContentCategory.SingleEvent,
  [EventsMultiple]: PresetContentCategory.EventList,
  [GetSubscribersWidget]: PresetContentCategory.Subscribe,
  [RestaurantsReservationsWidget]: PresetContentCategory.RestaurantReservation,
  [RestaurantsOrders]: PresetContentCategory.RestaurantOrders,
  [RestaurantsMenus]: PresetContentCategory.RestaurantMenus,
  [Bookings]: PresetContentCategory.BookingsWidget,
  [BookingsWidget]: PresetContentCategory.BookingsWidget,
  [BookingsTimetable]: PresetContentCategory.Timetable,
  [BandsInTownWidget]: PresetContentCategory.TourDates,
  [Groups_SingleGroup]: PresetContentCategory.Groups,
  [Forum]: PresetContentCategory.Forum,
  [PaidPlans]: PresetContentCategory.PaidPlans,
  [Challenges]: PresetContentCategory.OnlinePrograms,
  [Challenges_SingleChallenge]: PresetContentCategory.OnlinePrograms,
  [ProGalleryWidget]: PresetContentCategory.ProGallery,
  [Portfolio_Widget]: PresetContentCategory.Portfolio_Widget,

  [WelcomeLightbox]: null,
  [SubscribeLightbox]: null,
  [PromotionLightbox]: null,
  [PrivacyLightbox]: null,
  [LegalLightbox]: null,
  [ContactLightbox]: null,
  [DeviantArt_Widget]: null,
  [Cookie_Policy_Article]: null,
  [Cookie_Policy_Basics]: null,
  [Cookie_Policy_Disclaimer]: null,
  [Hotels_Widget]: null,
  [Hotels_Search_Widget]: null,
  [Privacy_Policy_Article]: null,
  [Privacy_Policy_Basics]: null,
  [Privacy_Policy_Disclaimer]: null,
  [Privacy_Policy_Included]: null,
  [Refund_Policy_Included]: null,
  [Refund_Policy_Basics]: null,
  [Refund_Policy_Disclaimer]: null,
  [Shipping_Policy_Included]: null,
  [Shipping_Policy_Basics]: null,
  [Shipping_Policy_Disclaimer]: null,
  [Terms_And_Conditions_Article]: null,
  [Terms_And_Conditions_Included]: null,
  [Terms_And_Conditions_Basics]: null,
  [Terms_And_Conditions_Disclaimer]: null,
  [Text]: null,
  [Image]: null,
  [Gallery]: null,
  [Video]: null,
  [List]: null,
  [GeneralDescription]: null,
  [Membership]: null,
  [Portfolio]: null,
  [Social]: null,
  [SpecialOffer]: null,
  [Widget]: null,
  [StickySocialBar]: null,
  [HTML]: null,
  [Podcast]: null,
  [Members_Page]: null,
  [Page]: null,
  [Blank_List]: null,
  [Blank_Single]: null,
  [Health]: null,
  [Registration]: null,
};

export const CE_TYPES_THAT_MUST_HAVE_CSM_DEFINITION_TO_RENDER = new Set<CeType>(
  [
    StoresGridGalleryWidget,
    StoresSliderGalleryWidget,
    StoresProductSpecificWidget,
    BlogPostGallery,
    NewBlogCustomFeed,
    NewBlogRecentPosts,
    Portfolio,
    Portfolio_Widget,
    Blog,
    Bookings,
    BookingsTimetable,
    RestaurantsMenus,
    RestaurantsOrders,
    RestaurantsReservationsWidget,
    Forum,
    Groups_SingleGroup,
  ],
);
