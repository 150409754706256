import { ALLOWED_CONTENT_LANGUAGES } from './caas/caasConsts';

import type {
  CompStructure,
  FieldContentRole,
} from '@wix/document-services-types';

export const getLocale = () => {
  // @ts-ignore TODO fix error TS2339: Property 'editorModel' does not exist on type 'Window & typeof globalThis'.
  const languageCode = window.editorModel.languageCode ?? '';
  return ALLOWED_CONTENT_LANGUAGES.has(languageCode) ? languageCode : 'en';
};

// TODO duplicated!!
const createCompIdToStructureMapInternal = (
  map: CompStructure[],
  compStructure: CompStructure,
): void => {
  map.push(compStructure);
  compStructure.components?.forEach((childStructure) => {
    createCompIdToStructureMapInternal(map, childStructure as CompStructure);
  });
};

export const createFlatStructureMap = (
  rootCompStructure: CompStructure,
): CompStructure[] => {
  // TODO find better name
  const map = [] as CompStructure[];
  createCompIdToStructureMapInternal(map, rootCompStructure);
  return map;
};

export const countNumberOfContentItems = (
  flatStructureMap: CompStructure[],
  onlyLists = false,
): number => {
  const rolesCount = flatStructureMap.reduce((acc, comp) => {
    const fieldRole = (comp.contentRole as FieldContentRole)?.fieldRole;
    const isRepeater = !!comp.data?.overrides;
    const isList =
      (comp.contentRole as FieldContentRole)?.listItemId !== undefined ||
      isRepeater;
    const onlyListsAndIsNotList = onlyLists && !isList;
    const notListsAndIsList = !onlyLists && isList;
    if (onlyListsAndIsNotList || notListsAndIsList) {
      return acc;
    }
    if (isRepeater && onlyLists) {
      acc[fieldRole] = Object.keys(comp.data?.overrides).length;
      return acc;
    }
    if (fieldRole) {
      acc[fieldRole] = (acc[fieldRole] ?? 0) + 1;
    }
    return acc;
  }, {} as Record<string, number>);
  return Object.values(rolesCount).reduce((highest: number, value: number) => {
    if (value > highest) {
      return value;
    }
    return highest;
  }, 0);
};
