//import { isNewWorkspaceEnabled } from '../workspace';
import experiment from 'experiment';

const isWelcomeTourEnabled = () => {
  return false;
};

const isEditorNewUsersTourEnabled = () => {
  return ['B', 'C'].includes(experiment.getValue('se_editorNewUsersTour'));
};

const isNewWorkspaceTourEnabled = () => {
  // Until we qa'ed it
  return false;
  //return isNewWorkspaceEnabled();
};

export {
  isWelcomeTourEnabled,
  isNewWorkspaceTourEnabled,
  isEditorNewUsersTourEnabled,
};
