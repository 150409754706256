import {
  AppToAppDefId,
  AppWidgetType,
  ContentItem,
  ListContentItem,
  Volume,
  widgetToAppMap,
} from '@wix/adi-content-api';
import { CeType } from '@wix/adi-core-types';
import { PresetContentCategory, unitedCeType } from './caasConsts';

import type { AppType } from '@wix/adi-core-types';
import { PageSectionContent } from '../types';

export const getContentCategoryByCeType = (
  ceType: CeType,
): PresetContentCategory => {
  // TODO what is the default content category?
  return unitedCeType[ceType] ?? PresetContentCategory.Welcome;
};

const appDefIdToAppType: Record<string, AppType> = Object.entries(
  AppToAppDefId,
).reduce((acc, [key, value]) => ({ ...acc, [value]: key }), {});

export const getAppTypeByAppDefId = (appDefId: string): AppType => {
  return appDefIdToAppType[appDefId];
};

export const getAppNamesAndAppDefIdsEntries = (
  appDefIds: string[],
): Array<[AppType, string]> => {
  return appDefIds
    .map((appDefId) => [appDefIdToAppType[appDefId] as AppType, appDefId])
    .filter(([appName]) => !!appName) as Array<[AppType, string]>;
};

export const getSourceTemplateIdKey = (
  appName: AppType,
  templateIdsMap: Record<string, string>,
): AppWidgetType | AppType | null => {
  if (templateIdsMap[appName]) return appName;

  const widgetAndAppTypeEntries = Array.from(widgetToAppMap);
  return (
    widgetAndAppTypeEntries.find(
      ([widgetType, _appName]) =>
        _appName === appName && templateIdsMap[widgetType],
    )?.[0] ?? null
  );
};

export const isListResult = (
  item: ContentItem | ListContentItem,
): item is ListContentItem => {
  const itemAsListItem = item as ListContentItem;
  return !!(itemAsListItem.header && itemAsListItem.items);
};

export const isListContentItem = (
  items: ContentItem[] | ListContentItem[],
): items is ListContentItem[] => {
  return (<ListContentItem[]>items)[0].header !== undefined;
};

export const createSectionContent = (
  ceType: CeType,
  volume: Volume,
  ceTypesNotToPersonalize: Set<CeType>,
  sourceTemplateIdsByCeType: Record<string, string>,
): PageSectionContent => {
  return {
    ceType,
    contentCategory: getContentCategoryByCeType(ceType),
    sourceTemplateId: ceTypesNotToPersonalize.has(ceType as CeType)
      ? ''
      : sourceTemplateIdsByCeType?.[ceType as CeType] || '',
    volume,
  };
};
