export default {
  changeValue: {
    evid: 1281,
    fields: {
      inputType: 'string',
      fieldType: 'string',
      field_name: 'string',
      previousValue: 'boolean',
      value: 'boolean',
    },
  },
  buttonClick: {
    evid: 1247,
    fields: {
      inputType: 'string',
      button_name: 'string',
      field_name: 'string',
    },
  },
};
