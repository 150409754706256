import _ from 'lodash';
import Color from 'color';
import * as biEvents from './bi/biEvents';
import * as utils from '@wix/santa-editor-utils';
import serviceTopology from '../serviceTopology/serviceTopology';
import * as backgroundMediaUtils from '@wix/background-media-utils';
import constants, {
  type BackgroundData,
  type MediaTypes,
  type VideoData,
} from '@/constants';
import { generate as generateGradient } from '@wix/css-gradient-generator';

import type { EditorAPI } from '@/editorAPI';
import type { ColorId } from '@wix/document-services-types';
import type {
  GradientLinear,
  GradientCircle,
  GradientEllipse,
  GradientConic,
  GradientMesh,
} from '@wix/css-gradient-generator';
import type { ColorLayerData, ColorOrGradient } from './types';

const mediaTypes = constants.MEDIA_TYPES;

const {
  getVideoBackgroundObject,
  getImageBackgroundObject,
  getMediaPlayerVideoObject,
  getVideoPosterObject,
  getImageBackgroundObjectFromMediaStudioFile,
} = backgroundMediaUtils;

/**
 * @param width
 * @param height
 * @param containerWidth
 * @param containerHeight
 * @returns {boolean} true if smaller
 */
function isSmallerFromContainer(
  width: number,
  height: number,
  containerWidth: number,
  containerHeight: number,
) {
  return width < containerWidth && height < containerHeight;
}

/**
 * @param fileInfo
 * @param origin
 * @param props
 * @returns {{video_id: string, video_width: string, video_height: string, video_format: *, video_fps: *, video_aspect_ratio: (string), video_quality: *, video_has_audio: number, origin: *}}
 */
function getChangeVideoBiEventFields(
  fileInfo: AnyFixMe,
  origin: string,
  props: AnyFixMe = {},
) {
  const qualities = _.sortBy(fileInfo.fileOutput.video, function (quality) {
    return parseInt(quality.quality, 10);
  });
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/last
  const maxVideoOutput = _.last(qualities);
  return {
    video_id: fileInfo.fileBaseUrl,
    video_width: fileInfo.fileInput.width,
    video_height: fileInfo.fileInput.height,
    video_format: maxVideoOutput.format,
    video_fps: maxVideoOutput.fps,
    video_aspect_ratio: maxVideoOutput.displayAspectRatio,
    video_quality: maxVideoOutput.quality,
    video_has_audio: maxVideoOutput.audioBitrate === -1 ? 0 : 1,
    video_has_alpha:
      fileInfo.tags && fileInfo.tags.includes('_mp4_alpha') ? 1 : 0,
    video_has_mask: props.hasMask ? 1 : 0,
    origin,
  };
}

/**
 * @param data
 * @returns {MediaTypes}
 */
function getMediaType(background: BackgroundData): MediaTypes {
  return background?.mediaRef?.type ?? mediaTypes.COLOR;
}

/**
 * removes overlayData from BackgroundMedia data
 * MUTATES DATA!
 * @param data
 */
function clearOverlayData(data: AnyFixMe) {
  //todo: should be modified if we want to 'support' overlay for Image type and mobile view
  data.ref.colorOverlay = '';
  data.ref.colorOverlayOpacity = 0;
  data.ref.imageOverlay = null;
}

/**
 * return showStoryboard value , defaults to 'time'
 */
function getStoryboardDisplayValue(
  videoData: VideoData,
  currentValue: 'videoAndTime' | 'time' | 'none',
) {
  if (currentValue && currentValue !== 'videoAndTime') {
    return currentValue;
  }

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find
  const storyboardItem = _.find(videoData.qualities, { quality: 'storyboard' });
  if (storyboardItem && currentValue) {
    return currentValue;
  }
  return 'time';
}

/**
 * @param editorAPI editorAPI
 * @param color , theme color or css color value
 * @returns string , css color value.
 */
function getColorValue(editorAPI: EditorAPI, color: string | ColorId = '') {
  return color.match('color')
    ? editorAPI.dsRead.theme.colors.get(color.replace(/[{}]/g, '') as ColorId)
    : color;
}

function getOverlayStyle(data: BackgroundData, editorAPI: EditorAPI) {
  const color = getColorValue(editorAPI, data.colorOverlay);
  return {
    backgroundImage: data.imageOverlay
      ? `url(${utils.url.joinURL(
          serviceTopology.staticMediaUrl,
          data.imageOverlay.uri,
        )})`
      : null,
    backgroundColor: color
      ? new Color(color).alpha(data.colorOverlayOpacity || 0).toString()
      : null,
  };
}

function generateColorLayer(
  fill: ColorLayerData['fill'],
  opacity: number = 1,
): ColorLayerData {
  const type = `${fill.type}Layer` as ColorLayerData['type'];
  return {
    type,
    opacity,
    fill,
  };
}

function getColorOrGradient(
  data: BackgroundData,
):
  | string
  | GradientLinear
  | GradientCircle
  | GradientEllipse
  | GradientConic
  | GradientMesh {
  const fill =
    data?.colorLayers?.[0]?.fill || data?.color?.replace(/[{}]/g, '');
  return fill?.type === 'SolidColor' ? fill.color : fill;
}

function getResolvedColor(
  color: ColorOrGradient,
  colorResolver: (color: string) => string,
) {
  if (typeof color === 'string') {
    return colorResolver(color);
  } else if (color?.type) {
    return generateGradient(color, {
      resolveColor: colorResolver,
    });
  }
  return color;
}

function getFirstColorStopColor(
  value: ColorOrGradient,
  colorResolver: (color: string) => string,
) {
  let color;
  if (typeof value === 'string') {
    color = value;
  } else if (value?.type === 'GradientMesh') {
    color = value.gradients?.[0].colorStops[0].color;
  } else {
    color = value?.colorStops?.[0]?.color;
  }
  return getResolvedColor(color, colorResolver);
}

function getOpacityValue(data: BackgroundData): number {
  return data.colorLayers?.[0]?.opacity ?? data.colorOpacity ?? 1;
}

function getNewColorLayers(
  data: BackgroundData,
  value:
    | string
    | GradientLinear
    | GradientCircle
    | GradientEllipse
    | GradientConic
    | GradientMesh
    | GradientMesh['gradients'] = getColorOrGradient(data),
  opacity?: number,
) {
  let colorLayers;
  opacity = opacity ?? getOpacityValue(data);

  if (typeof value === 'string') {
    colorLayers = [
      generateColorLayer(
        { type: 'SolidColor', color: value ?? '#FFFFFF' },
        opacity,
      ),
    ];
  } else if (
    (
      value as
        | GradientLinear
        | GradientCircle
        | GradientEllipse
        | GradientConic
        | GradientMesh
    )?.type
  ) {
    colorLayers = [
      generateColorLayer(
        value as
          | GradientLinear
          | GradientCircle
          | GradientEllipse
          | GradientConic
          | GradientMesh,
        opacity,
      ),
    ];
  } else if (Array.isArray(value)) {
    colorLayers = [
      generateColorLayer(
        {
          type: 'GradientMesh',
          gradients: value as GradientMesh['gradients'],
        },
        opacity,
      ),
    ];
  } else {
    // eslint-disable-next-line no-console
    console.error(value, 'is not a type we can set to a colorLayer');
  }
  return colorLayers;
}

const removeIdsAndMetaData = (backgroundData: BackgroundData) => {
  const { id: id0, ...background } = backgroundData;
  if (backgroundData.imageOverlay) {
    const { id, metaData, ...imageOverlay } = backgroundData.imageOverlay;
    background.imageOverlay = imageOverlay;
  }
  if (backgroundData.mediaRef) {
    const {
      id: id1,
      metaData: metaData1,
      ...mediaRef
    } = backgroundData.mediaRef;
    if (mediaRef.type === 'WixVideo') {
      const {
        id: id2,
        metaData: metaData2,
        ...posterImageRef
      } = (backgroundData.mediaRef as VideoData).posterImageRef;
      (mediaRef as VideoData).posterImageRef = posterImageRef;
    }
    background.mediaRef = mediaRef;
  }
  return background;
};

export {
  biEvents,
  isSmallerFromContainer,
  getChangeVideoBiEventFields,
  getMediaType,
  clearOverlayData,
  getStoryboardDisplayValue,
  getOverlayStyle,
  getColorValue,
  getVideoBackgroundObject,
  getImageBackgroundObject,
  getImageBackgroundObjectFromMediaStudioFile,
  getMediaPlayerVideoObject,
  getVideoPosterObject,
  // New gradients stuff
  getColorOrGradient,
  getNewColorLayers,
  getOpacityValue,
  generateColorLayer,
  getResolvedColor,
  getFirstColorStopColor,
  removeIdsAndMetaData,
};
