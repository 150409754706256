export default {
  videoQuotaBannerShow: {
    evid: 1070,
    src: 38,
    fields: {
      component_id: 'string',
      component_type: 'string',
      panel_name: 'string',
      video_time_quota: 'number',
      video_time_remaining: 'number',
      is_premium: 'bool',
      is_ascend: 'bool',
    },
  },
  videoQuotaTooltipOpen: {
    evid: 1069,
    src: 38,
    fields: {
      component_id: 'string',
      component_type: 'string',
      panel_name: 'string',
      control_name: 'string',
    },
  },
  videoQuotaLearnMoreClick: {
    evid: 254,
    src: 38,
    fields: {
      component_id: 'string',
      component: 'string',
      origin: 'string',
      panel_name: 'string',
      control_name: 'string',
      learn_more: 'bool',
    },
  },
  videoQuotaUpgradeClick: {
    evid: 519,
    src: 38,
    fields: {
      component_id: 'string',
      panel_name: 'string',
      origin: 'string',
    },
  },
  fileTypeDropdownClick: {
    evid: 1169,
    src: 38,
    fields: {},
  },
  fileTypeDropdownSelect: {
    evid: 1170,
    src: 38,
    fields: {
      option: 'string',
    },
  },
  fileLimitChange: {
    evid: 1171,
    src: 38,
    fields: {
      value: 'string',
    },
  },
  fileLimitFeatureRequestClick: {
    evid: 1172,
    src: 38,
    fields: {},
  },
} as const;
