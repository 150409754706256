// @ts-ignore
import styles from "./ui.css";
import type { ApiPathDebugInfoUI } from "./type";

function createDOMFromHTML(html: string): HTMLElement {
  const temp = document.createElement("div");
  temp.innerHTML = html;

  return temp.firstElementChild as HTMLElement;
}

function createUIList({
  onTogglePinned,
}: {
  onTogglePinned: (pathStr: string) => void;
}) {
  const list = createDOMFromHTML(`
    <ul class="${styles.list}">
      <li class="${styles.listItem}">
        <code><span data-path></span> <span data-path-count></span></code> <input data-path-pinned type="checkbox"/>
      </li>
    </ul>
  `);
  const listItems = new Map<string, HTMLElement>();
  const listItemTemplate = list.firstElementChild as HTMLElement;

  list.removeChild(listItemTemplate);

  function getOrCreateItem(pathStr: string): HTMLElement {
    if (listItems.has(pathStr)) {
      return listItems.get(pathStr) as HTMLElement;
    }

    const listItem = listItemTemplate.cloneNode(true) as HTMLElement;

    listItem.querySelector("[data-path]").textContent = pathStr;
    listItem
      .querySelector("input[data-path-pinned]")
      .addEventListener("change", () => onTogglePinned(pathStr));

    list.appendChild(listItem);
    listItems.set(pathStr, listItem);

    return listItem;
  }

  function setItem(
    pathStr: string,
    debugInfo: ApiPathDebugInfoUI,
    debugInfoPrev?: ApiPathDebugInfoUI
  ) {
    const item = getOrCreateItem(pathStr);

    if (debugInfo.callsCount !== debugInfoPrev?.callsCount) {
      const { callsCount, callsTotalDuration } = debugInfo;
      const callsAverageDuration =
        Math.round((callsTotalDuration / callsCount) * 1000) / 1000;

      item.querySelector(
        "[data-path-count]"
      ).textContent = `${callsCount} (${callsAverageDuration}ms)`;
    }

    if (debugInfo.order !== debugInfoPrev?.order) {
      item.style.order = debugInfo.order.toString();
    }

    if (debugInfo.isPinned !== debugInfoPrev?.isPinned) {
      (
        item.querySelector("input[data-path-pinned]") as HTMLInputElement
      ).checked = debugInfo.isPinned;
    }
  }

  function removeItem(pathStr: string) {
    listItems.get(pathStr)?.remove();
    listItems.delete(pathStr);
  }

  const debugInfoPrevMap = new Map<string, ApiPathDebugInfoUI>();
  function update(debugInfoMap: Map<string, ApiPathDebugInfoUI>) {
    debugInfoPrevMap.forEach((_item, pathStr) => {
      if (!debugInfoMap.has(pathStr)) {
        removeItem(pathStr);
        debugInfoPrevMap.delete(pathStr);
      }
    });

    debugInfoMap.forEach((debugInfo, pathStr) => {
      setItem(pathStr, debugInfo, debugInfoPrevMap.get(pathStr));
      debugInfoPrevMap.set(pathStr, debugInfo);
    });
  }

  return {
    element: list,
    update,
  } as const;
}

export function createUI({
  root,
  onTogglePinned,
}: {
  root: HTMLElement;
  onTogglePinned: (pathStr: string) => void;
}) {
  const uiList = createUIList({ onTogglePinned });

  root.classList.add(styles.container);
  root.appendChild(uiList.element);
  const destroy = () => {
    root.removeChild(uiList.element);
  };

  return {
    update: uiList.update,
    destroy,
  } as const;
}
