export const COMP_TYPES = {
  ACCORDION_CONTAINER: 'wixui.AccordionContainer',
  ACCORDION_ITEM: 'wixui.AccordionItem',
  ADDRESS_INPUT: 'wixui.AddressInput',
  ANCHOR: 'wysiwyg.common.components.anchor.viewer.Anchor',
  ANCHORS_MENU:
    'wysiwyg.common.components.verticalanchorsmenu.viewer.VerticalAnchorsMenu',
  APP_CONTROLLER: 'platform.components.AppController',
  APP_WIDGET: 'platform.components.AppWidget',
  BOX_SLIDE_SHOW: 'wysiwyg.viewer.components.BoxSlideShow',
  BOX_SLIDE_SHOW_SLIDE: 'wysiwyg.viewer.components.BoxSlideShowSlide',
  CHECK_BOX: 'wysiwyg.viewer.components.inputs.Checkbox',
  COLLAGE: 'tpa.viewer.components.Collage',
  COLLAPSIBLE_TEXT: 'wixui.CollapsibleText',
  COLUMN: 'wysiwyg.viewer.components.Column',
  COMBO_BOX_INPUT: 'wysiwyg.viewer.components.inputs.ComboBoxInput',
  CONTAINER: 'mobile.core.components.Container',
  DOCUMENT_MEDIA: 'wysiwyg.viewer.components.documentmedia.DocumentMedia',
  DROPDOWN_MENU: 'wysiwyg.viewer.components.menus.DropDownMenu',
  EXPANDABLE_MENU: 'wysiwyg.viewer.components.ExpandableMenu',
  FACEBOOK_COMMENTS: 'wysiwyg.viewer.components.WFacebookComment',
  FACEBOOK_LIKE: 'wysiwyg.viewer.components.WFacebookLike',
  FACEBOOK_LIKE_BOX:
    'wysiwyg.common.components.facebooklikebox.viewer.FacebookLikeBox',
  FIVE_GRID_LINE: 'wysiwyg.viewer.components.FiveGridLine',
  FOOTER: 'wysiwyg.viewer.components.FooterContainer',
  FORM_CONTAINER: 'wysiwyg.viewer.components.FormContainer',
  FORM_TEXT_AREA_INPUT: 'wysiwyg.viewer.components.inputs.TextAreaInput',
  FORM_TEXT_INPUT: 'wysiwyg.viewer.components.inputs.TextInput',
  FREESTYLE_GALLERY: 'tpa.viewer.components.Freestyle',
  GOOGLE_MAP: 'wysiwyg.viewer.components.GoogleMap',
  GRID: 'wysiwyg.viewer.components.Grid',
  GROUP: 'wysiwyg.viewer.components.Group',
  HEADER: 'wysiwyg.viewer.components.HeaderContainer',
  HONEYCOMB_GALLERY: 'tpa.viewer.components.Honeycomb',
  HOVER_BOX: 'wysiwyg.viewer.components.HoverBox',
  HTML_COMPONENT: 'wysiwyg.viewer.components.HtmlComponent',
  ICON_BUTTON: 'wysiwyg.common.components.imagebutton.viewer.ImageButton',
  IMPRESS_GALLERY: 'tpa.viewer.components.Impress',
  LANGUAGE_SELECTOR: 'wysiwyg.viewer.components.LanguageSelector',
  LINE_SHARE_BUTTON: 'wixui.LineShareButton',
  LOGIN_SOCIAL_BAR: 'wysiwyg.viewer.components.LoginSocialBar',
  LOGO: 'wixui.Logo',
  MASONRY: 'tpa.viewer.components.Masonry',
  MATRIX_GALLERY: 'wysiwyg.viewer.components.MatrixGallery',
  MEDIA_CONTAINER: 'wysiwyg.viewer.components.MediaContainer',
  MEDIA_PLAYER: 'wysiwyg.viewer.components.MediaPlayer',
  MENU_CONTAINER: 'wysiwyg.viewer.components.MenuContainer',
  MOBILE_BACK_TO_TOP_BUTTON: 'wysiwyg.viewer.components.BackToTopButton',
  MULTI_STATE_BOX: 'wixui.MultiStateBox',
  PAGE: 'mobile.core.components.Page',
  PAGINATED_GRID_GALLERY: 'wysiwyg.viewer.components.PaginatedGridGallery',
  PHOTO: 'wysiwyg.viewer.components.WPhoto',
  PINTERESET_PIN_IT:
    'wysiwyg.common.components.pinterestpinit.viewer.PinterestPinIt',
  PINTERESET_WIDGET:
    'wysiwyg.common.components.pinitpinwidget.viewer.PinItPinWidget',
  POPUP_CLOSE_ICON_BUTTON: 'wysiwyg.viewer.components.PopupCloseIconButton',
  POPUP_CONTAINER: 'wysiwyg.viewer.components.PopupContainer',
  PRO_GALLERY: 'wixui.ProGallery',
  QUICK_ACTION_BAR: 'wysiwyg.viewer.components.QuickActionBar',
  REF_COMPONENT: 'wysiwyg.viewer.components.RefComponent',
  REPEATER: 'wysiwyg.viewer.components.Repeater',
  SEARCH_BOX: 'wixui.SearchBox',
  SECTION: 'wysiwyg.viewer.components.ClassicSection',
  SELECTABLE_CONTAINER: 'wixui.SelectableContainer',
  SHAPE: 'wysiwyg.viewer.components.VectorImage',
  SINGLE_TAB: 'wixui.SingleTab',
  SITE_BUTTON: 'wysiwyg.viewer.components.SiteButton',
  SITE_REGION_CONTAINER: 'wysiwyg.viewer.components.SiteRegionContainer',
  SLIDE_SHOW: 'wixui.Slideshow',
  SLIDE_SHOW_GALLERY: 'wysiwyg.viewer.components.SlideShowGallery',
  SLIDER_GALLERY: 'wysiwyg.viewer.components.SliderGallery',
  SOCIAL_LINK_BAR: 'wysiwyg.viewer.components.LinkBar',
  STATE_BOX: 'wysiwyg.viewer.components.StateBox',
  STATE_BOX_FORM_STATE: 'wysiwyg.viewer.components.StateBoxFormState',
  STATE_BOX_STATE: 'wysiwyg.viewer.components.StateBoxState',
  STRIP_COLUMNS_CONTAINER: 'wysiwyg.viewer.components.StripColumnsContainer',
  STRIP_CONTAINER_SLIDE_SHOW:
    'wysiwyg.viewer.components.StripContainerSlideShow',
  STRIP_CONTAINER_SLIDE_SHOW_SLIDE:
    'wysiwyg.viewer.components.StripContainerSlideShowSlide',
  STRIP_SHOW_CASE: 'tpa.viewer.components.StripShowcase',
  STRIP_SLIDE_SHOW: 'tpa.viewer.components.StripSlideshow',
  STYLABLE_BUTTON: 'wixui.StylableButton',
  STYLABLE_HORIZONTAL_MENU: 'wixui.StylableHorizontalMenu',
  TABS: 'wixui.Tabs',
  TEXT: 'wysiwyg.viewer.components.WRichText',
  TPA_MULTI_SECTION: 'wysiwyg.viewer.components.tpapps.TPAMultiSection',
  TPA_SECTION: 'wysiwyg.viewer.components.tpapps.TPASection',
  TPA_WIDGET: 'wysiwyg.viewer.components.tpapps.TPAWidget',
  TPA3D_CAROUSEL: 'wysiwyg.viewer.components.tpapps.TPA3DCarousel',
  TPA3D_GALLERY: 'wysiwyg.viewer.components.tpapps.TPA3DGallery',
  TWITTER_FOLLOW: 'wysiwyg.viewer.components.WTwitterFollow',
  TWITTER_TWEET: 'wysiwyg.viewer.components.WTwitterTweet',
  VERTICAL_LINE: 'wysiwyg.viewer.components.VerticalLine',
  VERTICAL_MENU: 'wysiwyg.common.components.verticalmenu.viewer.VerticalMenu',
  VIDEO: 'wysiwyg.viewer.components.Video',
  VIDEO_MEDIA_CONTROL: 'wysiwyg.viewer.components.MediaControls',
  VIDEO_PLAY_BUTTON: 'wysiwyg.viewer.components.MediaOverlayControls',
  VIDEO_PLAYER: 'wixui.VideoPlayer',
  GLUED_CHAT: 'wysiwyg.viewer.components.tpapps.TPAGluedWidget',
};
