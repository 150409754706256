/**
 * Hey, editor developer!
 * If you need to add something, import it from separate file.
 * It would be cool  to move one small old part to file every time you add or change something :)
 */
/*eslint max-lines: [2, { "max":4500, "skipComments": true, "skipBlankLines": true}]*/
/*this file is huge, please don't make it even bigger, try to decompose it*/
import { switchLayout } from './events/switchLayout';
import addPanel from './events/addPanel';
import concurrentUsers from './events/concurrentUsers';
import AppBuilder from './events/appBuilder';
import fileUploader from './events/fileUploader';
import datePicker from './events/datePicker';
import multilingual, { googleTranslate } from './events/multilingual';
import { editor } from './events/editor';
import publish from './events/publish';
import topbar from './events/topBar';
import leftBar from './events/leftBar';
import panels from './events/panels';
import pages from './events/pages';
import sections from './events/sections';
import anchors from './events/anchors';
import helpHome from './events/helpHome';
import popups from './events/popups';
import rightClickMenu from './events/rightClickMenu';
import commonControls from './events/commonControls';
import siteSettingsBranchWarning from './events/siteSettingsBranchWarning';
import sectionsMigration from './events/sectionsMigration';
import siteCreation from './events/siteCreation';
import linkPanel from './events/linkPanel';
import designPanel from './events/designPanel';
import backgroundPanel from './events/backgroundPanel';
import loginSocialBar from './events/loginSocialBar';
import toolbar from './events/toolbar';
import changeBusinessType from './events/changeBusinessType';
import widgetPresetsPanel from './events/widgetPresetsPanel';
import logoPanels from './events/logoPanels';

export default {
  editor,
  switchLayout,
  leftBar,
  topbar,
  customErrorPage: {
    custom_error_page_panel: {
      evid: 947,
      fields: {
        action: 'string',
        flow: 'string',
      },
    },
  },
  pages,
  sections,
  popups,
  loginSocialBar,
  dynamicPageComboBox: {
    preview_clicked: {
      endpoint: 'platform',
      src: 79,
      evid: 87,
      fields: {
        item_url: 'string',
        item_name: 'string',
        pageId: 'string',
        appName: 'string',
      },
    },
    preview_select_page: {
      endpoint: 'platform',
      src: 79,
      evid: 88,
      fields: {
        item_url: 'string',
        item_name: 'string',
        pageId: 'string',
        appName: 'string',
      },
    },
    preview_navigate: {
      endpoint: 'platform',
      src: 79,
      evid: 89,
      fields: {
        item_url: 'string',
        item_name: 'string',
        pageId: 'string',
        appName: 'string',
      },
    },
    preview_closed: {
      endpoint: 'platform',
      src: 79,
      evid: 90,
      fields: {
        pageId: 'string',
        appName: 'string',
      },
    },
  },
  multiSelectLayoutPanel: {
    MULTISELECT_LAYOUT_PANEL_ALIGNMENT_CLICKED: {
      evid: 493,
      fields: {
        category: 'string',
      },
    },
    MULTISELECT_LAYOUT_PANEL_DISTRIBUTION_CLICKED: {
      evid: 495,
      fields: {
        category: 'string',
      },
    },
    MULTISELECT_LAYOUT_PANEL_MATCH_SIZE_CLICKED: {
      evid: 496,
      fields: {
        category: 'string',
      },
    },
  },
  mobileBackToTopButton: {
    back_to_top_button_icon_changed: {
      evid: 1154,
      fields: {
        editor_view_mode: 'string',
        iconId: 'numeric',
        icon_name: 'string',
      },
    },
    back_to_top_button_customize: {
      evid: 1155,
      fields: {
        editor_view_mode: 'string',
      },
    },
    back_to_top_button_layout_changed: {
      evid: 1156,
      fields: {
        editor_view_mode: 'string',
        layoutName: 'string',
      },
    },
  },
  mobileWelcomeScreenPanel: {
    CLOSE_WELCOME_SCREEN_PANEL: {
      evid: 884,
    },
    CANCEL_WELCOME_SCREEN_PANEL: {
      evid: 885,
    },
    DONE_WELCOME_SCREEN_PANEL: {
      evid: 886,
    },
    REMOVE_WELCOME_SCREEN_MOBILE_TOOLS: {
      evid: 887,
      fields: {
        mobile_tool: 'string',
      },
    },
    EDIT_WELCOME_SCREEN_MOBILE_TOOLS: {
      evid: 888,
      fields: {
        mobile_tool: 'string',
      },
    },
    WELCOME_SCREEN_ADD_LOGO: {
      evid: 1157,
      fields: {
        editor_working_mode: 'string',
        button_name: 'string',
      },
    },
    WELCOME_SCREEN_ANIMATION_DIRECTION_CHANGED: {
      evid: 1158,
      fields: {
        editor_working_mode: 'string',
        new_direction: 'string',
      },
    },
    WELCOME_SCREEN_BACKGROUND_DIRECTION_CHANGED: {
      evid: 1159,
      fields: {
        editor_working_mode: 'string',
        new_direction: 'string',
      },
    },
    WELCOME_SCREEN_BACKGROUND_ANIMATION_CHANGED: {
      evid: 1160,
      fields: {
        editor_working_mode: 'string',
      },
    },
    WELCOME_SCREEN_PLAY_ANIMATION_CLICKED: {
      evid: 1161,
      fields: {
        editor_working_mode: 'string',
      },
    },
  },
  desktopBrowserActions: {
    CLICK_TAB_CLOSE: {
      evid: 1138,
      fields: {},
    },
    MOVE_CURSOR_OUT_OF_VIEWPORT: {
      evid: 1139,
      fields: {},
    },
    WARN_BEFORE_LEAVE_POPS_UP: {
      evid: 1142,
      fields: {},
    },
  },
  mobileBrowserThemeColorPanel: {
    DONE_BROWSER_THEME_COLOR_PANEL: {
      evid: 891,
      fields: {
        close_method: 'string',
        color: 'string',
      },
    },
    CLOSE_BROWSER_THEME_COLOR_PANEL: {
      evid: 891,
      fields: {
        close_method: 'string',
      },
    },
    REMOVE_BROWSER_THEME_COLOR_MOBILE_TOOLS: {
      evid: 887,
      fields: {
        mobile_tool: 'string',
      },
    },
    EDIT_BROWSER_THEME_COLOR_MOBILE_TOOLS: {
      evid: 888,
      fields: {
        mobile_tool: 'string',
      },
    },
    choose_color_clicked: {
      evid: 1162,
      fields: {
        editor_view_mode: 'string',
      },
    },
  },
  actions: {
    CLICK: {
      evid: 100,
      fields: {
        action_name: 'string',
        component_id: 'string',
        component_type: 'string',
        icon: 'string',
        app_Id: 'string',
        origin: 'string',
      },
    },
  },
  gfpp: {
    GFPP_EXTRA_BUTTONS_SHOWN: {
      evid: 129,
      fields: {
        component_id: 'string',
        component_type: 'string',
      },
    },
  },
  textControls: {
    Text_Panel_opened: {
      evid: 224,
      fields: {
        origin: 'string',
      },
      sampleRatio: 10,
    },
    Text_Panel_Text_Style_Click: {
      evid: 225,
      fields: {
        component_id: 'string',
      },
    },
    Text_Panel_Text_Style_Selected: {
      evid: 226,
      fields: {
        component_id: 'string',
        category: 'string',
      },
    },
    Text_Panel_Font_Selector_Click: {
      evid: 227,
      fields: {
        component_id: 'string',
      },
    },
    Text_Panel_Font_Selector_Selected: {
      evid: 228,
      fields: {
        component_id: 'string',
        category: 'string',
      },
    },
    Text_Panel_Font_Language_Link_Click: {
      evid: 229,
      fields: {
        panel_name: 'string',
        component_id: 'string',
      },
    },
    Text_Panel_Bold_Click: {
      evid: 231,
      fields: {
        component_id: 'string',
      },
    },
    Text_Panel_Italic_Click: {
      evid: 232,
      fields: {
        component_id: 'string',
      },
    },
    Text_Panel_Alignment_Click: {
      evid: 233,
      fields: {
        component_id: 'string',
        target: 'string',
      },
    },
    Text_Panel_Bullets_Click: {
      evid: 235,
      fields: {
        component_id: 'string',
        target: 'string',
      },
    },
    Text_Panel_Underline_Click: {
      evid: 236,
      fields: {
        component_id: 'string',
      },
    },
    Text_Panel_Indentation_Click: {
      evid: 238,
      fields: {
        panel_name: 'string',
        category: 'string',
        component_id: 'string',
      },
    },
    Text_Panel_Text_Orientation_Click: {
      evid: 239,
      fields: {
        panel_name: 'string',
        category: 'string',
        component_id: 'string',
      },
    },
    Text_Panel_Link_Click: {
      evid: 240,
      fields: {
        component_id: 'string',
        target: 'string',
      },
    },
    Text_Panel_Color_Click: {
      evid: 241,
      fields: {
        panel_name: 'string',
        component_id: 'string',
      },
    },
    Text_Panel_Highlight_Click: {
      evid: 242,
      fields: {
        panel_name: 'string',
        component_id: 'string',
        target: 'string',
      },
    },
    Text_Panel_Line_Height_Radio_Buttons: {
      evid: 247,
      fields: {
        component_id: 'string',
      },
    },
    Text_Panel_Reset_to_theme_Click: {
      evid: 249,
      fields: {
        panel_name: 'string',
      },
    },
    Text_panel_save_theme_Click: {
      evid: 268,
      fields: {},
    },
    text_save_theme_dialog_save_click: {
      evid: 269,
      fields: {},
    },
    text_languages_support_dialog_save_click: {
      evid: 270,
      fields: {},
    },
    text_languages_support_learn_more_click: {
      evid: 271,
      fields: {},
    },
    Text_Panel_Font_Upload_Link_Click: {
      evid: 593,
      fields: {},
    },
    Text_Panel_VerticalText_toggle: {
      evid: 1007,
      fields: {
        toggle: 'boolean',
      },
    },
  },
  fontsUploadPanel: {
    Upload_Fonts_Click: {
      evid: 594,
      fields: {
        count: 'numeric',
      },
    },
    Fonts_Dragged: {
      evid: 595,
      fields: {
        count: 'numeric',
      },
    },
    Closed_Button_Click: {
      evid: 596,
      fields: {},
    },
    Fonts_Uploaded: {
      evid: 597,
      fields: {
        count: 'numeric',
        name: 'string',
      },
    },
    Download_File_Click: {
      evid: 598,
      fields: {},
    },
    Delete_File: {
      evid: 599,
      fields: {
        text: 'string',
      },
    },
    Approve_Delete_File: {
      evid: 600,
      fields: {
        text: 'string',
      },
    },
    Cancel_Upload: {
      evid: 602,
      fields: {
        count: 'number',
        name: 'string',
      },
    },
    Upload_Failed: {
      evid: 603,
      fields: {
        message: 'string',
        name: 'string',
      },
    },
    Approve_Stop_Upload: {
      evid: 611,
      fields: {
        text: 'string',
      },
    },
    Upload_Fonts_Try_Upload: {
      evid: 665,
      fields: {
        name: 'string',
      },
    },
    Start_Font_Upload_Process: {
      evid: 682,
      fields: {
        name: 'string',
      },
    },
  },
  addPanel,
  videoTooltip: {
    OPEN: {
      evid: 671,
      src: 38,
      fields: {
        tooltipname: 'string',
      },
      sampleRatio: 10,
    },
    CLOSE: {
      evid: 669,
      src: 38,
      fields: {
        tooltipname: 'string',
      },
    },
    HOVER: {
      evid: 672,
      src: 38,
      fields: {
        tooltipname: 'string',
        duration: 'numeric',
      },
      sampleRatio: 10,
    },
    THUMBNAIL_CLICK: {
      evid: 670,
      src: 38,
      fields: {
        tooltipname: 'string',
        videoID: 'string',
      },
    },
  },
  backToADIFromEditor: {
    goingBackToWixADITooltipOpened: {
      evid: 786,
      src: 38,
      fields: {
        esi: 'guid',
        site_id: 'guid',
      },
    },
    backToWixADIPanelClicked: {
      evid: 787,
      src: 38,
      fields: {
        category: 'string',
        esi: 'guid',
        site_id: 'guid',
      },
    },
  },
  singleVideoPlayer: {
    video_player_play_preview_click: {
      evid: 676,
      src: 38,
      fields: {},
    },
    video_player_reset_video_proportions_click: {
      evid: 678,
      src: 38,
      fields: {
        description: 'string',
      },
    },
    video_player_settings_on_panel_closed: {
      evid: 680,
      src: 38,
      fields: {
        autoplay_status: 'boolean',
        loop_status: 'boolean',
        mute_on_load_status: 'boolean',
        cover_image_id: 'string',
        cover_image_index: 'number',
        cover_image_custom: 'boolean',
      },
    },
    video_player_big_play_design_on_panel_closed: {
      evid: 681,
      src: 38,
      fields: {
        big_play_fill_color: 'boolean',
        big_play_fill_color_opacity: 'boolean',
        big_play_stroke_color: 'boolean',
        big_play_stroke_color_opacity: 'string',
        big_play_stroke_width: 'number',
      },
    },
    video_player_big_play_change_on_panel_closed: {
      evid: 688,
      src: 38,
      fields: {
        big_play_symbol_id: 'string',
        big_play_symbol_index: 'string',
      },
    },
    videobox_mask: {
      evid: 978,
      src: 38,
      fields: {
        mask_index: 'number',
        mask_media_name: 'string',
        component_id: 'string',
      },
    },

    video_player_video_changed: {
      evid: 687,
      src: 38,
      fields: {
        video_id: 'string',
        video_format: 'string',
        video_fps: 'string',
        video_aspect_ratio: 'string',
        video_quality: 'string',
        video_width: 'number',
        video_height: 'number',
        video_has_audio: 'boolean',
        video_has_alpha: 'boolean',
        video_has_mask: 'boolean',
        origin: 'string',
      },
    },
  },
  singleAudioPlayer: {
    changeTrackClicked: {
      evid: 1205,
      fields: {
        component_type: 'string',
        comp_id: 'string',
      },
    },
    artistNameChanged: {
      evid: 1207,
      fields: {
        component_type: 'string',
        comp_id: 'string',
      },
    },
    trackNameChanged: {
      evid: 1208,
      fields: {
        component_type: 'string',
        comp_id: 'string',
      },
    },
    playbackOptionsChanged: {
      evid: 1209,
      fields: {
        component_type: 'string',
        comp_id: 'string',
        selection: 'string',
      },
    },
    trackAdded: {
      evid: 1210,
      fields: {
        layout: 'string',
        source: 'string',
        trackID: 'string',
        component_type: 'string',
        comp_id: 'string',
      },
    },
  },
  ADVANCED_STYLE_PANEL: {
    BACK_BUTTON_CLICK: {
      evid: 323,
      fields: {
        component_type: 'string',
        component_id: 'string',
      },
    },
    STATE_CHANGED: {
      evid: 259,
      fields: {
        component_type: 'string',
        mouse_state: 'string',
      },
    },
    SCROLL_SKINS: {
      evid: 255,
      fields: {
        component_type: 'string',
        navigation_arrow: 'string',
      },
      sampleRatio: 10,
    },
    SKIN_CHANGE: {
      evid: 256,
      fields: {
        component_type: 'string',
        component_id: 'string',
        skin_id: 'string',
      },
    },
    PANEL_CLOSED: {
      evid: 322,
      fields: {
        component_type: 'string',
      },
    },
    CATEGORY_CHANGE: {
      evid: 257,
      fields: {
        component_type: 'string',
        tab_id: 'string',
        action: 'string',
      },
    },
    COLOR_PICKER_OPENED_FROM_CUSTOMIZE_DESIGN: {
      evid: 605,
      fields: {
        control_name: 'string',
        tab: 'string',
        skin: 'string',
      },
    },
    Component_Part_Tab_Empty: {
      evid: 98,
      fields: {
        skin_name: 'string',
      },
    },
  },
  mobileEditor: {
    hiddenElements: {
      HIDDEN_ELEMENTS_HIDE: {
        evid: 299,
        fields: {
          current_page_name: 'string',
          component_type: 'string',
          origin: 'string',
          site_segment: '',
        },
      },
      HIDDEN_ELEMENTS_SHOW: {
        evid: 300,
        fields: {
          current_page_name: 'string',
          component_type: 'string',
          component_id: 'string',
          section: 'string',
        },
      },
      HIDDEN_ELEMENTS_REFRESH_LAYOUT_TOOLTIP_OPEN: {
        evid: 302,
        fields: {
          current_page_name: 'string',
        },
      },
      HIDDEN_ELEMENTS_REFRESH_LAYOUT_CLICK: {
        evid: 301,
        fields: {},
      },
    },
    mobileTools: {
      MOBILE_ELEMENT_CLICK: {
        evid: 714,
        fields: {
          element_name: 'string',
        },
      },
      BACK_TO_TOP_SHOW_TOGGLE: {
        evid: 303,
        fields: {
          toggle_status: 'string',
        },
      },
      BACK_TO_TOP_PREVIEW_CLICK: {
        evid: 304,
        fields: {},
      },
      MOBILE_ACTION_BAR_TOGGLE: {
        evid: 615,
        fields: {
          toggle_status: 'string',
          site_id: 'guid',
        },
      },
      MOBILE_ACTION_BAR_COMPONENT_TOGGLE: {
        evid: 616,
        fields: {
          toggle_name: 'string',
          toggle_status: 'string',
          site_id: 'guid',
        },
      },
    },
    MOBILE_QRCODE_GO_TO_MOBILE_EDITOR: {
      evid: 1132,
      fields: {
        origin: 'string',
      },
    },
    MOBILE_OPEN_QRCODE_PANEL_CLICK: {
      evid: 1134,
      fields: {
        target: 'string',
      },
    },
    MOBILE_EDITOR_READY: {
      evid: 747,
      fields: {
        origin: 'string',
        is_first: 'boolean',
      },
    },
    MOBILE_EDITOR_DEVICE_CLICKED: {
      evid: 781,
      fields: {},
    },
    mobileWizard: {
      MOBILE_WIZARD_FIRST_STEP_SKIP: {
        evid: 775,
        fields: {},
      },
      MOBILE_WIZARD_TOP_BAR_WIX_LOGO_CLICK: {
        evid: 782,
        fields: {},
      },
      MOBILE_WIZARD_CHOOSE_OPTION: {
        evid: 779,
        fields: {
          current_step: 'string',
          selected_value: 'string',
        },
      },
      MOBILE_WIZARD_NAVIGATE: {
        evid: 776,
        fields: {
          current_step: 'string',
          selected_value: 'string',
          next_or_previous: 'string',
        },
      },
      MOBILE_WIZARD_NAVIGATE_TO_NONE_LANDING_PAGE: {
        evid: 792,
        fields: {
          current_page_id: 'string',
          next_page_id: 'string',
        },
      },
      MOBILE_WIZARD_START: {
        evid: 791,
        fields: {},
      },
      MOBILE_WIZARD_PROGRESS_BAR_NAVIGATE: {
        evid: 778,
        fields: {
          current_step: 'string',
          next_step: 'string',
          selected_value: 'string',
        },
      },
      MOBILE_WIZARD_FINISH: {
        evid: 780,
        fields: {
          current_step: 'string',
          tiny_menu_option: 'string',
          site_header_option: 'string',
          quick_action_bar_option: 'string',
          back_to_top_button_option: 'string',
          finish_method: 'string',
        },
      },
    },
    menuContainer: {
      FIND_OUT_MORE: {
        evid: 882,
        src: 38,
        fields: {
          origin: 'string',
        },
      },
      UPGRADE: {
        evid: 883,
        src: 38,
        fields: {
          origin: 'string',
        },
      },
    },
  },
  commonControls,
  myMediaPanel: {
    my_uploads_panel_section_click: {
      evid: 370,
      fields: {
        section: 'string',
      },
    },
  },
  mediaEditorPanel: {
    OPENED: {
      evid: 662,
      src: 26,
      endpoint: 'media-manager-g6',
      fields: {
        jsonData: 'string',
        recentlySectionExists: 'boolean',
        loadingTime: 'numeric',
        esi: 'guid',
        msid: 'guid',
      },
    },
    SEARCH_CANCEL: {
      evid: 663,
      src: 26,
      endpoint: 'media-manager-g6',
      fields: {
        target: 'string',
        esi: 'guid',
        msid: 'guid',
      },
    },
    SEARCH_START: {
      evid: 658,
      src: 26,
      endpoint: 'media-manager-g6',
      fields: {
        target: 'string',
        esi: 'guid',
        msid: 'guid',
      },
    },
    SEARCH_FINISH: {
      evid: 659,
      src: 26,
      endpoint: 'media-manager-g6',
      fields: {
        target: 'string',
        success: 'boolean',
        jsonData: 'string',
        loadingTime: 'numeric',
        esi: 'guid',
        msid: 'guid',
      },
    },
    SHUTTERSTOCK_PURCHASE_BUTTON_CLICK: {
      evid: 660,
      src: 26,
      endpoint: 'media-manager-g6',
      fields: {
        section: 'string',
        mediaProducer: 'string',
        category: 'string',
        esi: 'guid',
        fileId: 'string',
        msid: 'guid',
      },
    },
    MEDIA_PURCHASE_COMPLETED: {
      evid: 680,
      src: 26,
      endpoint: 'media-manager-g6',
      fields: {
        fileId: 'string',
        name: 'string',
        section: 'string',
        mediaType: 'string',
        mediaProducer: 'string',
        fileFormat: 'string',
        isNew: 'boolean',
        esi: 'guid',
        msid: 'guid',
      },
    },
    UPLOAD_BUTTON_CLICK: {
      evid: 661,
      src: 26,
      endpoint: 'media-manager-g6',
      fields: {
        button: 'string',
        esi: 'guid',
        msid: 'guid',
      },
    },
    RECOMMENDED_BUTTON_CLICK: {
      evid: 671,
      src: 26,
      endpoint: 'media-manager-g6',
      fields: {
        target: 'string',
        selection: 'string',
        button_name: 'string',
        esi: 'guid',
        msid: 'guid',
      },
    },
  },
  animationPanel: {
    animation_settings_back_click: {
      evid: 1150,
      fields: {
        component_type: 'string',
        component_id: 'string',
      },
    },
    animation_panel_behavior_click: {
      evid: 371,
      fields: {
        behavior_name: 'string',
        action_name: 'string',
        component_name: 'string',
        component_type: 'string',
      },
    },
    animation_panel_customize_click: {
      evid: 372,
      fields: {
        behavior_name: 'string',
        action_name: 'string',
        component_name: 'string',
      },
    },
    animation_panel_behavior_saved: {
      evid: 373,
      fields: {
        behavior_name: 'string',
        action_name: 'string',
        component_name: 'string',
        duration: 'numeric',
        delay: 'numeric',
        direction: 'string',
        cycle: 'numeric',
        animate_once: 'boolean',
        origin: 'string',
      },
    },
    animation_panel_behavior_deleted: {
      evid: 374,
      fields: {
        behavior_name: 'string',
        action_name: 'string',
        component_name: 'string',
        component_type: 'string',
      },
    },
  },
  backgroundPanel,
  shapeComponentPanel: {
    shape_component_settings_panel_changes: {
      evid: 1141,
      fields: {
        component_type: 'string',
        component_id: 'string',
        fieldName: 'string',
        fieldValue: 'string',
      },
    },
  },
  inputManageItemsPanel: {
    DELETE_CHOICE: {
      src: 38,
      evid: 1163,
      fields: {
        msid: 'guid',
        esi: 'guid',
        field_type: 'string',
        choice_name: 'string',
        builder_origin: 'string',
      },
    },
    EDIT_LABEL_START: {
      src: 38,
      evid: 1179,
      fields: {
        msid: 'guid',
        esi: 'guid',
        field_type: 'string',
        builder_origin: 'string',
      },
    },
    EDIT_LABEL_DONE: {
      src: 38,
      evid: 1180,
      fields: {
        msid: 'guid',
        esi: 'guid',
        field_type: 'string',
        builder_origin: 'string',
      },
    },
    TOGGLE_CHOICE_DEFAULT: {
      src: 5,
      evid: 876,
      fields: {
        msid: 'guid',
        esi: 'guid',
        field_type: 'string',
        choice_name: 'string',
        builder_origin: 'string',
      },
    },
    DUPLICATE_CHOICE_START: {
      src: 5,
      evid: 877,
      fields: {
        msid: 'guid',
        esi: 'guid',
        field_type: 'string',
        choice_name: 'string',
        builder_origin: 'string',
      },
    },
    DUPLICATE_CHOICE_DONE: {
      src: 5,
      evid: 878,
      fields: {
        msid: 'guid',
        esi: 'guid',
        field_type: 'string',
        choice_name: 'string',
        builder_origin: 'string',
      },
    },
    CHOICE_REORDER_DONE: {
      src: 38,
      evid: 1164,
      fields: {
        msid: 'guid',
        esi: 'guid',
        field_type: 'string',
        choice_name: 'string',
        builder_origin: 'string',
      },
    },
    ADD_CHOICE: {
      src: 38,
      evid: 1165,
      fields: {
        field_type: 'string',
      },
    },
    CHANGE_DISPLAY_NAME: {
      src: 38,
      evid: 1173,
      fields: {
        field_type: 'string',
      },
    },
    SET_REQUIRED: {
      src: 5,
      evid: 900,
      fields: {
        field_type: 'string',
      },
    },
  },
  media_player: {
    videobox_behavior: {
      evid: 802,
      fields: {
        video_onload: 'string',
        video_pause: 'string',
        video_ends: 'string',
        video_slow_mo_available: 'boolean',
        video_slow_mo: 'boolean',
        audio_available: 'boolean',
        audio_play: 'string',
      },
    },
    videobox_design: {
      evid: 803,
      fields: {
        cover_index: 'number',
        cover_media_name: 'string',
        cover_animate: 'boolean',
        overlay_index: 'number',
        overlay_media_name: 'string',
      },
    },
    videobox_bigplay: {
      evid: 804,
      fields: {
        bigplay_index: 'number',
        bigplay_media_name: 'string',
        bigplay_opacity: 'number',
        bigplay_colors: 'string',
      },
    },
  },
  stripContainerSettings: {
    strip_background_panel_background_changed: {
      evid: 487,
      fields: {
        panel_name: 'string',
        origin: 'string',
        bg_media_type: 'string',
        bg_mobile_detached: 'string',
        bg_media_name: 'string',
        bg_page_id: 'string',
        bg_preset_index: 'number',
        bg_vendor_name: 'string',
        bg_vendor_media_id: 'string',
        bg_color: 'string',
        bg_fitting: 'string',
        bg_positioning: 'string',
        bg_opacity: 'number',
        bg_scroll_with_site: 'string',
        bg_overlay_color: 'string',
        bg_video_opacity: 'number',
        bg_overlay_color_opacity: 'string',
        bg_video_overlay_pattern: 'string',
        bg_video_loop: 'string',
        bg_slideshow_image_fitting: 'string',
        bg_slideshow_transition_type: 'string',
        bg_slideshow_transition_duration: 'number',
        bg_slideshow_transition_direction: 'string',
        bg_slideshow_pausebetweenimages: 'number',
        component_id: 'string',
        source: 'string',
        is_ai_generator: 'boolean',
      },
    },
  },
  effectsPanel: {
    strip_background_scroll_effects: {
      evid: 1019,
      fields: {
        component_type: 'string',
        component_id: 'string',
        bg_scroll_with_site: 'string',
        origin: 'string',
      },
    },
  },
  lists: {
    LIST_DB_MANAGER_MIGRATION_HELP_CLICK: {
      src: 59,
      endpoint: 'listim2',
      evid: 130,
      fields: {
        panel_name: 'string',
        origin: 'string',
        learn_more: 'bool',
        component: 'string',
      },
    },
    LIST_SETTINGS_PANEL_OPENED: {
      src: 59,
      endpoint: 'listim2',
      evid: 101,
      fields: {
        list_part_id: 'string',
        component_id: 'string',
      },
    },
  },
  rightClickMenu,
  editBox: {
    ROTATE_COMPONENT: {
      evid: 153,
      fields: {
        value: 'number',
        origin: 'string',
        component_type: 'string',
        status: 'string',
      },
    },
    CANCEL_ROTATE_COMPONENT: {
      evid: 154,
      fields: {},
    },
    DRAG_BY_HANDLE: {
      evid: 155,
      fields: {
        component_type: 'string',
      },
    },
    RESIZE_BY_HANDLE: {
      evid: 156,
      fields: {
        component_type: 'string',
      },
    },
    RESIZE: {
      evid: 157,
      fields: {
        appId: 'string',
        handle_value: 'string',
        origin: 'string',
      },
      sampleRatio: 10,
    },
    MOVE_TO_FOOTER: {
      evid: 468,
      fields: {
        component_type: 'string',
      },
    },
    DRAG_GAP: {
      evid: 752,
      fields: {
        gap_type: 'string',
        gap_size_start: 'number',
        gap_size_end: 'number',
      },
    },
  },
  publish,
  push: {
    pushClick: {
      evid: 901,
      src: 38,
    },
    openOnlineModeAfterPush: {
      evid: 902,
      src: 38,
    },
    openSiteHistoryAfterPush: {
      evid: 906,
      src: 38,
    },
    forcePushClicked: {
      evid: 903,
      src: 38,
      fields: {
        step: 'string',
      },
    },
  },
  localSave: {
    localSaveClick: {
      evid: 916,
      src: 38,
      fields: {
        initiatorName: 'string',
      },
    },
  },
  preview: {
    PREVIEW_CLICK: {
      evid: 250,
      src: 38,
      fields: {
        origin: 'string',
      },
    },
    PREVIEW_SUCCESS: {
      evid: 957,
      src: 38,
      fields: {
        origin: 'string',
        duration: 'numeric',
        is_published: 'boolean',
      },
    },
  },
  testSite: {
    MAIN_CTA_CLICKED: {
      evid: 285,
      src: 38,
      fields: {
        category: 'string',
      },
    },
    TEST_SITE_DROPDOWN_CLICKED: {
      evid: 1473,
      src: 38,
      fields: {
        origin: 'string',
      },
    },
    TEST_SITE_BUTTON_DROPDOWN_LEARN_MORE: {
      evid: 254,
      src: 38,
      fields: {
        panel_name: 'string',
        help_id: 'string',
        learn_more: 'boolean',
      },
    },
  },
  save: {
    saveProcess: {
      SAVE_CLICK: {
        evid: 260,
        fields: {
          origin: 'string',
        },
      },
      START_SAVE_PROCESS: {
        evid: 461,
        fields: {
          save_type: 'string',
        },
      },
      SUCCESS_SAVE_PROCESS: {
        evid: 462,
        fields: {
          duration: 'numeric',
          save_type: 'string',
          revisionId: 'number',
        },
      },
    },
    chooseDomainPanel: {
      DOMAIN_OPTION_CLICKED: {
        evid: 315,
        fields: {
          selection: 'string',
        },
      },
      ACTION_BUTTON_CLICKED: {
        evid: 317,
        fields: {
          value_selected: 'string',
        },
      },
    },
    siteWasSavedPanel: {
      FIRST_SAVE_ACTION_BUTTON_CLICKED: {
        evid: 325,
        fields: {},
      },
      HELP_CONNECTING_DOMAIN_CLICKED: {
        evid: 326,
        fields: {},
      },
      CONNECT_NEW_DOMAIN_CLICKED: {
        evid: 327,
        fields: {},
      },
      CONNECT_EXISTING_DOMAIN_CLICKED: {
        evid: 328,
        fields: {},
      },
      First_Save_Dialog_2_maybe_later_click: {
        evid: 329,
        fields: {},
      },
      PUBLISH_NOW_CLICKED: {
        evid: 335,
        fields: {
          is_first_save: 'boolean',
          user_status: 'string',
        },
      },
      SECOND_SAVE_DONT_SHOW_AGAIN_CLICKED: {
        evid: 336,
        fields: {
          toggle: 'boolean',
          user_status: 'string',
        },
      },
      SECOND_SAVE_ACTION_BUTTON_CLICKED: {
        evid: 337,
        fields: {
          user_status: 'string',
        },
      },
      SECOND_SAVE_CONNECT_DOMAIN_CLICKED: {
        evid: 338,
        fields: {
          user_status: 'string',
        },
      },
      SECOND_SAVE_PUBLISH_NOW_BUTTON_CLICKED: {
        evid: 339,
        fields: {
          user_status: 'string',
        },
      },
    },
    saveFailed: {
      First_Save_error_work_not_saved_error_close_click: {
        evid: 340,
        fields: {},
      },
      First_Save_error_work_not_saved_error_ok_click: {
        evid: 341,
        fields: {},
      },
      First_Save_error_work_not_saved_error_help_center_link_click: {
        evid: 342,
        fields: {},
      },
      CONCURRENT_SAVE: {
        evid: 447,
        fields: {
          selection: 'string',
        },
      },
      CONCURRENT_AUTOSAVE: {
        evid: 660,
        fields: {
          selection: 'string',
        },
      },
    },
    saveReminder: {
      REMINDER_DISPLAYED: {
        evid: 456,
        fields: {
          origin: 'string',
        },
      },
      SAVE_CLICKED: {
        evid: 457,
        fields: {},
      },
    },
    autosave: {
      ENABLE_DISABLE: {
        evid: 764,
        fields: {
          type: 'string',
        },
      },
    },
  },
  domainInput: {
    SITE_DOMAIN_VALIDATION_SUCCESS: {
      evid: 1039,
    },
    DISABLED_DOMAIN_PART_CLICKED: {
      evid: 444,
      fields: {
        user_status: 'string',
        save_or_publish: 'string',
        panel_name: 'string',
        is_first_save_or_publish: 'boolean',
      },
    },
    DOMAIN_VALIDATION_ERROR: {
      evid: 445,
      fields: {
        user_status: 'string',
        panel_name: 'string',
        is_first_save: 'boolean',
      },
    },
    DOMAIN_EDIT_SAVE_CLICKED: {
      evid: 446,
      fields: {
        user_status: 'string',
        panel_name: 'string',
        is_first_save: 'boolean',
      },
    },
    DOMAIN_EDIT_CANCEL_CLICKED: {
      evid: 448,
      fields: {
        user_status: 'string',
        panel_name: 'string',
        is_first_save: 'boolean',
      },
    },
    DOMAIN_EDIT_CLICKED: {
      evid: 450,
      fields: {
        user_status: 'string',
        panel_name: 'string',
        is_first_save: 'boolean',
      },
    },
  },
  selection: {
    select_container_component: {
      evid: 1040,
      fields: {
        parent_component_type: 'string',
        parent_component_id: 'string',
        component_type: 'string',
        component_id: 'string',
      },
    },
    MULTISELECT: {
      evid: 127,
      num_of_elements_selected: 'number',
      selection_method: 'string',
    },
  },
  colorPicker: {
    CHANGE_SITE_COLORS_LINK_CLICKED: {
      evid: 131,
      fields: {},
    },
    SITE_COLOR_SELECT: {
      evid: 132,
      fields: {
        index_selected_color: 'numeric',
        parent_item_id: 'string',
        component_id: 'string',
        component_type: 'string',
      },
      sampleRatio: 10,
    },
    ADD_COLOR_BUTTON_CLICKED: {
      evid: 133,
      fields: {},
    },
    ADD_COLOR_LINK_CLICKED: {
      evid: 134,
      fields: {},
    },
    CUSTOM_COLOR_SELECT: {
      evid: 135,
      fields: {
        component_id: 'string',
      },
    },
    MAIN_PANEL_CLOSED: {
      evid: 251,
      fields: {},
    },
    ADD_COLOR_PANEL_CLOSED: {
      evid: 252,
      fields: {},
    },
    CHANGE_SITE_COLOR_CLOSED: {
      evid: 253,
      fields: {},
    },
    COLOR_SPACE_ADD_NEW_COLOR_TINTS: {
      evid: 399,
      fields: {
        component_id: 'string',
        value: 'string',
        panelName: 'string',
      },
    },
    COLOR_SPACE_ADD_NEW_COLOR_OK: {
      evid: 401,
      fields: {
        component_id: 'string',
        panelName: 'string',
        isChanged: 'boolean',
      },
    },
    COLOR_SPACE_ADD_NEW_COLOR_CANCEL: {
      evid: 402,
      fields: {
        component_id: 'string',
        panelName: 'string',
      },
    },
    EDIT_SITE_COLORS_CLICK: {
      evid: 403,
      fields: {
        component_id: 'string',
        value: 'string',
        target: 'string',
        panelName: 'string',
      },
    },
    EDIT_SITE_COLORS_PALETTE_SELECT: {
      evid: 405,
      fields: {
        component_id: 'string',
        value: 'string',
      },
    },
    EDIT_MAIN_COLOR_CANCEL: {
      evid: 410,
      fields: {},
    },
    EDIT_MAIN_COLOR_OK: {
      evid: 411,
      fields: {},
    },
    MY_COLORS_TOOLTIP_OPEN: {
      evid: 1069,
      fields: {
        panel_name: 'string',
      },
    },
  },
  organizeImages: {
    ORGIMAGES_CLICK_CLOSE: {
      src: 26,
      endpoint: 'mg',
      evid: 201,
      fields: {},
    },
    ORGIMAGES_CLICK_CANCEL: {
      src: 26,
      endpoint: 'mg',
      evid: 202,
      fields: {},
    },
    ORGIMAGES_CLICK_CONFIRMATION: {
      src: 26,
      endpoint: 'mg',
      evid: 203,
      fields: {},
    },
    ORGIMAGES_CLICK_REPLACE: {
      src: 26,
      endpoint: 'mg',
      evid: 204,
      fields: {},
    },
    ORGIMAGES_CLICK_ADD_IMAGES: {
      src: 26,
      endpoint: 'mg',
      evid: 205,
      fields: {},
    },
    ORGIMAGES_START_REORDERING: {
      src: 26,
      endpoint: 'mg',
      evid: 206,
      fields: {},
    },
    ORGIMAGES_CLICK_DELETE: {
      src: 26,
      endpoint: 'mg',
      evid: 207,
      fields: {},
    },
    ORGIMAGES_DIALOG_LOADED: {
      src: 26,
      endpoint: 'mg',
      evid: 208,
      fields: {},
    },
    ORGIMAGES_CLICK_ORGIMAGES: {
      src: 26,
      endpoint: 'mg',
      evid: 209,
      fields: {},
    },
    ORGIMAGES_EDIT_TITLE: {
      src: 26,
      endpoint: 'mg',
      evid: 210,
      fields: {},
    },
    ORGIMAGES_EDIT_DESCRIPTION: {
      src: 26,
      endpoint: 'mg',
      evid: 211,
      fields: {},
    },
    ORGIMAGES_ADD_LINK: {
      evid: 212,
      fields: {},
    },
    ORGIMAGES_USER_SETS_OPEN: {
      src: 26,
      endpoint: 'mg',
      evid: 137,
      fields: {},
    },
    ORGIMAGES_USER_SETS_ORDER_CHANGED: {
      src: 26,
      endpoint: 'mg',
      evid: 138,
      fields: {
        import_order: 'string',
      },
    },
    ORGIMAGES_USER_SETS_TITLES_CHANGED: {
      src: 26,
      endpoint: 'mg',
      evid: 139,
      fields: {
        titles_import: 'string',
      },
    },
    ORGIMAGES_USER_SETS_CONFIRM: {
      src: 26,
      endpoint: 'mg',
      evid: 140,
      fields: {},
    },
    ORGIMAGES_USER_SETS_CLOSE: {
      src: 26,
      endpoint: 'mg',
      evid: 141,
      fields: {},
    },
  },
  pinToScreen: {
    OPEN_PIN_TO_SCREEN_PANEL: {
      evid: 332,
      src: 38,
      fields: {
        origin: 'string',
        component_type: 'string',
        component_id: 'string',
      },
    },
    UNPIN: {
      evid: 333,
      src: 38,
      fields: {
        origin: 'string',
        component_type: 'string',
        component_id: 'string',
      },
    },
    NOTIFICATION_TOO_BIG: {
      evid: 349,
      src: 38,
      fields: {
        state: 'string',
        component_type: 'string',
      },
    },
    POSITION_SELECTED: {
      evid: 382,
      src: 38,
      fields: {
        selected_area: 'string',
        component_type: 'string',
        component_id: 'string',
      },
    },
    DRAG_ATTEMPT: {
      evid: 386,
      src: 38,
      fields: {
        component_type: 'string',
        component_id: 'string',
      },
    },
    FIRST_PIN_TO_SCREEN: {
      evid: 810,
      fields: {
        component_type: 'string',
        component_id: 'string',
        selected_area: 'string',
      },
    },
  },
  imageCrop: {
    Open_crop_mode: {
      evid: 510,
      fields: {
        fixed_layout: 'bool',
      },
    },
    open_crop_options_menu: {
      evid: 511,
    },
    crop_option_selected: {
      evid: 512,
      fields: {
        action_name: 'string',
      },
    },
    apply_crop: {
      evid: 513,
      fields: {
        is_changed: 'bool',
        origin: 'string',
      },
    },
    resize_the_frame_in_crop_mode: {
      evid: 514,
    },
    try_to_drag_the_crop_tool_bar: {
      evid: 515,
    },
  },
  musicPromotionPopup: {
    submit_video_clicked: {
      evid: 306,
      fields: {
        origin: 'string',
      },
    },
  },
  photographyPromotionPopup: {
    submit_video_clicked: {
      evid: 628,
      fields: {
        origin: 'string',
      },
    },
  },
  applyStyle: {
    COPY_STYLE_CHOOSE_STYLES_TO_REPLACE_CLICK: {
      evid: 319,
      src: 38,
      fields: {
        status: 'bool',
        style_id: 'string',
        component_id: 'string',
        component_type: 'string',
      },
    },
    COPY_STYLE_RCM_CLICK: {
      evid: 318,
      src: 38,
      fields: {
        component_id: 'string',
        component_type: 'string',
      },
    },
    COPY_STYLE_CANCEL: {
      evid: 320,
      src: 38,
      fields: {
        component_id: 'string',
        component_type: 'string',
      },
    },
    COPY_STYLE_APPLY: {
      evid: 321,
      src: 38,
      fields: {
        num_of_styles_chosen: 'numeric',
        component_id: 'string',
        component_type: 'string',
      },
    },
  },
  sectionReorganize: {
    zoom_mode_spacer_drag: {
      evid: 550,
      src: 38,
    },
    reorder_panel_clicked: {
      evid: 551,
      src: 38,
      fields: {
        action_clicked: 'string',
        editor_view_mode: 'string',
      },
    },
    header_label_appear: {
      evid: 554,
      src: 38,
      fields: {
        editor_view_mode: 'string',
      },
      sampleRatio: 10,
    },
    footer_label_appear: {
      evid: 555,
      src: 38,
      fields: {
        editor_view_mode: 'string',
      },
      sampleRatio: 10,
    },
    footer_tooltip_hover: {
      evid: 553,
      src: 38,
      fields: {
        editor_view_mode: 'string',
      },
    },
    header_tooltip_hover: {
      evid: 552,
      src: 38,
      fields: {
        editor_view_mode: 'string',
      },
    },
    SECTION_APPEARANCE: {
      evid: 470,
      src: 38,
      fields: {
        page_id: 'string',
      },
    },
    HOVER_SECTION_HANDLE: {
      evid: 471,
      src: 38,
      fields: {
        page_id: 'string',
        section_size: 'string',
      },
      sampleRatio: 10,
    },
    REORDER_SECTION: {
      evid: 472,
      src: 38,
      fields: {
        position_before: 'string',
        position_after: 'string',
        page_id: 'string',
        section_size: 'string',
      },
    },
    SPACE_TOP_DRAG: {
      evid: 473,
      src: 38,
      fields: {
        page_id: 'string',
        section_size: 'string',
      },
    },
    SPACE_BOTTOM_DRAG: {
      evid: 474,
      src: 38,
      fields: {
        page_id: 'string',
        section_size: 'string',
      },
    },
    SECTION_ZOOM_IN: {
      evid: 475,
      src: 38,
      fields: {
        page_id: 'string',
        scale: 'numeric',
      },
    },
    SECTION_ZOOM_OUT: {
      evid: 476,
      src: 38,
      fields: {
        page_id: 'string',
        scale: 'numeric',
      },
    },
    SPACE_DELETED: {
      evid: 677,
      src: 38,
      fields: {
        site_id: 'guid',
        page_id: 'string',
        space_size: 'numeric',
      },
    },
  },
  ON_BOARDING_MIGRATION: {
    PANEL_ACTION_CLICKED: {
      evid: 619,
      src: 38,
      fields: {
        action: 'string',
      },
    },
  },
  newEditorWelcomeScreens: {
    POP_UP_OPEN_WITH_BACK_OPTION: {
      evid: 478,
      src: 38,
      fields: {
        site_id: 'guid',
      },
    },
    POP_UP_OPEN_WITHOUT_BACK_OPTION: {
      evid: 479,
      src: 38,
      fields: {
        site_id: 'guid',
      },
    },
    LEARN_MORE_CLICK_WITHOUT_BACK_OPTION: {
      evid: 480,
      src: 38,
      fields: {
        duration: 'numeric',
        site_id: 'guid',
      },
    },
    LEARN_MORE_CLICK_WITH_BACK_OPTION: {
      evid: 481,
      src: 38,
      fields: {
        duration: 'numeric',
        site_id: 'guid',
      },
    },
    START_NOW_CLICK_WITH_BACK_OPTION: {
      evid: 482,
      src: 38,
      fields: {
        duration: 'numeric',
        site_id: 'guid',
      },
    },
    START_NOW_CLICK_WITHOUT_BACK_OPTION: {
      evid: 483,
      src: 38,
      fields: {
        duration: 'numeric',
        site_id: 'guid',
      },
    },
    ESC_CLICK_WITHOUT_BACK_OPTION: {
      evid: 484,
      src: 38,
      fields: {
        duration: 'numeric',
        site_id: 'guid',
      },
    },
    ESC_CLICK_WITH_BACK_OPTION: {
      evid: 485,
      src: 38,
      fields: {
        duration: 'numeric',
        site_id: 'guid',
      },
    },
    BACK_TO_OLD_EDITOR_CLICK: {
      evid: 486,
      src: 38,
      fields: {
        duration: 'numeric',
        site_id: 'guid',
      },
    },
  },
  siteImageQualitySettingsPanel: {
    site_image_quality_settings_panel_apply: {
      evid: 517,
      src: 38,
      fields: {
        origin: 'string',
        preset: 'string',
        unsharp_amount: 'numeric',
        unsharp_radius: 'numeric',
        unsharp_threshold: 'numeric',
      },
    },
  },
  newUserIntroVideoPanel: {
    INTRO_VIDEO_PAGE_LOADS: {
      evid: 490,
      src: 38,
      fields: {
        site_id: 'guid',
      },
    },
    INTRO_VIDEO_START_NOW_CLICK: {
      evid: 491,
      src: 38,
      fields: {
        duration: 'numeric',
        site_id: 'guid',
      },
    },
    INTRO_VIDEO_CLOSE: {
      evid: 492,
      src: 38,
      fields: {
        duration: 'numeric',
        origin: 'string',
        video_url: 'string',
        site_id: 'guid',
        panel_name: 'string',
      },
    },
  },
  cameFromADIVideoPanel: {
    INTRO_VIDEO_PAGE_LOADS: {
      evid: 495,
      src: 38,
      fields: {
        site_id: 'guid',
      },
    },
    INTRO_VIDEO_START_NOW_CLICK: {
      evid: 491,
      src: 38,
      fields: {
        duration: 'numeric',
        site_id: 'guid',
      },
    },
  },
  newFeaturesVideoPanel: {
    NEW_FEATURES_INTRO_VIDEO_PAGE_LOADS: {
      evid: 590,
      src: 38,
      fields: {
        site_id: 'guid',
      },
    },
    NEW_FEATURES_INTRO_VIDEO_START_NOW_CLICK: {
      evid: 591,
      src: 38,
      fields: {
        duration: 'numeric',
        site_id: 'guid',
      },
    },
    NEW_FEATURES_INTRO_VIDEO_CLOSE: {
      evid: 592,
      src: 38,
      fields: {
        duration: 'numeric',
        origin: 'string',
        video_url: 'string',
        site_id: 'guid',
        panel_name: 'string',
      },
    },
  },
  getTrafficPanel: {
    GET_TRAFFIC_GET_FOUND_ON_GOOGLE_CLICK: {
      evid: 629,
      src: 38,
      fields: {
        origin: 'string',
        video_url: 'string',
        site_id: 'guid',
      },
    },
    GET_TRAFFIC_SEARCH_GOOGLE_CLICK: {
      evid: 630,
      src: 38,
      fields: {
        url: 'string',
        site_id: 'guid',
      },
    },
  },
  urlTooltip: {
    url_tooltip_opened: {
      evid: 543,
      src: 38,
      fields: {},
    },
    url_tooltip_action_clicked: {
      evid: 544,
      src: 38,
      fields: {
        name_value: 'string',
        translate_mode: 'string',
        action: 'string',
      },
    },
    url_tooltip_notification_appeared: {
      evid: 545,
      src: 38,
      fields: {},
    },
    url_tooltip_notification_clicked: {
      evid: 546,
      src: 38,
      fields: {},
    },
    url_tooltip_notification_disappeared: {
      evid: 547,
      src: 38,
      fields: {},
    },
  },
  welcomeVideoPanel: {
    WELCOME_SCREEN_CLOSED: {
      evid: 497,
      src: 38,
      fields: {
        panel_name: 'string',
        origin: 'string',
      },
    },
  },
  concurrentUsers,
  wixstores: {
    WIXSTORES_MIGRATION_UPGRADE_CLICKED: {
      evid: 506,
      src: 38,
      fields: {
        origin: 'string',
      },
    },
  },
  help: {
    HELP_CLICK: {
      evid: 254,
      src: 38,
      fields: {
        learn_more: 'boolean',
        panel_name: 'string',
        origin: 'string',
        component: 'string',
        helpId: 'string',
      },
    },
  },
  helpHome,
  imageFiltersPanel: {
    image_filter_panel_closed: {
      evid: 556,
      src: 38,
      fields: {
        filter_name: 'string',
        component_name: 'string',
        origin: 'string',
      },
    },
  },
  rulers: {
    RULERS_HOVER: {
      evid: 520,
      src: 38,
      fields: {
        editor_view_mode: 'string',
      },
    },
    RULERS_CLICKED: {
      evid: 521,
      src: 38,
      fields: {
        editor_view_mode: 'string',
        xCoordinate: 'string',
        yCoordinate: 'string',
      },
    },
    GUIDE_DRAGGED: {
      evid: 523,
      src: 38,
      fields: {
        editor_view_mode: 'string',
        xCoordinate_new: 'string',
        xCoordinate_old: 'string',
        yCoordinate_new: 'string',
        yCoordinate_old: 'string',
      },
    },
    GUIDE_DELETED: {
      evid: 524,
      src: 38,
      fields: {
        editor_view_mode: 'string',
        origin: 'string',
      },
    },
    GUIDE_TOOLTIP_APPEAR: {
      evid: 525,
      src: 38,
      fields: {
        editor_view_mode: 'string',
      },
    },
    GUIDE_TOOLTIP_IN_EDIT_MODE: {
      evid: 526,
      src: 38,
      fields: {
        editor_view_mode: 'string',
        origin: 'string',
      },
    },
    VALUE_CHANGE_IN_GUIDE_TOOLTIP: {
      evid: 558,
      src: 38,
      fields: {
        editor_view_mode: 'string',
        type: 'string',
        value: 'string',
        valid_value: 'string',
      },
    },
  },
  boxSlideShow: {
    MANAGE_SLIDES_SLIDE_ACTION_SELECTED: {
      evid: 533,
      fields: {
        action: 'string',
      },
    },
    SLIDES_NAVIGATION: {
      evid: 534,
      fields: {
        componentType: 'string',
        component_id: 'string',
        origin: 'string',
      },
    },
    MANAGE_SLIDES_SLIDE_REORDERED: {
      evid: 535,
      fields: {
        componentType: 'string',
        component_id: 'string',
      },
    },
    MANAGE_SLIDES_ACTION_MENU_OPENED: {
      evid: 536,
      fields: {
        componentType: 'string',
        component_id: 'string',
      },
    },
    MANAGE_SLIDES_ADD_NEW_SLIDE: {
      evid: 537,
      fields: {
        componentType: 'string',
        component_id: 'string',
      },
    },
    MANAGE_SLIDES_DUPLICATE_CURRENT_SLIDE: {
      evid: 538,
      fields: {
        componentType: 'string',
        component_id: 'string',
      },
    },
    APPLY_ON_OTHER_SLIDES_CLICKED: {
      evid: 539,
      fields: {
        componentType: 'string',
        component_id: 'string',
      },
    },
    APPLY_ON_OTHER_SLIDES_SELECTED_SLIDES: {
      evid: 540,
      fields: {
        total_slides: 'string',
        num_selected_slides: 'string',
        all_slides_selected: 'boolean',
        componentType: 'string',
        component_id: 'string',
      },
    },
    SETTINGS_TRANSITION_SELECTED: {
      evid: 541,
      fields: {
        transition: 'string',
        componentType: 'string',
        component_id: 'string',
      },
    },
    SETTINGS_TRANSITION_DIRECTION_SELECTED: {
      evid: 542,
      fields: {
        direction: 'string',
        componentType: 'string',
        component_id: 'string',
      },
    },
    SLIDESHOW_HIDDEN_ELEMENTS_TOOLTIP_APPEAR: {
      evid: 620,
      fields: {},
    },
    SLIDESHOW_HIDDEN_ELEMENTS_TOOLTIP_ACTION_CLICKED: {
      evid: 621,
      fields: {
        action: 'string',
      },
    },
  },
  columns: {
    COLUMNS_FIT_TO_SIZE_CLICK: {
      evid: 570,
      fields: {
        value: 'string',
      },
    },
    COLUMNS_SPLIT_TO_COLUMNS_CLICK: {
      evid: 571,
      fields: {
        num_of_columns_before: 'numeric',
      },
    },
    COLUMNS_ALIGNMENT_CLICK: {
      evid: 572,
      fields: {
        value: 'string',
      },
    },
    COLUMNS_ADD_COLUMN_CLICK: {
      evid: 573,
      fields: {
        origin: 'string',
        num_of_columns_before: 'numeric',
      },
    },
    COLUMNS_PROPORTION_CLICK: {
      evid: 574,
      fields: {
        value: 'string',
      },
    },
    COLUMNS_REVERSE_LAYOUT_CLICK: {
      evid: 575,
      fields: {},
    },
    COLUMNS_CUSTOMIZE_PROPORTIONS_CLICK: {
      evid: 576,
      fields: {
        value: 'string',
        origin: 'string',
        component_type: 'string',
        component_id: 'string',
      },
    },
    COLUMNS_ELEMENTS_PLACED_IN_DEAD_SPOT: {
      evid: 577,
      fields: {
        num_of_elements: 'numeric',
        is_same_parent_component: 'boolean',
      },
    },
    COLUMNS_ELEMENTS_PLACED_IN_DEAD_SPOT_WARNING: {
      evid: 578,
      fields: {},
    },
    COLUMNS_MOVE_LEFT_RIGHT: {
      evid: 579,
      fields: {
        origin: 'string',
      },
    },
    COLUMNS_MOVE_UP_DOWN: {
      evid: 580,
      fields: {
        origin: 'string',
      },
    },
    COLUMNS_DUPLICATE_CLICK: {
      evid: 581,
      fields: {},
    },
    COLUMNS_CHANGE_BACKGROUND: {
      evid: 582,
      fields: {
        preset_id: 'string',
      },
    },
    COLUMNS_BACKGROUND_TOOLTIP_APPEARANCE: {
      evid: 583,
      fields: {},
    },
    COLUMNS_OUT_OF_GRID_WARNING: {
      evid: 584,
      fields: {},
    },
    COLUMNS_MANAGER_ACTION_CLICK: {
      evid: 624,
      fields: {
        action: 'string',
      },
    },
  },
  videoPlayer: {
    OPEN: {
      evid: 667,
      src: 38,
      fields: {
        videoID: 'string',
      },
    },
    CLOSE: {
      evid: 668,
      src: 38,
      fields: {
        videoID: 'string',
        duration: 'numeric',
        origin: 'string',
      },
    },
    CHANGE_VIDEO: {
      evid: 820,
      fields: {
        component_type: 'string',
        component_id: 'string',
        videoSource: 'string',
        video_id: 'string',
        origin: 'string',
      },
    },
    SETTINGS_AUTOPLAY_VIDEO_CHANGED: {
      evid: 821,
      fields: {
        component_type: 'string',
        component_id: 'string',
        toggle: 'boolean',
        origin: 'string',
      },
    },
    SETTINGS_PLAY_ON_LOOP_CHANGED: {
      evid: 822,
      fields: {
        component_type: 'string',
        component_id: 'string',
        toggle: 'boolean',
        origin: 'string',
      },
    },
    LAYOUT_PLAY_BUTTON_SIZE_CHANGED: {
      evid: 828,
      fields: {
        component_id: 'string',
      },
    },
    LAYOUT_SHOW_VIDEO_TITLE_CHANGED: {
      evid: 829,
      fields: {
        component_id: 'string',
        toggle: 'boolean',
      },
    },
    LAYOUT_CONTROLS_SHOW_CHANGED: {
      evid: 830,
      fields: {
        component_type: 'string',
        component_id: 'string',
        selection: 'string',
      },
    },
    CHECK_OUT_WIX_VIDEO_CLICK: {
      evid: 813,
      fields: {
        component_id: 'string',
      },
    },
    CHANGE_VIDEO_TYPE: {
      evid: 861,
      fields: {
        clickOrigin: 'string',
        component_id: 'string',
        videoSource: 'string',
        origin: 'string',
      },
    },
    CHANGE_VIDEO_CLICK: {
      evid: 862,
      fields: {
        component_id: 'string',
        origin: 'string',
      },
    },
    URL_PARSING: {
      evid: 863,
      fields: {
        component_id: 'string',
        success: 'boolean',
        videoUrl: 'string',
      },
    },
    SEARCH_CLICK: {
      evid: 890,
      fields: {
        component_type: 'string',
        component_id: 'string',
        videoSource: 'string',
      },
    },
  },
  repeaters: {
    COMPONENT_ADDED_TO_REPEATER: {
      evid: 738,
      fields: {
        category: 'string',
        component_id: 'string',
        component_type: 'string',
        origin: 'string',
        section: 'string',
      },
    },
    REPEATER_LAYOUT_CHANGED: {
      evid: 744,
      fields: {
        alignment: 'string',
      },
    },
    REPEATER_SPACING_CHANGED: {
      evid: 743,
      fields: {
        value: 'string',
      },
    },
    REPEATER_STRETCH_TOGGLE: {
      evid: 742,
      fields: {
        toggle_status: 'string',
      },
    },
    REORDER_REPEATER_ITEM: {
      evid: 740,
      fields: {},
    },
    REPEATER_DUPLICATE_ITEM: {
      evid: 739,
      fields: {
        origin: 'string',
      },
    },
    DELETE_REPEATER_ITEM: {
      evid: 741,
      fields: {},
    },
    background_panel: {
      apply_to_all: {
        evid: 1009,
        fields: {
          parent_item_id: 'string',
          component_id: 'string',
        },
      },
    },
  },
  linkPanel,
  pageResize: {
    PAGE_MIN_HEIGHT_TOOLTIP_APPEARED: {
      evid: 612,
      fields: {},
    },
    PAGE_MIN_HEIGHT_RESET_MIN_HEIGHT: {
      evid: 613,
      fields: {},
    },
    PAGE_MIN_HEIGHT_RESIZE: {
      evid: 614,
      fields: {
        yCoordinate_new: 'string',
        yCoordinate_old: 'string',
      },
    },
  },
  grid: {
    GRID_ADD_COLUMN_CLICKED: {
      evid: 650,
      eventId: 650,
      params: {
        site_id: 'siteId',
        component_id: 'componentId',
        panel_name: 'panelName',
      },
    },
    GRID_ADD_COLUMN_DONE: {
      evid: 651,
      eventId: 651,
      params: {
        site_id: 'siteId',
        component_id: 'componentId',
        type: 'columnType',
        field_name: 'fieldName',
        column_title: 'columnTitle',
        origin: 'origin',
        panel_name: 'panelName',
      },
    },
    GRID_SETTINGS_CLOSED: {
      evid: 652,
      eventId: 652,
      params: {
        site_id: 'siteId',
        component_id: 'componentId',
        link_open: 'linkOpen',
        click_select: 'clickSelect',
        origin: 'origin',
        panel_name: 'panelName',
      },
    },
    GRID_LAYOUT_SETTINGS_CLOSED: {
      evid: 653,
      eventId: 653,
      params: {
        site_id: 'siteId',
        component_id: 'componentId',
        panel_name: 'panelName',
        column_width_type: 'columnWidthType',
        alignment_type: 'alignmentType',
        row_height: 'rowHeight',
        header_height: 'headerHeight',
        has_rows_changed: 'hasRowsChanged',
        show_header_row: 'showHeaderRow',
        rows_add_method: 'rowsAddMethod',
        rows_per_page: 'rowsPerPage',
        pagination: 'pagination',
        resize_hight: 'resizeHeight',
        origin: 'origin',
      },
    },
    GRID_COLUMN_WIDTH_CLOSED: {
      evid: 654,
      eventId: 654,
      params: {
        site_id: 'siteId',
        component_id: 'componentId',
        panel_name: 'panelName',
        origin: 'origin',
        has_data_changed: 'hasDataChanged',
        column_width_data: 'columnWidthData',
      },
    },
  },
  a11ySettings: {
    addA11yAttribute: {
      evid: 927,
      eventId: 927,
      fields: {
        site_id: 'guid',
        component_id: 'componentId',
        aria_prop: 'string',
        prop_token: 'string',
        componentName: 'string',
        target: 'string',
      },
    },
    addAttributeClick: {
      evid: 1229,
      eventId: 1229,
      fields: {
        component_id: 'componentId',
        component_name: 'string',
      },
    },
    attributeThreeDotsClick: {
      evid: 1230,
      eventId: 1230,
      fields: {
        component_id: 'componentId',
        component_name: 'string',
        aria_property: 'string',
        prop_token: 'string',
        target: 'string',
      },
    },
  },
  accessibilityPanel: {
    click_accessbility_in_topbar_menu: {
      evid: 679,
      eventId: 679,
      fields: {
        site_id: 'guid',
      },
    },
  },
  mobileFriendlyPanel: {
    toggle_click: {
      evid: 292,
      eventId: 292,
      fields: {
        origin: 'string',
        status: 'boolean',
      },
    },
  },
  contactForm: {
    field_change: {
      src: 5,
      endpoint: 'contact-form',
      evid: 711,
      fields: {
        action: 'string',
        field_name: 'string',
        field_type: 'string',
        new_value: 'string',
      },
    },
    form_name_changed: {
      src: 5,
      endpoint: 'contact-form',
      evid: 716,
      fields: {
        new_name: 'string',
      },
    },
    add_wix_forms: {
      src: 5,
      endpoint: 'contact-form',
      evid: 717,
    },
  },
  panels,
  quickActionBar: {
    LAYOUT_QUICK_ACTION_BAR: {
      evid: 716,
      src: 38,
      fields: {
        alignment: 'string',
      },
    },
    HIDE_LABELS_QUICK_ACTION_BAR: {
      evid: 717,
      src: 38,
      fields: {
        hidden: 'bool',
      },
    },
    CHANGE_COLOR_SCHEME: {
      evid: 718,
      src: 38,
      fields: {
        value: 'string',
        component_type: 'string',
      },
    },
    INVERT_COLOR_SCHEME: {
      evid: 719,
      src: 38,
      fields: {
        invert: 'bool',
        component_type: 'string',
      },
    },
    CLOSE_SET_UP_QUICK_ACTIONS: {
      evid: 720,
      src: 38,
      fields: {
        number_of_quick_actions: 'number',
        quick_actions_list: 'string',
      },
    },
    ADD_QUICK_ACTION: {
      evid: 722,
      src: 38,
      fields: {
        action_type: 'string',
      },
    },
    RENAME_QUICK_ACTION: {
      evid: 723,
      src: 38,
      fields: {
        new_name: 'string',
        action_type: 'string',
      },
    },
    CLICK_SET_UP_QUICK_ACTION: {
      evid: 724,
      src: 38,
      fields: {
        action_type: 'string',
      },
    },
    REMOVE_QUICK_ACTION: {
      evid: 725,
      src: 38,
      fields: {
        action_type: 'string',
      },
    },
    CHANGE_ICON_QUICK_ACTION: {
      evid: 726,
      src: 38,
      fields: {
        icon: 'string',
      },
    },
    CLICK_CHANGE_ICON_QUICK_ACTION: {
      evid: 727,
      src: 38,
      fields: {},
    },
    CLICK_MORE_QUICK_ACTION: {
      evid: 728,
      src: 38,
      fields: {},
    },
  },
  stage: {
    ATTACH_TO: {
      evid: 158,
      src: 38,
      fields: {
        component_type: 'string',
        component_id: 'string',
        source_component: 'string',
        origin: 'string',
        target_component: 'string',
        target_component_id: 'string',
        is_target_repeated: 'boolean',
        is_source_repeated: 'boolean',
        is_target_hover: 'boolean',
      },
    },
    OUT_OF_GRIDLINES: {
      evid: 814,
      src: 38,
      fields: {
        component_id: 'string',
        parent_component_id: 'string',
        parent_component_type: 'string',
        non_page_top_parent_component_id: 'string',
        non_page_top_parent_component_type: 'string',
        component_type: 'string',
        source_name: 'string',
        is_same_parent_component: 'boolean',
      },
    },
  },
  dealer: {
    content_presented_by_dealer: {
      evid: 767,
      src: 38,
      fields: {
        description: 'string',
        content_presented_by_dealer: 'bool',
        stage: 'string',
        category: 'string',
        panel_name: 'string',
      },
    },
    dealer_upgrade_topbar_on_view: {
      evid: 794,
    },
  },
  dataFixers: {
    blogSinglePostFix: {
      evid: 759,
      src: 38,
      fields: {
        msid: 'guid',
      },
    },
  },
  codeEmbed: {
    Code_embed_apply_clicked: {
      evid: 749,
      src: 38,
      fields: {
        esi: 'string',
        component_id: 'string',
        component_type: 'string',
        site_id: 'guid',
      },
    },
    Code_embed_title_changed: {
      evid: 1228,
      src: 38,
      fields: {
        esi: 'string',
        title: 'string',
        isSuccess: 'bool',
        multilingual: 'string',
        component_id: 'string',
        component_type: 'string',
        site_id: 'guid',
      },
    },
  },
  multilingual,
  googleTranslate,
  ratings: {
    Rating_display_settings_text_changed: {
      evid: 799,
      src: 38,
      fields: {
        preset_id: 'string',
        component_type: 'string',
        component_id: 'string',
        esi: 'guid',
        page_id: 'string',
      },
    },
    Rating_display_settings_without_reviews_state_set: {
      evid: 800,
      src: 38,
      fields: {
        value: 'string',
        preset_id: 'string',
        component_type: 'string',
        component_id: 'string',
        esi: 'guid',
        page_id: 'string',
      },
    },
  },
  PROPERTIES_PANEL_TOGGLE: {
    endpoint: 'code',
    src: 39,
    evid: 141,
    fields: {
      state: 'string',
      site_id: 'guid',
      origin: 'string',
    },
  },
  notifications: {
    editor_notification_appear: {
      evid: 811,
      src: 38,
      fields: {
        location: 'numeric',
        topic: 'string',
        origin: 'string',
        type: 'string',
      },
    },
    editor_notification_close: {
      evid: 812,
      src: 38,
      fields: {
        time_since_load: 'numeric',
        finish_method: 'string',
        topic: 'string',
        action: 'string',
      },
    },
  },
  WIDGET_PUBLIC_API: {},
  layers: {
    layer_show_hide: {
      evid: 806,
      fields: {
        state: 'string',
        layer_name: 'string',
        panel_origin: 'string',
        out_of_grid: 'boolean',
      },
    },
    layer_click: {
      evid: 808,
      fields: {
        layer_name: 'string',
        component_type: 'string',
        parent_component_type: 'string',
        panel_origin: 'string',
        is_landing_page: 'boolean',
        is_popup: 'boolean',
        out_of_grid: 'boolean',
      },
    },
    layer_drag: {
      evid: 809,
      fields: {
        state: 'string',
        layer_name: 'string',
        panel_origin: 'string',
        out_of_grid: 'boolean',
      },
    },
    layer_spotlight: {
      evid: 807,
      fields: {
        current_position_index: 'number',
        previous_position_index: 'number',
        layer_name: 'string',
        panel_origin: 'string',
        out_of_grid: 'boolean',
      },
    },
    layers_panel_click: {
      evid: 1181,
      fields: {
        esi: 'string',
        site_id: 'guid',
      },
    },
    hover_hidden_element: {
      evid: 1182,
      fields: {
        section: 'string',
        component_id: 'string',
        component_type: 'string',
        current_page_name: 'string',
        origin: 'string',
        site_segment: 'string',
      },
    },
    hover_element_layers_panel: {
      evid: 1183,
      fields: {
        layer_name: 'string',
        component_type: 'string',
        parent_component_type: 'string',
        is_landing_page: 'boolean',
        is_popup: 'boolean',
      },
    },
    layer_hierarchy_click: {
      evid: 1185,
      fields: {
        target: 'string',
        layer_name: 'string',
      },
    },
  },
  SITE_BACKGROUND_CHANGE_CLICK: {
    src: 38,
    evid: 816,
    fields: {
      mediaType: 'string',
      origin: 'string',
      esi: 'guid',
      site_id: 'guid',
    },
  },
  DATA_CORRUPTION_MESSAGE: {
    src: 38,
    evid: 840,
    fields: {
      date_corruption_type: 'string',
    },
  },
  ssrCache: {
    click_advanced_page_settings_page_panel: {
      evid: 835,
      src: 38,
    },
    enable_ssr_cache_for_a_page_toggle: {
      evid: 836,
      src: 38,
      fields: {
        toggle: 'bool',
        pageId: 'string',
      },
    },
    auto_manual_drop_down_change: {
      evid: 897,
      src: 38,
      fields: {
        pageId: 'string',
        selection: 'string',
      },
    },
    chose_timeframe_in_dropdown: {
      evid: 837,
      src: 38,
      fields: {
        selection: 'string',
      },
    },
  },
  signup: {
    link_to_policy: {
      evid: 617,
      src: 5,
      endpoint: 'site-members',
      fields: {
        name: 'string',
        action: 'string',
        type: 'string',
      },
    },
    set_auto_approval: {
      evid: 652,
      src: 5,
      endpoint: 'site-members',
      fields: {
        hosting: 'string',
        selection: 'string',
      },
    },
    set_login_dialog_first: {
      evid: 653,
      src: 5,
      endpoint: 'site-members',
      fields: {
        name: 'string',
        value: 'string',
      },
    },
    set_social_login: {
      evid: 654,
      src: 5,
      endpoint: 'site-members',
      fields: {
        name: 'string',
        value: 'string',
      },
    },
    toggle_policy: {
      evid: 655,
      src: 5,
      endpoint: 'site-members',
      fields: {
        hosting: 'string',
        toggle_state: 'boolean',
        type: 'string',
      },
    },
    create_custom_signup: {
      evid: 656,
      src: 5,
      endpoint: 'site-members',
      fields: {},
    },
    create_custom_login: {
      evid: 1211,
      src: 5,
      endpoint: 'site-members',
      fields: {
        msid: 'guid',
      },
    },
  },
  shortcuts: {
    esc_during_action: {
      evid: 871,
      src: 38,
      fields: {
        panel_name: 'string',
        action: 'string',
      },
    },
    tab_shift_tab_nav: {
      evid: 872,
      src: 38,
      fields: {
        component_id: 'string',
      },
    },
    keep_add_panel_open_add_comp: {
      evid: 873,
      src: 38,
    },
    comp_main_action: {
      evid: 874,
      src: 38,
      fields: {
        componentId: 'string',
      },
    },
    comp_main_panels_trigger: {
      evid: 875,
      src: 38,
      fields: {
        panel_name: 'string',
      },
    },
  },
  copyPaste: {
    CLIPBOARD_ERROR: {
      evid: 90,
      src: 38,
      fields: {
        action: 'string',
        error_type: 'string',
      },
    },
    PASTE_COMPONENT: {
      evid: 943,
      src: 38,
      fields: {
        origin_msid: 'guid',
        component_type: 'string',
        paste_option: 'string',
        success: 'boolean',
        error_type: 'string',
        theme: 'string',
        media_content: 'string',
      },
    },
    COPY_PAGE: {
      evid: 948,
      src: 38,
      fields: {
        page_id: 'guid',
        copy_option: 'string',
      },
    },
    PASTE_PAGE: {
      evid: 945,
      src: 38,
      fields: {
        origin_msid: 'guid',
        origin_page_id: 'guid',
        paste_option: 'string',
        success: 'boolean',
        error_type: 'string',
        theme: 'string',
        media_content: 'string',
      },
    },
    UNKNOWN_PASTE_ORIGIN: {
      evid: 1176,
      src: 38,
      fields: {
        content: 'string',
      },
    },
  },
  AppBuilder,
  documentMedia: {
    SHOW_DOCUMENT_TITLE: {
      evid: 878,
      src: 38,
      fields: {
        toggle: 'string',
        component_id: 'string',
      },
    },
    HOVER_SETTINGS_TOOLTIP: {
      evid: 878,
      src: 38,
      fields: {
        toggle: 'string',
        component_id: 'string',
      },
    },
    PDF_INDEXING: {
      evid: 880,
      src: 38,
      fields: {
        origin: 'string',
      },
    },
  },
  platform: {
    import_app_click: {
      evid: 866,
      endpoint: 'app-studio',
      fields: {
        app_id: 'string',
        appName: 'string',
      },
    },
    add_widget_to_page: {
      evid: 868,
      endpoint: 'app-studio',
      fields: {
        app_id: 'string',
        appName: 'string',
        source_name: 'string',
      },
    },
    INIT_START: {
      evid: 77,
      fields: {
        viewerVersion: 'string',
        viewerName: 'string',
        app_name: 'string',
        uuid: 'guid',
        subPhaseName: 'string',
        phaseName: 'string',
        msid: 'guid',
        isTemplate: 'bool',
        esi: 'guid',
      },
    },
    INIT_END: {
      evid: 78,
      fields: {
        viewerVersion: 'string',
        viewerName: 'string',
        app_name: 'string',
        uuid: 'guid',
        subPhaseName: 'string',
        phaseName: 'string',
        msid: 'guid',
        is_template: 'bool',
        intervalLoadTime: 'numeric',
        esi: 'guid',
      },
    },
  },
  refComponent: {
    resetCustomization: {
      evid: 88,
      fields: {
        component_type: 'string',
        component_id: 'string',
      },
    },
    changes: {
      evid: 88,
      fields: {
        changeType: 'string',
        component_type: 'string',
        component_id: 'string',
      },
    },
  },
  blog: {
    add_widget_event: {
      evid: 701,
      src: 69,
      fields: {
        id: 'string',
        origin: 'string',
        element_type: 'string',
        widget_id: 'string',
      },
    },
  },
  liveChat: {
    open_widget: {
      evid: 662,
      src: 4,
      endpoint: 'ans_bi',
      fields: {
        current_platform: 'string',
        lang: 'string',
        module_name: 'string',
        open_method: 'string',
        parent_name: 'string',
        parent_url: 'string',
        referral_name: 'string',
        referral_url: 'string',
        source_name: 'string',
      },
    },
    close_widget: {
      evid: 663,
      src: 4,
      endpoint: 'ans_bi',
      fields: {
        current_platform: 'string',
        lang: 'string',
        module_name: 'string',
        close_method: 'string',
        parent_name: 'string',
        parent_url: 'string',
        referral_name: 'string',
        referral_url: 'string',
        source_name: 'string',
      },
    },
    successfully_created: {
      evid: 664,
      src: 4,
      endpoint: 'ans_bi',
      fields: {
        current_platform: 'string',
        ticket_id: 'string',
        lang: 'string',
        module_name: 'string',
        parent_name: 'string',
        parent_url: 'string',
        referral_name: 'string',
        referral_url: 'string',
        source_name: 'string',
      },
    },
  },
  GOOGLE_MAPS: {
    SETTINGS_PANEL_STATUS: {
      evid: 1212,
      fields: {
        allow_dragging: 'bool',
        show_direction_link: 'bool',
        show_street_view: 'bool',
        show_zoom_control: 'bool',
        show_map_satellite_views: 'bool',
        zoom_value: 'mumeric',
      },
    },
    INSERT_ADDRESS: {
      evid: 1213,
      fields: {
        addressId: 'string',
        address: 'string',
        success: 'bool',
      },
    },
    MULTIPLE_LOCATIONS_CHOOSE: {
      evid: 1214,
      fields: {
        choice: 'string',
      },
    },
    ADD_LOCATION_CLICK_NEW: {
      evid: 1215,
    },
    LOCATION_DOTS_MENU_CLICK: {
      evid: 1216,
      fields: {
        address: 'string',
        option: 'string',
      },
    },
    MOVE_TABS_TO_PINS_TAB_NEW: {
      evid: 1217,
      fields: {
        component_id: 'string',
        tab: 'string',
      },
    },
    UPLOAD_PIN_DESIGN_CLICK_NEW: {
      evid: 1218,
      fields: {
        component_id: 'string',
      },
    },
  },
  WPHOTO: {
    ALT_TEXT_CHANGE: {
      evid: 929,
      fields: {
        value: 'string',
      },
    },
  },
  savedComponents: {
    open_save_panel: {
      evid: 954,
      src: 38,
      fields: {
        funnel_id: 'string',
        origin: 'string',
      },
    },
    save_elements: {
      evid: 958,
      src: 38,
      fields: {
        funnel_id: 'string',
        element_id: 'string',
        element_name: 'string',
        success: 'bool',
        element_source: 'string',
        content_type: 'string',
        components: 'string',
        error_msg: 'string',
      },
    },
    add_element_to_stage: {
      evid: 959,
      src: 38,
      fields: {
        theme: 'string',
        element_id: 'string',
        adding_method: 'string',
        content_type: 'string',
        success: 'bool',
        error_msg: 'string',
      },
    },
    delete_from_library: {
      evid: 960,
      src: 38,
      fields: {
        element_id: 'string',
      },
    },
    confirm_deletion_from_library: {
      evid: 961,
      src: 38,
      fields: {
        element_id: 'string',
        success: 'bool',
        error_msg: 'string',
      },
    },
  },
  interactions: {
    interaction_mode_started: {
      evid: 977,
      src: 38,
      fields: {
        component_id: 'string',
        interaction_id: 'string',
        component_type: 'string',
      },
    },
    exit_interaction_mode: {
      evid: 988,
      src: 38,
      fields: {
        component_id: 'string',
        interaction_id: 'string',
        component_type: 'string',
        origin: 'string',
      },
    },
    interaction_mode_move_component: {
      evid: 1001,
      src: 38,
      fields: {
        yCoordinate_old: 'string',
        yCoordinate_new: 'string',
        xCoordinate_old: 'string',
        xCoordinate_new: 'string',
        parent_component_type: 'string',
        parent_component_id: 'string',
        interaction_id: 'string',
        component_type: 'string',
        component_id: 'string',
        handle_used: 'string',
      },
    },
    interaction_mode_hide_component: {
      evid: 1011,
      src: 38,
      fields: {
        component_id: 'string',
        component_type: 'string',
        interaction_id: 'string',
        parent_component_id: 'string',
        parent_component_type: 'string',
        origin: 'string',
      },
    },
    interaction_mode_unhide_component: {
      evid: 1012,
      src: 38,
      fields: {
        component_id: 'string',
        component_type: 'string',
        interaction_id: 'string',
        parent_component_id: 'string',
        parent_component_type: 'string',
        origin: 'string',
      },
    },
    add_hover_interaction: {
      evid: 1013,
      src: 38,
      fields: {
        component_id: 'string',
        component_type: 'string',
        panel_name: 'string',
        interaction_id: 'string',
        parent_component_id: 'string',
        parent_component_type: 'string',
      },
    },
    edit_interaction: {
      evid: 1014,
      src: 38,
      fields: {
        component_id: 'string',
        component_type: 'string',
        interaction_id: 'string',
      },
    },
    remove_interaction: {
      evid: 1018,
      src: 38,
      fields: {
        component_id: 'string',
        component_type: 'string',
        interaction_id: 'string',
      },
    },
    interaction_mode_add_component: {
      evid: 1041,
      src: 38,
      fields: {
        component_id: 'string',
        component_type: 'string',
        interaction_id: 'string',
        parent_component_id: 'string',
        parent_component_type: 'string',
      },
    },
    interaction_go_to_preview: {
      evid: 1077,
      src: 38,
      fields: {
        component_id: 'string',
        component_type: 'string',
        interaction_id: 'string',
      },
    },
    hoverbox_change_tab: {
      evid: 1074,
      src: 38,
      fields: {
        tab_name: 'string',
        component_id: 'string',
        component_type: 'string',
        interaction_id: 'string',
      },
    },
    timing_panel_behavior_click: {
      evid: 81,
      src: 38,
      fields: {
        component_id: 'string',
        component_type: 'string',
        behavior_name: 'string',
        interaction_id: 'string',
      },
    },
    timing_panel_behavior_deleted: {
      evid: 83,
      src: 38,
      fields: {
        component_id: 'string',
        component_type: 'string',
        behavior_name: 'string',
        interaction_id: 'string',
      },
    },
    timing_panel_change_settings: {
      evid: 84,
      src: 38,
      fields: {
        component_id: 'string',
        component_type: 'string',
        behavior_name: 'string',
        parameter_name: 'string',
        interaction_id: 'string',
        old_value: 'string',
        new_value: 'string',
      },
    },
    timing_panel_behavior_saved: {
      evid: 85,
      src: 38,
      fields: {
        component_id: 'string',
        component_type: 'string',
        behavior_name: 'string',
        interaction_id: 'string',
        duration: 'string',
        delay: 'string',
      },
    },
    effects_panel_behavior_click: {
      evid: 980,
      src: 38,
      fields: {
        component_id: 'string',
        component_type: 'string',
        behavior_name: 'string',
        interaction_id: 'string',
      },
    },
    effects_panel_behavior_deleted: {
      evid: 981,
      src: 38,
      fields: {
        component_id: 'string',
        component_type: 'string',
        interaction_id: 'string',
        deleted_behavior_name: 'string',
      },
    },
    effects_panel_customize_click: {
      evid: 983,
      src: 38,
      fields: {
        component_id: 'string',
        component_type: 'string',
        behavior_name: 'string',
        interaction_id: 'string',
      },
    },
    effects_panel_change_settings: {
      evid: 984,
      src: 38,
      fields: {
        new_value: 'string',
        old_value: 'string',
        parameter_name: 'string',
        component_id: 'string',
        component_type: 'string',
        behavior_name: 'string',
        interaction_id: 'string',
      },
    },
    effects_panel_behavior_saved: {
      evid: 986,
      src: 38,
      fields: {
        effect_origin: 'string',
        ver_skew: 'numeric',
        hor_skew: 'numeric',
        rotation_value: 'numeric',
        scale: 'numeric',
        component_id: 'string',
        component_type: 'string',
        behavior_name: 'string',
        interaction_id: 'string',
      },
    },
    effects_panel_back_from_custom_settings: {
      evid: 1002,
      src: 38,
      fields: {
        component_id: 'string',
        component_type: 'string',
        behavior_name: 'string',
        interaction_id: 'string',
      },
    },
    interaction_mode_text_style_changing: {
      evid: 1003,
      src: 38,
      component_id: 'string',
      component_type: 'string',
      parameter_name: 'string',
      interaction_id: 'string',
    },
    interaction_mode_text_style_changed: {
      evid: 1004,
      src: 38,
      component_id: 'string',
      interaction_id: 'string',
      component_type: 'string',
    },
  },
  anchorMenu: {
    addNewAnchor: {
      evid: 1006,
      fields: {
        component_id: 'string',
      },
    },
    toggleAnchor: {
      evid: 1178,
      fields: {
        anchor_name: 'string',
        action: 'string',
        component_id: 'string',
      },
    },
  },
  anchors,
  editorSearch: {
    hotKeyOpened: {
      evid: 1037,
      src: 38,
    },
    topBarTooltipShown: {
      evid: 989,
      src: 38,
      fields: {
        clickType: 'string',
      },
    },
    searchQueryCleared: {
      evid: 992,
      src: 38,
      fields: {
        clickOrigin: 'string',
      },
    },
    showMoreClicked: {
      evid: 993,
      src: 38,
      fields: {
        clickOrigin: 'string',
        category: 'string',
        categoryIndex: 'number',
      },
    },
    addPanelButtonClicked: {
      evid: 1026,
      src: 38,
    },
    linkClicked: {
      evid: 994,
      src: 38,
    },
    searchResultCTAClicked: {
      evid: 995,
      src: 38,
      fields: {
        clickOrigin: 'string',
        itemId: 'string',
        itemIndex: 'number',
        category: 'string',
        categoryIndex: 'number',
      },
    },
    searchResultHovered: {
      evid: 996,
      src: 38,
      fields: {
        itemId: 'string',
        itemIndex: 'number',
        category: 'string',
        categoryIndex: 'number',
      },
    },
    conditionalCTAHovered: {
      evid: 998,
      src: 38,
      fields: {
        itemId: 'string',
        itemIndex: 'number',
        category: 'string',
        categoryIndex: 'number',
      },
    },
    conditionalTooltipCTAClicked: {
      evid: 999,
      src: 38,
      fields: {
        itemId: 'string',
        itemIndex: 'number',
        category: 'string',
        categoryIndex: 'number',
      },
    },
    searchResultReceived: {
      evid: 991,
      src: 38,
      fields: {
        loadingDuration: 'number',
        success: 'boolean',
        errorMessage: 'string',
        itemsCount: 'number',
        result: 'string',
      },
    },
    searchResultViewed: {
      evid: 997,
      src: 38,
      fields: {
        itemId: 'string',
        itemIndex: 'number',
        category: 'string',
        categoryIndex: 'number',
      },
    },
  },
  text: {
    Text_text_changed: {
      evid: 979,
      src: 38,
      fields: {
        textOrigin: 'string',
        text: 'string',
        component_id: 'string',
        component_type: 'string',
        parentComponentId: 'string',
        parentComponentType: 'string',
        multilingual: 'bool', // ? or string?
        pageId: 'string',
      },
    },
    gfpp_Buttons_show: {
      evid: 1049,
      src: 38,
      fields: {
        component_id: 'string',
        component_type: 'string',
        parentComponentId: 'string',
        parentComponentType: 'string',
        pageId: 'string',
        is_parent_hover: 'boolean',
        origin: 'string',
        isWithinBlocksWidget: 'boolean',
      },
    },
  },
  smartWriter: {
    smartWriterOpen: {
      evid: 1027,
      src: 38,
      fields: {
        pageUrl: 'string',
        pageId: 'string',
        step: 'string',
        multilingual: 'bool',
        businessType: 'string',
        isWixData: 'bool',
        component_type: 'string',
        component_id: 'string',
        textLength: 'numeric',
      },
    },
    suggestionClick: {
      evid: 1028,
      src: 38,
      fields: {
        target: 'string',
        subject: 'string',
        pageUrl: 'string',
        pageId: 'string',
        step: 'string',
        multilingual: 'bool',
        clickOrigin: 'string',
        businessType: 'string',
        isWixData: 'bool',
        component_type: 'string',
        component_id: 'string',
        textLength: 'numeric',
      },
    },
    businessTypeSelection: {
      evid: 1029,
      src: 38,
      fields: {
        target: 'string',
        oldBusinessType: 'string',
        businessType: 'string',
        isWixData: 'bool',
        component_type: 'string',
        component_id: 'string',
        multilingual: 'bool',
        textLength: 'numeric',
      },
    },
    goBackClick: {
      evid: 1030,
      src: 38,
      fields: {
        subject: null, // why is it here?
        clickOrigin: 'string',
        step: 'string',
        businessType: 'string',
        isWixData: 'bool',
        component_type: 'string',
        component_id: 'string',
        multilingual: 'bool',
      },
    },
    createTextClick: {
      evid: 1031,
      src: 38,
      fields: {
        subject: 'string',
        businessType: 'string',
        isWixData: 'bool',
        component_type: 'string',
        component_id: 'string',
        textLength: 'numeric',
        session_id: 'string',
        panel_type: 'string',
        success: 'boolean',
        prompt: 'string',
        gptParams: 'string',
      },
    },
    tabsChange: {
      evid: 1032,
      src: 38,
      fields: {
        target: 'string',
        businessType: 'string',
        subject: 'string',
        isWixData: 'bool',
        component_type: 'string',
        component_id: 'string',
      },
    },
    placeholderHover: {
      evid: 1034,
      src: 38,
      fields: {
        textId: 'string',
        subject: 'string',
        businessType: 'string',
        isWixData: 'bool',
        component_type: 'string',
        component_id: 'string',
        textLength: 'numeric',
      },
    },
    useTextClick: {
      evid: 1035,
      src: 38,
      fields: {
        clickOrigin: 'string',
        subject: 'string',
        textId: 'string',
        businessType: 'string',
        isWixData: 'bool',
        component_type: 'string',
        component_id: 'string',
        multilingual: 'string',
        textLength: 'numeric',
        is_ai_text_creator: 'bool',
        session_id: 'string',
        panel_type: 'string',
      },
    },
    searchDropDownOpen: {
      evid: 1058,
      src: 38,
      fields: {
        businessType: 'string',
        multilingual: 'string',
        isWixData: 'bool',
        component_type: 'string',
        component_id: 'string',
      },
    },
  },
  aiTextGenerator: {
    gpt3Response: {
      evid: 1417,
      src: 38,
      fields: {
        query: 'string',
        query_output: 'string',
        tokensUsed: 'numeric',
        duration: 'numeric',
        session_id: 'string',
        panel_type: 'string',
        prompt: 'string',
        gptParams: 'string',
      },
    },
    apiError: {
      evid: 1456,
      src: 38,
      fields: {
        query: 'string',
        component_id: 'string',
        component_type: 'string',
        errorMessage: 'string',
        errorCode: 'numeric',
        prompt: 'string',
        gptParams: 'string',
      },
    },
    panelOpen: {
      evid: 1468,
      src: 38,
      fields: {
        query: 'string',
        theme: 'string',
        session_id: 'string',
        panel_type: 'string',
        origin: 'string',
      },
    },
    panelOpenIntent: {
      evid: 1494,
      src: 38,
      fields: {
        is_intend: 'boolean',
        panel_type: 'string',
      },
    },
    notificationShown: {
      evid: 1467,
      src: 38,
      fields: {
        field_name: 'string',
        type: 'string',
        message: 'string',
        session_id: 'string',
      },
    },
    settingsChanged: {
      evid: 1469,
      src: 38,
      fields: {
        field_name: 'string',
        mandatory_field: 'string',
        new_value: 'string',
        session_id: 'string',
        panel_type: 'string',
      },
    },
    additionalAction: {
      evid: 1471,
      src: 38,
      fields: {
        action_name: 'string',
        session_id: 'string',
        panel_type: 'string',
      },
    },
    entryPoint: {
      evid: 1466,
      src: 38,
      fields: {
        origin: 'string',
        action_name: 'string',
      },
    },
  },
  manageContent: {
    actionClick: {
      evid: 1038,
      src: 38,
      fields: {
        actionName: 'string',
        origin: 'string',
      },
    },
  },
  tour: {
    ENDED: {
      evid: 1081,
      src: 38,
      fields: {
        name: 'string',
        panel_name: 'string',
      },
    },
  },
  fileUploader,
  datePicker,
  stretch: {
    STRETCH_SLIDER_CHANGE: {
      evid: 527,
      src: 38,
      fields: {
        unit: 'string',
        value: 'number',
      },
    },
    STRETCH_SLIDER_SEEN: {
      evid: 1121,
      component_type: 'string',
      component_id: 'string',
      appId: 'string',
    },
    STRETCH_TOGGLE: {
      evid: 508,
      fields: {
        value: 'string',
      },
    },
  },
  developerMode: {
    IDE_TOPBAR_RESIZE: {
      endpoint: 'platform',
      src: 79,
      evid: 20,
      fields: {
        old_hight: 'string',
        new_hight: 'string',
        site_id: 'guid',
      },
    },
    LEFT_TREE_TOGGLE: {
      endpoint: 'platform',
      src: 79,
      evid: 21,
      fields: {
        state: 'string',
        site_id: 'guid',
      },
    },
  },
  sectionsMigration,
  siteCreation,
  siteSettingsBranchWarning,
  designPanel,
  toolbar,
  changeBusinessType,
  widgetPresetsPanel,
  logoPanels,
} as const;
