import _ from 'lodash';
import { type ColorLayerData, ColorLayerType } from '../backgroundUtils/types';
import type { CompRef } from '@wix/document-services-types';
import type { EditorAPI } from '@/editorAPI';

interface ColorObject {
  red: number;
  green: number;
  blue: number;
}

const updateColorObj = (colorStr: string, colorObj: ColorObject) => {
  colorObj.red = parseInt(colorStr.substr(1, 2), 16);
  colorObj.green = parseInt(colorStr.substr(3, 2), 16);
  colorObj.blue = parseInt(colorStr.substr(5, 2), 16);
};

const getMobileDesignDataVariant = (
  editorAPI: EditorAPI,
  compRefWithMobileVarant: CompRef,
) => {
  const pageMobileVariant = editorAPI.mobile.getMobileVariant();

  return editorAPI.components.variants.getPointer(compRefWithMobileVarant, [
    pageMobileVariant,
  ]);
};

const GRADIENT_COLOR_LAYERS_TYPES = new Set<ColorLayerType>([
  ColorLayerType.GradientCircleLayer,
  ColorLayerType.GradientLinearLayer,
  ColorLayerType.GradientEllipseLayer,
  ColorLayerType.GradientMeshLayer,
  ColorLayerType.GradientConicLayer,
]);

const hasGradientsDesign = (editorAPI: EditorAPI, columnRef: CompRef) => {
  if (!editorAPI.columns.isColumn(columnRef)) {
    return false;
  }
  const colorLayers = editorAPI.components.design.get(columnRef).background
    ?.colorLayers as ColorLayerData[];
  if (!colorLayers) {
    return false;
  }
  return colorLayers.some(({ type }) => GRADIENT_COLOR_LAYERS_TYPES.has(type));
};

const hasDividersDesign = (
  editorAPI: EditorAPI,
  columnsContainer: CompRef | CompRef[],
) => {
  const compDesign = editorAPI.components.design.get(columnsContainer);
  return !!(compDesign?.dividerTop || compDesign?.dividerBottom);
};

const hasMobileDividersDesign = (
  editorAPI: EditorAPI,
  columnsContainer: CompRef,
) => {
  const mobileCompPointer = getMobileDesignDataVariant(
    editorAPI,
    columnsContainer,
  );
  return hasDividersDesign(editorAPI, mobileCompPointer);
};

function updateThemeColors(data: any, colors: { [key: string]: string }) {
  (data?.themeMappings || []).forEach(
    (themeColorName: string, path: string) => {
      const colorStr = colors[themeColorName];
      const colorObj = _.get(data.cssStyle, path);

      if (colorStr && colorObj) {
        updateColorObj(colorStr, colorObj);
      }
    },
  );
}

export {
  updateThemeColors,
  hasDividersDesign,
  hasGradientsDesign,
  hasMobileDividersDesign,
};
