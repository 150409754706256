import experiment from 'experiment';
import editorConstants from '@/constants';
import { editorModel } from '@wix/santa-editor-utils';
import constants from '@/constants';
import { isAppStudio } from '../appStudio/appStudioUtils';
import * as sectionsConstants from './constants';

// NOTE: import from the `@/main/startup/sectionsExperience`(and not vise versa), to be sure main chunk file size is minimal
// eslint-disable-next-line @wix/santa-editor/scoped-imports
import { getIsSectionsExperienceEnabled } from '@/main/startup/sectionsExperience';
import {
  createSectionToPasteUtils,
  hasAnchor,
  hasFWE,
} from './createSectionToPasteUtils';
import { getAnchorPositionBoundaries } from './boundaries/getAnchorPositionBoundaries';
import { isSectionableByStructure } from './isSectionable';

import type {
  CompRef,
  CompStructure,
  RelativePosition,
  Point,
} from 'types/documentServices';
import type { EditorParams } from '@/editorParams';
import type { EditorAPI } from '@/editorAPI';

export { sectionsConstants as constants, getAnchorPositionBoundaries };
export { getBlankSectionStructure } from './getBlankSectionStructure';
export {
  isSectionableByStructure,
  isSectionableComponent,
} from './isSectionable';
export { getTopPositionByStageEntryIndex } from './getTopSectionPosition';

export interface PageSectionsEditorData {
  isSectionsEnabled: boolean;
  isMigratedFromAdi?: boolean;
  migrationVersion?: string;
  migrationSkipCount?: number;
}

let cachedSiteSectionsEditorData: PageSectionsEditorData;

export const isSectionsEnabled = (): boolean => {
  if (typeof cachedSiteSectionsEditorData?.isSectionsEnabled === 'boolean') {
    return cachedSiteSectionsEditorData.isSectionsEnabled;
  }

  const isSectionsExperienceEnabled = getIsSectionsExperienceEnabled({
    editorModel,
    experiment,
    isInsideEditorX: false,
    isInsideAppStudio: isAppStudio(),
  });

  // NOTE: there are cases when we are not 100% sure if it enabled or not, migration e.g.
  if (typeof isSectionsExperienceEnabled === 'boolean') {
    return isSectionsExperienceEnabled;
  }
  return false;
};

export const predictIsSectionsEnabled = (editorParams: EditorParams): boolean =>
  editorParams.isSectionsExperienceEnabled ||
  Boolean(editorParams.sectionsMigrationFlow);

export const getSiteSectionsEditorData = (
  editorAPI: EditorAPI,
): PageSectionsEditorData => {
  if (cachedSiteSectionsEditorData !== undefined) {
    return cachedSiteSectionsEditorData;
  }

  const masterPageRef = editorAPI.components.get.byId(
    editorAPI.pages.getMasterPageId(),
  );

  const value = editorAPI.components.features?.get(
    masterPageRef,
    sectionsConstants.PAGE_SECTIONS_EDITOR_DATA_NAMESPACE,
  ) as PageSectionsEditorData;

  cachedSiteSectionsEditorData = value;

  return value;
};

export const updateSiteSectionsEditorData = (
  editorAPI: EditorAPI,
  value: PageSectionsEditorData,
) => {
  cachedSiteSectionsEditorData = value;

  const masterPageRef = editorAPI.components.get.byId(
    editorAPI.pages.getMasterPageId(),
  );

  editorAPI.components.features.update(
    masterPageRef,
    sectionsConstants.PAGE_SECTIONS_EDITOR_DATA_NAMESPACE,
    value,
  );
};

export const isSiteMigratedFromAdi = (editorAPI: EditorAPI): boolean =>
  getSiteSectionsEditorData(editorAPI)?.isMigratedFromAdi;

export const isSectionsControlsRedesignEnabled = () =>
  isSectionsEnabled() && experiment.isOpen('se_sectionsControlsRedesign');

export const getComponentsBelowPosition = (
  editorAPI: EditorAPI,
  position: number,
) => {
  const currentPage = editorAPI.pages.getCurrentPage();
  const components = editorAPI.components.getChildren(currentPage);
  const notPinnedComponents = components.filter(
    (ref) => !editorAPI.components.layout.isPinned(ref),
  );
  const componentsToShift = notPinnedComponents.filter(
    (ref) => editorAPI.components.layout.get_position(ref).y >= position,
  );
  return componentsToShift;
};

export const shiftComponents = (
  editorAPI: EditorAPI,
  components: CompRef[],
  offset: number,
) => {
  components.forEach((component) => {
    const compLayout = editorAPI.components.layout.get_position(component);
    editorAPI.components.layout.update(
      component,
      { y: compLayout.y + offset },
      true,
    );
  });
};

const IGNORED_COMP_TYPES = new Set<string>(
  [
    editorConstants.COMP_TYPES.ANCHOR,
    editorConstants.COMP_TYPES.TEXT,
    editorConstants.COMP_TYPES.SECTION,
    editorConstants.COMP_TYPES.HEADER,
    editorConstants.COMP_TYPES.FOOTER,
    editorConstants.COMP_TYPES.POPUP_CONTAINER,
  ].filter(Boolean),
);

export const shouldApplyNewResizeDesign = (
  editorAPI: EditorAPI,
  compRef: CompRef,
) => {
  const compType = editorAPI.components.getType(compRef);

  return (
    !editorAPI.components.is.horizontallyMovable(compRef) &&
    !editorAPI.components.layout.isPinned(compRef) &&
    !IGNORED_COMP_TYPES.has(compType)
  );
};

export { rearrangeNewSectionElement } from './rearrangeElements/rearrangeNewSectionElement';

const createGetSectionLikeContainerToPaste =
  (allowSectionsOnly: boolean) =>
  (
    editorAPI: EditorAPI,
    components: CompStructure[],
    { position }: { position?: { y: number } } = {},
  ) => {
    const {
      getByYPosition,
      getVisibleInViewportAndFocused,
      getVisibleInViewport,
      getClosestToViewport,
    } = createSectionToPasteUtils(editorAPI, components, allowSectionsOnly);

    return (
      (position && getByYPosition(position)) ||
      getVisibleInViewportAndFocused() ||
      getVisibleInViewport() ||
      getClosestToViewport()
    );
  };

export const getSectionLikeContainerToPaste =
  createGetSectionLikeContainerToPaste(false);
export const getSectionContainerToPaste =
  createGetSectionLikeContainerToPaste(true);

/**
 * get components paste X and Y position boundaries
 * NOTE:
 *  - relative to structure for all components but anchor
 *  - relative to page for anchor 🤷 (https://github.com/wix-private/santa-editor/commit/a43dce623683953dd5c5abc9e8900fbe1166f6f2)
 * @param editorAPI
 * @param components
 */
export const getPastePositionBoundaries = (
  editorAPI: EditorAPI,
  components: CompStructure[],
): { [key: string]: [topY: number, bottomY: number] } => {
  const containerRef = getSectionLikeContainerToPaste(editorAPI, components);

  if (!containerRef) {
    return { y: [-Infinity, Infinity], x: [-Infinity, Infinity] };
  }

  const hasAnchorComponent = hasAnchor(components);

  if (hasFWE(editorAPI, components) || hasAnchorComponent) {
    const lastFWEChild = getLastFWEChildInContainer(editorAPI, containerRef);

    if (lastFWEChild) {
      const lastFWEChildLayoutRelativeToScreen =
        editorAPI.components.layout.getRelativeToScreen(lastFWEChild);
      const childHeight = hasAnchorComponent
        ? getLastAnchorContainerChildHeight(components, lastFWEChild)
        : lastFWEChildLayoutRelativeToScreen.height;

      return {
        y: [
          lastFWEChildLayoutRelativeToScreen.y + childHeight,
          lastFWEChildLayoutRelativeToScreen.y + childHeight,
        ],
        x: [
          lastFWEChildLayoutRelativeToScreen.x +
            lastFWEChildLayoutRelativeToScreen.width,
          lastFWEChildLayoutRelativeToScreen.x +
            lastFWEChildLayoutRelativeToScreen.width,
        ],
      };
    }
  }

  const containerLayout =
    editorAPI.components.layout.getRelativeToScreen(containerRef);

  return {
    y: [containerLayout.y, containerLayout.y + containerLayout.height],
    x: [containerLayout.x, containerLayout.x + containerLayout.width],
  };
};

export const getLastFWEChildInContainer = (
  editorAPI: EditorAPI,
  containerRef: CompRef,
) => {
  const fullWidthChildren = editorAPI.components
    .getChildren(containerRef)
    .filter(editorAPI.components.is.fullWidth);

  if (!fullWidthChildren.length) {
    return null;
  }

  const [lastFWEChild] = fullWidthChildren.sort(
    (a, b) =>
      editorAPI.components.layout.get_position(b).y -
      editorAPI.components.layout.get_position(a).y,
  );

  return lastFWEChild;
};

const getLastAnchorContainerChildHeight = (
  components: CompStructure[],
  lastAnchorChildCompRef: CompRef,
) =>
  components.find(
    (component) => component.originCompId === lastAnchorChildCompRef.id,
  )?.layout?.height || 0;

export const getPositionForFWEPlacementInSection = (
  editorAPI: EditorAPI,
  containerRef: CompRef,
  compDefs: CompStructure[],
) => {
  if (
    isSectionsEnabled() &&
    hasFWE(editorAPI, compDefs) &&
    editorAPI.sections.isSection(containerRef)
  ) {
    const lastFWEChild = getLastFWEChildInContainer(editorAPI, containerRef);

    if (lastFWEChild) {
      const layout = editorAPI.components.layout.get_rect(lastFWEChild);

      return {
        x: 0,
        y: layout.y + layout.height,
      };
    }
  }
};

export const getCompPanelOpenPosition = (
  editorAPI: EditorAPI,
  { clickPosition }: { clickPosition?: RelativePosition } = {},
): RelativePosition => {
  const { top: minTop, left: minLeft } =
    editorAPI.store.getState().domMeasurements.previewPosition; // not a selector because we can't import state management in @/util package

  if (!clickPosition) {
    const scroll = editorAPI.scroll.get();
    const cursor = editorAPI.cursor.get();

    return {
      left: Math.max(
        minLeft + sectionsConstants.PANEL_EDGE_OFFSET,
        cursor.x - sectionsConstants.PANEL_WIDTH,
      ),
      top: Math.max(minTop, cursor.y - scroll.scrollTop),
    };
  }

  return {
    left:
      clickPosition.left -
      sectionsConstants.PANEL_WIDTH -
      sectionsConstants.PANEL_EDGE_OFFSET,
    top: Math.max(
      minTop + sectionsConstants.PANEL_EDGE_OFFSET,
      clickPosition.top - sectionsConstants.PANEL_OFFSET_Y,
    ),
  };
};

export const getSectionType = (
  editorAPI: EditorAPI,
  compRef: CompRef,
): sectionsConstants.SectionTypes => {
  if (editorAPI.sections.isHeader(compRef)) {
    return sectionsConstants.SectionTypes.Header;
  }

  if (editorAPI.sections.isFooter(compRef)) {
    return sectionsConstants.SectionTypes.Footer;
  }

  return sectionsConstants.SectionTypes.Section;
};

export const getSectionLikeTranslateY = (
  editorAPI: EditorAPI,
  sectionLikeRef: CompRef,
  shouldForceTranslate?: boolean,
) => {
  // if DOM layout translateY is already included
  if (!shouldForceTranslate) {
    return 0;
  }

  const translateYValue =
    editorAPI.components.transformations.get(sectionLikeRef)?.translate?.y
      .value ?? 0;

  return translateYValue;
};

export const getStageEntryIndex = (
  editorAPI: EditorAPI,
  pageRef: CompRef,
): number => {
  let focusedSection = editorAPI.sections.getFocusedSection();
  if (!focusedSection) {
    const stagePositionY =
      editorAPI.ui.scroll.getScroll().scrollTop +
      editorAPI.ui.stage.getEditingAreaLayout().height / 2;
    focusedSection = editorAPI.sections.getSectionAtY(stagePositionY);
  }
  const focusedSectionIndex = editorAPI.sections
    .getPageSectionsSortedByStageOrder(pageRef)
    .findIndex((section) => section.id === focusedSection?.id);
  return focusedSectionIndex + 1;
};

export const getTopComponentByXY = (
  editorAPI: EditorAPI,
  clickPosition: Point,
) => {
  if (!clickPosition) return null;

  const { scrollTop, scrollLeft } = editorAPI.scroll.get();

  const [topComponent] = editorAPI.selection.getComponentsByXY(
    clickPosition.x - scrollLeft,
    clickPosition.y - scrollTop,
  );

  return topComponent;
};

export const isRestrictedToAddIntoSectionByCompType = (compType: string) => {
  const RESTRICTED_TO_ADD_INTO_SECTION_COMP_TYPES: string[] = [
    constants.COMP_TYPES.SECTION,
    constants.COMP_TYPES.ANCHORS_MENU,
    constants.COMP_TYPES.PAGE,
  ].filter(Boolean);

  return RESTRICTED_TO_ADD_INTO_SECTION_COMP_TYPES.includes(compType);
};

export const shouldUseSectionInsteadOfPageAsContainer = (
  editorAPI: EditorAPI,
  containerRef: CompRef,
  compDef: CompStructure,
) => {
  if (!isSectionsEnabled()) {
    return false;
  }
  if (
    isRestrictedToAddIntoSectionByCompType(compDef?.componentType) ||
    !isSectionableByStructure(compDef)
  ) {
    return false;
  }

  const isAddingToPage =
    containerRef &&
    editorAPI.utils.isPage(containerRef) &&
    !editorAPI.pages.popupPages.isPopup(containerRef.id) &&
    containerRef.id !== editorAPI.pages.getMasterPageId();
  return isAddingToPage;
};

export const checkSiteStructureForSections = (editorAPI: EditorAPI): boolean =>
  editorAPI.components.get.byType('wysiwyg.viewer.components.ClassicSection')
    .length > 0;

export const getSectionLikeName = (editorAPI: EditorAPI, compRef: CompRef) => {
  if (editorAPI.sections.isSection(compRef)) {
    return editorAPI.sections.getName(compRef);
  }

  return editorAPI.sections.isHeader(compRef) ? 'Header' : 'Footer';
};
