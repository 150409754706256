export default {
  SCREEN_LOADING: {
    evid: 1250,
    fields: {
      view: 'string',
      screenResolution: 'string',
      pageCount: 'number',
    },
  },
  PAGE_LOADING_SUCCESS: {
    evid: 1251,
    fields: {
      view: 'string',
      screenResolution: 'string',
      durationToAction: 'string',
      loadTime: 'string',
      pageCount: 'number',
    },
  },
  NAVIGATION_CLICK: {
    evid: 1253,
    fields: {
      view: 'string',
      screenResolution: 'string',
      navButton: 'string',
    },
  },
  THUMBNAIL_SCROLL: {
    evid: 1257,
    fields: {
      view: 'string',
      screenResolution: 'string',
      position: 'number',
    },
  },
  ADDING_HOMEPAGE: {
    evid: 1258,
    fields: {
      actionSource: 'string',
      screenResolution: 'string',
      position: 'number',
    },
  },
  ADDITIONAL_PAGE_CLICK: {
    evid: 1259,
    fields: {
      action: 'string',
      pageName: 'string',
      screenResolution: 'string',
      pagesAdded: 'number',
    },
  },
  WIZARD_FINISHED: {
    evid: 1260,
    fields: {
      view: 'string',
      screenResolution: 'string',
      duration: 'number',
    },
  },
  EDITOR_FULLY_INTERACTIVE_AFTER_WIZARD: {
    evid: 1261,
    fields: {
      pageId: 'string',
      screenResolution: 'string',
    },
  },
  ERROR_LOADING_CONTENT: {
    evid: 1278,
    fields: {
      flow: 'string',
      industryId: 'string',
      structureId: 'string',
      appsToInstall: 'string',
      curatedTemplateId: 'number',
    },
  },
  ERROR_ADJUSTING_CONTENT: {
    evid: 1295,
    fields: {
      layoutToUpdate: 'string',
      resultLayout: 'string',
      componentId: 'string',
      componentType: 'string',
      curatedTemplateId: 'number',
    },
  },
  ERROR_UPDATING_PROPERTIES: {
    evid: 1298,
    fields: {
      propertiesToUpdate: 'string',
      resultProperties: 'string',
      componentId: 'string',
      componentType: 'string',
      curatedTemplateId: 'number',
    },
  },
  ERROR_PARENTING: {
    evid: 1299,
    fields: {
      componentId: 'string',
      componentType: 'string',
      currentParentId: 'string',
      currentParentType: 'string',
      newParentId: 'string',
      newParentType: 'string',
      curatedTemplateId: 'number',
    },
  },
  ERROR_HIDING: {
    evid: 1300,
    fields: {
      componentId: 'string',
      componentType: 'string',
      containerId: 'string',
      containerType: 'string',
      curatedTemplateId: 'number',
    },
  },
  SITE_CREATION_PROCESS_FINISHED: {
    evid: 1317,
    fields: {
      revisionId: 'number',
    },
  },
  SITE_CREATION_WIDGET_INSTALL_START: {
    evid: 1333,
    fields: {
      widgetName: 'string',
    },
  },
  SITE_CREATION_WIDGET_INSTALL_END: {
    evid: 1335,
    fields: {
      widgetName: 'string',
      duration: 'number',
    },
  },
  SITE_CREATION_BUSINESS_FIRST_ACTION: {
    evid: 1338,
    fields: {
      action: 'string',
    },
  },
} as const;
