export const editor = {
  UPGRADE_EVENT: {
    evid: 519,
    fields: {
      origin: 'string',
    },
  },
  UPGRADE_TOPBAR_HOVER: {
    evid: 1384,
    fields: {
      origin: 'string',
    },
  },
  EDITOR_STAGE_READY: {
    evid: 429,
    fields: {
      is_premium: 'boolean',
      is_template: 'boolean',
      window_width: 'number',
      window_height: 'number',
      screen_width: 'number',
      screen_height: 'number',
      avail_width: 'number',
      avail_height: 'number',
      prefetch: 'boolean',
      experiments: 'string',
      open_method: 'string',
      is_section_migration: 'string',
      sectionsEnabled: 'boolean',
      theme: 'string',
      contentEditingOnly: 'boolean',
      editorLanguage: 'string',
    },
  },
  APP_MANIFEST_LOADED: {
    evid: 797,
    fields: {
      app_id: 'string',
    },
  },
  Add_PANEL_LOADED: {
    evid: 700,
  },
  PREFETCH_START: {
    evid: 588,
  },
  PREFETCH_FINISH: {
    evid: 589,
  },
  MAIN_R_LOADED: {
    evid: 458,
  },
  EDITOR_LOADED: {
    evid: 459,
  },
  FRAME_RATE: {
    evid: 713,
    sampleRatio: 7,
    fields: {
      frames_per_second: 'number',
      action_name: 'string',
    },
  },
  FRAME_RATE_SECTION_DRAG: {
    evid: 636,
    fields: {
      frames_per_second: 'number',
    },
  },
  FRAME_RATE_RESIZE: {
    evid: 637,
    fields: {
      devMode: 'string',
      frames_per_second: 'number',
      component_type: 'string',
      component_id: 'string',
      parent_component_id: 'string',
      parent_component_type: 'string',
      non_page_top_parent_component_id: 'string',
      non_page_top_parent_component_type: 'string',
      out_of_grid: 'boolean',
      out_of_grid_previously: 'boolean',
    },
  },
  FRAME_RATE_DRAG: {
    evid: 638,
    fields: {
      appId: 'string',
      devMode: 'string',
      distance: 'number',
      frames_per_second: 'number',
      component_type: 'string',
      component_id: 'string',
      parent_component_id: 'string',
      parent_component_type: 'string',
      non_page_top_parent_component_id: 'string',
      non_page_top_parent_component_type: 'string',
      out_of_grid: 'boolean',
      out_of_grid_previously: 'boolean',
      is_same_parent_component: 'boolean',
    },
  },
  FRAME_RATE_RESIZE_PUSH: {
    evid: 696,
    fields: {
      devMode: 'string',
      frames_per_second: 'number',
      component_id: 'string',
      parent_component_id: 'string',
      parent_component_type: 'string',
      non_page_top_parent_component_id: 'string',
      non_page_top_parent_component_type: 'string',
    },
  },
  FRAME_RATE_DRAG_PUSH: {
    evid: 733,
    fields: {
      devMode: 'string',
      frames_per_second: 'number',
      component_id: 'string',
      parent_component_id: 'string',
      parent_component_type: 'string',
      non_page_top_parent_component_id: 'string',
      non_page_top_parent_component_type: 'string',
    },
  },
  FRAME_RATE_ROTATE: {
    evid: 734,
    fields: {
      frames_per_second: 'number',
      component_type: 'string',
    },
  },
  PREVIEW_PREFETCH_COMPARISON: {
    evid: 714,
    fields: {
      prefetched_preview_url: 'string',
      client_preview_url: 'string',
      does_match: 'boolean',
    },
  },
  component_removed: {
    evid: 279,
    fields: {
      app_id: 'guid',
      app_site_id: 'guid',
      component_id: 'string',
      component_type: 'string',
      instance_id: 'guid',
      is_last: 'boolean',
      removal_method: 'string',
    },
    sampleRatio: 10,
  },
  undo_redo_component_change: {
    evid: 281,
    fields: {
      app_id: 'guid',
      app_site_id: 'guid',
      component_id: 'string',
      component_type: 'string',
      instance_id: 'guid',
      status_change: 'string',
      origin: 'string',
      type: 'string',
      pageId: 'string',
      actionName: 'string',
      date_of_action: 'string',
    },
  },
  hide_tools: {
    hide_controls: {
      evid: 499,
      fields: {
        origin: 'string',
      },
    },
    show_controls: {
      evid: 500,
      fields: {
        origin: 'string',
      },
    },
    show_controls_tooltip_appearance: {
      evid: 501,
    },
    show_controls_tooltip_close: {
      evid: 502,
    },
  },
  GLASS_BOX_STARTED: {
    src: 38,
    evid: 89,
    fields: {
      videoSessionId: 'string',
      videoUrl: 'string',
    },
  },
  ZOOM_MODE_OPEN: {
    src: 38,
    evid: 1085,
    fields: {
      origin: 'string',
      editor_view_mode: 'string',
    },
  },
  ZOOM_MODE_CLOSE: {
    src: 38,
    evid: 549,
    fields: {
      editor_view_mode: 'string',
    },
  },
  ESCAPE_BUTTON_CLICKED: {
    src: 38,
    evid: 1284,
    fields: {
      mode: 'string',
    },
  },
  BOUNDING_BOX_ISSUES: {
    src: 38,
    evid: 1303,
    fields: {
      methodName: 'string',
      component_id: 'string',
      component_type: 'string',
      layout_structure: 'string',
      layout_dom: 'string',
    },
  },
} as const;
