import { UserData } from './types';
import { DEFAULT_INDUSTRY_ID, DEFAULT_STRUCTURE_ID } from './caas/caasConsts';

export enum Providers {
  CAAS = 'caas',
}

export const TEMP_USER_DATA: UserData = {
  businessName: 'businessName',
  businessTypeName: 'businessTypeName',
  industryId: DEFAULT_INDUSTRY_ID,
  structureId: DEFAULT_STRUCTURE_ID,
  email: 'email',
  address: {
    googleFormattedAddress:
      '500 Terry Francois Street, 6th Floor. San Francisco, CA 94158',
  },
  phoneNumber: 'phoneNumber',
  socialLinks: [],
};

export enum ContentKind {
  TEXT = 'text',
  MEDIA = 'media',
  ALL = 'all',
}
